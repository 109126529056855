import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider , useAuth} from 'oidc-react';


const base_url = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/home' : 'https://earthintel.app'

// Europe/MiddleEast/Asia Okta Platform
const OKTA_EMEA_EARTH_INTEL = {
  clientID: "0oaan3k659MgQWR8s0i7",
  authority: "https://eulogon-admin.okta-emea.com/oauth2/default"
}
// CropGPT Okta Platform
const OKTA_GAMMA_CROPGPT = {
  clientID: "0oajm0439oBGWThtd297",
  authority: "https://gamma.okta.com/oauth2/default"
}
const okta = OKTA_GAMMA_CROPGPT

const removeAuthCodeFromRedirect = (windowPath: string) => {
  const arr = windowPath.split("?")
  return (arr[0])
}

const oidcConfig = {
  onSignIn: ()=> {window.location.replace(`${base_url}${removeAuthCodeFromRedirect(window.location.pathname)}`)},
  authority: okta.authority,
  clientId: okta.clientID,
  redirectUri: `${base_url}${window.location.pathname}`,
};

const AppWrapped = () => (
    <AuthProvider {...oidcConfig}>
        <AuthApp />
   </AuthProvider>
)

const AuthApp: React.FC<{}> = () => {

    const { isLoading, signIn, userData} = useAuth();

    useEffect(() => {
      (async function login() {
        if (!isLoading && !userData) {
          await signIn();
        }
      })();
    }, [isLoading]);

    return (
      <React.Fragment>
          {userData && <App/>}
      </React.Fragment>
    )
  }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    process.env.NODE_ENV === 'development' ? <App/> : <AppWrapped/>
    // <App/>
  // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
