import * as S from './styles'
import './StoryButton.css'
import { StoryType } from "../../store/StoryApi";
import { IMapView, MAP_VIEW_CENTRAL_VALLEY, MAP_VIEW_GLOBAL_CENTER, MAP_VIEW_CORN_BELT, MAP_VIEW_IOWA } from '../../store/MapApi';

interface StoryButtonParams {
  showButtons: boolean,
  setShowButtons: (value: boolean) => void,
  panelState: boolean,
  setPanelState: (value: boolean) => void,
  currentStory: StoryType;
  setStory: (value: StoryType) => void;
  label: string;
  storyId: StoryType;
  handleMapViewChange: (newMapView: IMapView) => void,
}

export const StoryButton = (params: StoryButtonParams) => {
  const { showButtons, panelState, setShowButtons, setPanelState, setStory, handleMapViewChange, label, storyId} = params

  const clicked = () => {
    console.log("Clicked story button: " + storyId)
    setStory(storyId)
    setPanelState(!panelState)
    setShowButtons(false)
    // If Panel is closed or current story is already selected, toggle open/close state
    // if (!panelState || currentStory === storyId){
    //   setPanelState(!panelState)
    //   setShowButtons(false)
    // }
    switch (storyId){
      case StoryType.GLOBAL_AG:
        handleMapViewChange(MAP_VIEW_GLOBAL_CENTER)
        break;
      case StoryType.AG_INPUTS:
        handleMapViewChange(MAP_VIEW_GLOBAL_CENTER)
        break;
      case StoryType.RAIL_AND_SHIPPING:
        handleMapViewChange(MAP_VIEW_GLOBAL_CENTER)
        break;
      case StoryType.TRADEFLOWS:
        handleMapViewChange(MAP_VIEW_GLOBAL_CENTER)
        break;
      case StoryType.FARM_DEMAND:
        handleMapViewChange(MAP_VIEW_CORN_BELT)
        break;
      case StoryType.GRAIN:
        handleMapViewChange(MAP_VIEW_CORN_BELT)
        break;
      case StoryType.CENTRAL_VALLEY_AG:
        handleMapViewChange(MAP_VIEW_CENTRAL_VALLEY)
        break;
      case StoryType.SUPPLY_CHAIN:
        handleMapViewChange(MAP_VIEW_GLOBAL_CENTER)
        break;
      default:
        return;
        // do nothing
    }
  }

  return (
    <>
    { showButtons && <S.Button
      onClick={clicked}
      className="StoryButton"
    >
      {label}
    </S.Button>}
    </>

  );
};
