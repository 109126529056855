import MenuItem from "@mui/material/MenuItem";
import { useFormControl } from '@mui/material/FormControl';
import { IAddressPoint, IMapView } from "../../store/MapApi";
import { IManageCache } from "../CropAI/CropAI";
import { Box, FormControl, IconButton, Input, InputAdornment, ClickAwayListener, ListItemText, Switch, FormControlLabel, FormHelperText, Tooltip } from "@mui/material";
import { AddLocationAlt, Clear, ErrorOutline, TravelExplore, WrongLocation } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import { AddressQueryResponse, queryAddress } from "../CropAI/FetchData";
import {IconLayer} from '@deck.gl/layers';
import { produce } from "immer";
import Scrollbars from "react-custom-scrollbars";


// Infer type from promise
type AsyncReturnType<T extends (...args: any) => Promise<any>> =
    T extends (...args: any) => Promise<infer R> ? R : any

interface AddressSearchParams {
  cache: IManageCache,
  handleMapViewChange: (newMapView: IMapView) => void,
}

// AgInputs: PotashDeposits
export const generateAddressLayer = (addressArray: IAddressPoint[]) => {
  const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
  };
    /**
   * Data format:
   * [
   *   {name: 'Colma (COLM)', address: '365 D Street, Colma CA 94014', exits: 4214, coordinates: [-122.466233, 37.684638]},
   *   ...
   * ]
   */
    const layer = new IconLayer({
      id: 'address-pins',
      data: addressArray,
      pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: 'https://storage.googleapis.com/cropai-mappable2/assets/location_pin_black_128.png',
      iconMapping: ICON_MAPPING,
      getIcon: (d: any) => 'marker',
      sizeScale: 15,
      getPosition: (d: IAddressPoint) => [d.geometry.longitude, d.geometry.latitude],
      getSize: (d: any) => 2,
      getColor: (d: any) => [200, 25, 25],
      // getColor: (d: any) => [Math.sqrt(d.exits), 140, 0]
    });
  return layer
}

const AddressInput = (params: {params: AddressSearchParams}) => {
  const {focused} = useFormControl() || {focused: false}
  const [query, setQuery] = useState("")
  const [queryResponse, setQueryResponse] = useState<AddressQueryResponse>(AddressQueryResponse.INITIAL)
  const {cache, handleMapViewChange} = (params.params)

  const priorSearches = cache.addresses.addressArray.length > 0

  const removeAddressFromArray = (addressQuery: string) => {
    const priorQueryIndex = cache.addresses.addressArray.findIndex(e => e.query.toLowerCase() === addressQuery.toLowerCase())
    if (priorQueryIndex >= 0){
      cache.addresses.setAddressArray(produce<IAddressPoint[]>(draft => {
        // Remove that prior query from address
        draft.splice(priorQueryIndex, 1)
      }))
      // update address query response to REMOVED
      setQueryResponse(AddressQueryResponse.REMOVED)
    }
  }

  const handleClickAway = () => {
    setPanelOpen(false)
    // Reset queryResponse state (to remove error text if applicable)
    setQueryResponse(AddressQueryResponse.INITIAL)
  }

  const submitSearchQuery = () => {
    if (query.length > 0){
      console.log(query)
      let clearPrevious = false
      if (showMultiplePins){
        // If query is a repeat and currently exists in address array
        removeAddressFromArray(query)
      }else{
        // Remove all other pins from array
        clearPrevious = true
      }
      // Submit address query
      const response = queryAddress(query, handleMapViewChange, cache, clearPrevious)
      response.then(value => {
        console.log(`response value: ${value}`)
        setQueryResponse(value)
      })
      // Clear query
      setQuery("")
    }
  }

  const handleChange = (event: React.ChangeEvent) => {
    // {value} is string[]
    const value = (event.target as HTMLInputElement).value
    // If clear all selected
    setQuery(value)
  }

  const [panelOpen, setPanelOpen] = useState(false)

  const memoizedFocus = useMemo(() => {
    if (focused){
      setPanelOpen(true)
    }
    return focused
  }, [focused]);

  const hasError = useMemo(() => {
    const errorValues = [
      AddressQueryResponse.REQUEST_DENIED,
      AddressQueryResponse.REQUEST_FAILED,
      AddressQueryResponse.ZERO_RESULTS,
      AddressQueryResponse.UNKNOWN,
    ]
    return errorValues.includes(queryResponse)

  }, [queryResponse])

  function AddressInputHelperText() {

    const helperText = useMemo(() => {
      switch (queryResponse) {
        case AddressQueryResponse.INITIAL:
          return "Input address or location";
        case AddressQueryResponse.OK:
          return "Pin added";
        case AddressQueryResponse.REMOVED:
          return "Pin removed";
        case AddressQueryResponse.REQUEST_DENIED:
          return "GEOCODING_API_KEY not set";
        case AddressQueryResponse.REQUEST_FAILED:
          return "Backend request failed";
        case AddressQueryResponse.ZERO_RESULTS:
          return "No results found";
        case AddressQueryResponse.UNKNOWN:
          return "Search failed for UNKNOWN reason.";
        default:
          return "Erorr: No response received.";
      }
    }, []);

    return (
      <Box sx={{
        backgroundColor: (memoizedFocus || panelOpen) ? "white" : "#dfdfdf",
        color:"initial",
        // paddingY: "4px",
        margin: "0px",
        padding: "0px 8px 4px",
        // paddingX: "8px",
        opacity: (memoizedFocus || panelOpen) ? "100%" : "70%",
        borderBottomLeftRadius: (priorSearches && panelOpen) ? "0px" : "5px",
        borderBottomRightRadius: (priorSearches && panelOpen) ? "0px" : "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"

      }}>
        {hasError && <ErrorOutline fontSize={"small"} sx={{color: '#ED6C02', paddingRight: "2px"}}/>}
        {(queryResponse === AddressQueryResponse.OK) && <AddLocationAlt fontSize={"small"} sx={{color: '#D32F2F', paddingRight: "2px"}}/>}
        {(queryResponse === AddressQueryResponse.REMOVED) && <WrongLocation fontSize={"small"} sx={{color: '#D32F2F', paddingRight: "2px"}}/>}
        <FormHelperText
          sx={{
            color: (hasError) ? '#ED6C02' : 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {`${helperText}`}
        </FormHelperText>
      </Box>
    );
  }

  const zoomToPreviousLocation = (address: IAddressPoint) => {
    // Zoom to location
    const view: IMapView = {
      longitude: address.geometry.longitude,
      latitude: address.geometry.latitude,
      zoom: 8,
      pitch: 0,
      bearing: 0,
    }
    handleMapViewChange(view)
  }

  const [showMultiplePins, setShowMultiplePins] = useState(false)

  const toggleShowMultiplePins = () => {
    setShowMultiplePins(!showMultiplePins)
  }

  const renderAddressPanel = (internalFocus: boolean) => {
    return (
      <Box
        sx={{
          backgroundColor: (internalFocus || panelOpen) ? "white" : "#dfdfdf",
          color:"initial",
          padding: "4px",
          opacity: (internalFocus || panelOpen) ? "100%" : "70%",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          maxWidth:"220px",
        }}>
        <FormControlLabel
          sx={{margin: "0px", fontSize: "12px"}}
          control={
            <Switch
              size={"small"}
              checked={showMultiplePins}
              onChange={toggleShowMultiplePins}
              color={"error"}
            />
          }
          label="Keep multiple pins"
          disableTypography
          labelPlacement="end"
        />
        {cache.addresses.addressArray.map((address) => (
          <MenuItem
            key={address.query} value={address.query}
            sx={{paddingLeft:"4px", paddingRight: "8px"}}
          >

            <InputAdornment position="start">
              <IconButton
                onClick={() => removeAddressFromArray(address.query)}
                sx={{
                  padding: "3px"
                }}
                >
                <Clear fontSize={"small"}/>
              </IconButton>
            </InputAdornment>
            <ListItemText
              sx={{paddingRight: "4px"}}
              primary={(address.query.length > 69) ? (address.query.substring(0, 66) + "...") : address.query}
              onClick={() => zoomToPreviousLocation(address)}
              primaryTypographyProps={{
                whiteSpace: "normal",
                style: {
                  wordWrap: "break-word",
                }
              }}
            />
          </MenuItem>
        ))}
      </Box>
    )
  }

  return (
    <ClickAwayListener
      onClickAway={handleClickAway}
    >
    <Box>
      <Input
      id="address-search-input"
      placeholder="Address Search"
      value={query}
      onKeyDown={(e) => (e.key.toString() === "Enter") && submitSearchQuery()}
      disableUnderline
      error={true}
      sx={{
        backgroundColor: (memoizedFocus || panelOpen) ? "white" : "#fffcfc",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        // add priorSearches back if needed to panelOpen boolean check
        borderBottomLeftRadius: (panelOpen) ? "0px" : "5px",
        borderBottomRightRadius: (panelOpen) ? "0px" : "5px",
        paddingX: "8px",
        opacity: (memoizedFocus || panelOpen) ? "100%" : "70%"
      }}
      type="string"
      onChange={handleChange}
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title="Submit search query" placement="bottom" PopperProps={{style:{zIndex:3001}}}>
            <IconButton
              onClick={submitSearchQuery}
              sx={{
                padding: "4px"
              }}
              >
              <TravelExplore fontSize={"small"}/>
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
    />
    {panelOpen && <AddressInputHelperText />}
    {panelOpen && priorSearches && renderAddressPanel(memoizedFocus)}
  </Box>
  </ClickAwayListener>
  )
}

export const AddressSearch = (params: AddressSearchParams) => {

  return (
    <Scrollbars
      autoHeight
      autoHeightMin={0}
      autoHeightMax={"420px"}
      hideTracksWhenNotNeeded={true}
    >
    <Box className="Address-Search-Box" sx={{maxHeight: "420px"}}>
      <FormControl variant="standard">
        <AddressInput params={params}/>
      </FormControl>
    </Box>
    </Scrollbars>
  );
};
