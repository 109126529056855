import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

export default function AppHeader() {

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (path:string ="") => {
    setAnchorEl(null);
    if (path){
      navigate(path)
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <S.Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            id="basic-button"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={()=> handleClose()}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{zIndex: 3000}}
          >
            <MenuItem onClick={() => handleClose('/home')}>Home</MenuItem>
            <MenuItem onClick={() => handleClose('/map')}>Map</MenuItem>
            <MenuItem onClick={() => handleClose('/dashboard')}>Dashboard</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Center for Earth Intelligence
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </S.Toolbar>
      </AppBar>
    </Box>
  );
}
