import {
  styled,
  Box
} from '@mui/material'

export const StoryBox = styled(Box)(() => ({
  // marginLeft: "0px",
  // display: "flex",
  position: "absolute",
  // top: "80",
  // left: "20",
  zIndex: "1900",
  height: 'calc(100% - 10vh)',
  top: '3vh',
}));
