import Plot from "react-plotly.js";
import { IChiPlot, IManageCache } from "../CropAI/CropAI";
import { fetchChiPlotData } from "../CropAI/FetchData";
import { VERBOSE_DEBUG } from "../../store/config";
import { useEffect, useState } from "react";
import { ChartSkeleton } from "./ChartSkeleton";

export interface IChiChartParams {
  cache: IManageCache,
  crop: string,
  stateName: string,
  healthIndex: string
}

export const StateChiChart = (params: IChiChartParams) => {

  const {cache, crop, stateName, healthIndex} = (params)
  const [fetchSent, setFetchSent] = useState(false)

  // Reset fetchSent state when state/crop is changed
  useEffect(() => {
    setFetchSent(false)
  }, [crop, stateName, healthIndex]);

  let chart: IChiPlot | undefined;
  let yAxisTitle = ""

  // Fetch data
  if (crop === "corn"){
    if (healthIndex.toUpperCase() === "EVI"){
      chart = cache.charts.chartData.chiPlots.corn.evi.find(e => e.level === stateName.toUpperCase())
      yAxisTitle = "Enhanced Vegetation Index"
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchChiPlotData(cache, "corn", "evi", stateName.toUpperCase())
        if (!cache.charts.chartData.chiPlots.corn.evi.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchChiPlotData(cache, "soybeans", "evi", stateName.toUpperCase())
        }
      }
    }else if (healthIndex.toUpperCase() === "TEMPERATURE"){
      chart = cache.charts.chartData.chiPlots.corn.temperature.find(e => e.level === stateName.toUpperCase())
      yAxisTitle = "Daytime LST (Celsius)"
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchChiPlotData(cache, "corn", "temperature", stateName.toUpperCase())
        if (!cache.charts.chartData.chiPlots.corn.temperature.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchChiPlotData(cache, "soybeans", "temperature", stateName.toUpperCase())
        }
      }
    }else if (healthIndex.toUpperCase() === "PRECIPITATION"){
      chart = cache.charts.chartData.chiPlots.corn.precipitation.find(e => e.level === stateName.toUpperCase())
      yAxisTitle = "Accumulated Precipitation (Inches)"
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchChiPlotData(cache, "corn", "precipitation", stateName.toUpperCase())
        if (!cache.charts.chartData.chiPlots.corn.precipitation.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchChiPlotData(cache, "soybeans", "precipitation", stateName.toUpperCase())
        }
      }
    }
  }else if (crop === "soybeans") {
    if (healthIndex.toUpperCase() === "EVI"){
      chart = cache.charts.chartData.chiPlots.soybeans.evi.find(e => e.level === stateName.toUpperCase())
      yAxisTitle = "Enhanced Vegetation Index"
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchChiPlotData(cache, "corn", "evi", stateName.toUpperCase())
        if (!cache.charts.chartData.chiPlots.corn.evi.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchChiPlotData(cache, "soybeans", "evi", stateName.toUpperCase())
        }
      }
    }else if (healthIndex.toUpperCase() === "TEMPERATURE"){
      chart = cache.charts.chartData.chiPlots.soybeans.temperature.find(e => e.level === stateName.toUpperCase())
      yAxisTitle = "Daytime LST (Celsius)"
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchChiPlotData(cache, "corn", "temperature", stateName.toUpperCase())
        if (!cache.charts.chartData.chiPlots.corn.temperature.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchChiPlotData(cache, "soybeans", "temperature", stateName.toUpperCase())
        }
      }
    }else if (healthIndex.toUpperCase() === "PRECIPITATION"){
      chart = cache.charts.chartData.chiPlots.soybeans.precipitation.find(e => e.level === stateName.toUpperCase())
      yAxisTitle = "Accumulated Precipitation (Inches)"
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchChiPlotData(cache, "corn", "precipitation", stateName.toUpperCase())
        if (!cache.charts.chartData.chiPlots.corn.precipitation.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchChiPlotData(cache, "soybeans", "precipitation", stateName.toUpperCase())
        }
      }
    }
  }else {
    console.log("ERROR: Invalid croptype in State CropHealth Index Chart")
  }

  // If chart is undefined, either plot does not exist in cache or invalid crop was supplied
  if (chart){
  return (
    <>
        <Plot
          data={[
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.median.yield,
              name: 'Median',
              line: {
                color: "rgb(161, 191, 239)",
                width: 2
              },
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.maximum.yield,
              name: '(2012 - 2022)',
              marker: {
                color: "#bab6b6"
              },
              line: {
                width: 0
              },
              showlegend: false
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.minimum.yield,
              name: '(2012 - 2022)',
              marker: {
                color: "#bab6b6"
              },
              line: {
                width: 0
              },
              fillcolor: "rgba(68, 68, 68, 0.3)",
              fill: 'tonexty',
              showlegend: false
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2012.yield,
              name: '2012',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2018.yield,
              name: '2018',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2019.yield,
              name: '2019',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2020.yield,
              name: '2020',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2021.yield,
              name: '2021',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2022.yield,
              name: '2022',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2023.yield,
              name: '2023',
              line: {
                color: "rgb(31, 119, 180)",
                width: 2.5
              },
            },
          ]}
          config={{displayModeBar:false, responsive: true}}
          layout={{
            autosize: true,
            // width: 400,
            height: 350,
            margin: {t: 40, l: 50, r: 0},
            xaxis: {
              showgrid: false,
              showline: true,
              linewidth: 0.5,
              linecolor: 'black',
              tickformat: '%b-%d',
              tickfont: {
                family: 'Tahoma',
                size: 12
              },
              zeroline: false
            },
            yaxis: {
              title: yAxisTitle,
              showgrid: false,
              showline: true,
              linewidth: 0.5,
              linecolor: 'black',
              tickfont: {
                family: 'Tahoma',
                size: 12
              },
              zeroline: false
            },
            legend: {
              x: 0.5,
              y: -0.09,
              xanchor: "center",
              orientation: 'h',
              bgcolor: "rgb(255,255,255,255)"
            }
          }}
          useResizeHandler={true}
          style={{width: '100%'}}
        />
      </>
  );
  }else{
    if (VERBOSE_DEBUG) console.log(`NO DATA: Crop Health Index Chart ${stateName} for ${crop} does not exist and could not be rendered.`)
    return (
      <ChartSkeleton/>
    )
  }
}
