import { Box, Stack } from "@mui/material";
import BasicCard from "../Cards/Card";
import AppHeader from "../AppHeader/AppHeader";

export const LandingPage = () => {
  return(
      <Box className="Landing-Page" sx={{height: "100vh", backgroundColor:"#EEEEEE"}}>
        <AppHeader />
        <br></br>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <BasicCard
            lightHeader="Center for Earth Intelligence"
            boldHeader="Global Insights"
            subHeader={["Agriculture", "Rail and Shipping", "Tradeflows", "Farm Demand"]}
            content= "Full screen map view"
            actionCardLink="/map"
            override={
              {
                backgroundColor: "#67676B"
              }
            }
          />
          <BasicCard
            lightHeader="Center for Earth Intelligence"
            boldHeader="Forecast Dashboard"
            subHeader={["BCG and USDA Crop Forecasts", "National Insights", "State Insights", "County Insights"]}
            content= "Dashboard view"
            actionCardLink="/dashboard"
            override={
              {
                backgroundColor: "#00A887"
              }
            }
          />
          <BasicCard
            lightHeader="Center for Earth Intelligence"
            boldHeader="EarthAgent"
            subHeader={["Experimental", "---", "---", "---"]}
            content= "Launch EarthAgent"
            actionCardLink="/cropgpt"
            override={
              {
                backgroundColor: "#6E76FA"
              }
            }
          />
            <BasicCard
            lightHeader="Center for Earth Intelligence"
            boldHeader="Grain Elevator"
            subHeader={["Experimental", "-------", "-------", "-------"]}
            content= "Grain elevator map"
            actionCardLink="/elevator"
            override={
              {
                backgroundColor: "#f5a442"
              }
            }
          />
        </Stack>
    </Box>
  );
}
