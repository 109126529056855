import { GeoJsonLayer } from "@deck.gl/layers"
import { IGeoLayer, IStoryData, IStoryPanel, ISubFilter, StoryType, layerType, optionSet } from "../../store/StoryApi"
import { DataFilterExtension } from "@deck.gl/extensions/typed"
import { hexToRgb } from "@mui/system"
import { convertRGBtoArray } from "../../utils/color"
import { produce } from "immer"
import { IManageState } from "../CropAI/CropAI"
import { BitmapLayer } from "@deck.gl/layers/typed"
import { COORDINATE_SYSTEM, ChangeFlags, Layer } from "@deck.gl/core/typed"
import { useState } from "react"

const setActiveOptions = (state: IManageState, panel: IStoryPanel, layer: IGeoLayer, filter: ISubFilter, activeOptions: string[]) => {
    state.setStoryData(produce<IStoryData>(draft => {
      const panelIndex = draft.panels.findIndex(e => e.name === panel.name)
      if (panelIndex !== -1){
        const layerIndex = panel.layers.findIndex(e => e.type === layer.type)
        if (layerIndex !== -1){
          const subFilterIndex = panel.layers[layerIndex].subFilters.findIndex(e => e.field === filter.field)
          if (subFilterIndex !== -1){
            draft.panels[panelIndex].layers[layerIndex].subFilters[subFilterIndex].activeValues = [...activeOptions]
            console.log("VAM Filter update values")
          }
        }
      }
    }))
}

// AgInputs: PotashMines
export const generatePotashMines = (state: IManageState, panel: IStoryPanel, layer: IGeoLayer, handleFilterLayer: (type: layerType, sets: optionSet[]) => void) => {

  let layerChanged = false

  // Get active subfilters
  const activeFilters = layer.subFilters

  const activeCompanies = []
  const activeCountries = []
  // let activeOptions:{companies: string[], countries: string[]} = {
  //   companies: [],
  //   countries: []
  // }

  const activeOptions: optionSet[] = []

  // TODO: refactor to outside
  // For each filter, create an empty optionSet
  for (let filter of activeFilters){
    activeOptions.push(
      {
        property: filter.propName,
        options: []
      }
    )
  }

  const layerPotashMines: Layer = new GeoJsonLayer({
    id: 'layer-potash-mines',
    data: "https://storage.googleapis.com/cropai-mappable/GlobalMines.geojson",

    pointRadiusMinPixels: 0,
    stroked: true,
    getLineColor: [255, 255, 255],
    // getLineColor: (d:any) => {
    //   return (setColorOrHide("#FFFFFF", d.properties, activeFilters))
    // },
    getLineWidth: 1.0,
    // getLineWidth: (d:any) => {
    //   return (setWidthOrHide(1.0, d.properties, activeFilters))
    // },
    getFillColor: (d:any) => {
      return ((convertRGBtoArray(hexToRgb("#4928FF"))))
      // return (setColorOrHide("#4928FF", d.properties, activeFilters))
    },
    lineWidthUnits: 'pixels',
    pointRadiusUnits: "pixels",
    getPointRadius: (d: any) => {
      return (getMineSizeByProdVolume(d.properties._22_ProdVo, d.properties))
      // return Math.min(d.properties._22_ProdCa**.3, 24)
    },
    getFilterValue: (d: any) => {
      // Check if data has properties
      console.log("getFilterValue")
      layerChanged = true
      if ("properties" in d){
        // If active filters are set, hideItem if it is not included in filters
        for (let filter of activeFilters){
          if (filter.activeValues.length > 0){
            // For each filter with activeValues, dataProps must have associated property in activeValues
            if (!(filter.propName in d.properties) || (!filter.activeValues.includes(d.properties[filter.propName]))){
              return 0
            }
          }
          // For each filter, create optionset
          // activeOptions.push({property: filter.propName, options: []})
        }
        // Value passed all filters, update active options if needed
        // For all optionsets, update the data's property values in each set if doesn't already exist
        for (let optionSet of activeOptions){
          let property = optionSet.property
          const propValue = d.properties[property]
          if (propValue && (!(optionSet.options.includes(propValue)))){
            optionSet.options.push(propValue)
            console.log(`for optionSet: ${optionSet.property} adding ${propValue}`)
          }
        }
        // Update {layer}.subFilters.activeOptions with the values that pass other filters
        // state.setStoryData(produce(draft => {
        //   const panelIndex = draft.panels.findIndex(e => e.name === panel.name)
        //   if (panelIndex > -1){
        //     const layerIndex = draft.panels[panelIndex].layers.findIndex(e => e.type === layer.type)
        //     if (layerIndex > -1){

        //       // For each optionSet in activeOptions, either add to or replace layer's activeOptions.options
        //       activeOptions.forEach(optionSet => {
        //         const setIndex = draft.panels[panelIndex].layers[layerIndex].activeOptions.findIndex(e => e.property === optionSet.property)
        //         if (setIndex > -1){
        //           // The property array already exists, so update it -> removes and replaces current optionSet at index
        //           // draft.panels[panelIndex].layers[layerIndex].activeOptions.splice(setIndex, 1, optionSet)
        //           console.log(`activeOptions.optionSet REPLACED in layer: ${layer.type} with values: ${optionSet}`)
        //         }else{
        //           // Else it needs to be added
        //           draft.panels[panelIndex].layers[layerIndex].activeOptions.push(optionSet)
        //           console.log(`activeOptions.optionSet PUSHED to layer: ${layer.type} with values: ${optionSet}`)
        //         }
        //       })

        //       // draft.panels[panelIndex].layers[layerIndex].subFilters.forEach(sub => {
        //       //   const curOptionSet = activeOptions.find(e => e.property === sub.propName)
        //       //   console.log(curOptionSet)
        //       //   if (curOptionSet){
        //       //     // sub.activeOptions = curOptionSet.options
        //       //   }else{
        //       //     console.log(`WARNING: Property mismatch. Could not find sub.propName: ${sub.propName} in activeOptions: ${activeOptions}`)
        //       //   }
        //       // })
        //     }
        //   }
        // }))
        return 1
      }else{
        return 0
      }
  },
  updateTriggers: {
    getPointRadius: [activeFilters],
    getLineWidth: [activeFilters],
    getFilterValue: [activeFilters],
    getFillColor: [activeFilters],
    // getLineColor: [activeFilters],
  },
  filterRange: [1,1],  // 12:00 - 13:00
  extensions: [new DataFilterExtension({filterSize: 1})],
  opacity: .4,
  pickable: true,

  })


  if (layerChanged){
    console.log("Layer Changed")
  }else{
    console.log("Layer not changed")
  }

    // Update {layer}.subFilters.activeOptions with the values that pass other filters
  // state.setStoryData(produce(draft => {
  //   const panelIndex = draft.panels.findIndex(e => e.name === panel.name)
  //   if (panelIndex > -1){
  //     const layerIndex = draft.panels[panelIndex].layers.findIndex(e => e.type === layer.type)
  //     if (layerIndex > -1){

  //       // For each optionSet in activeOptions, either add to or replace layer's activeOptions.options
  //       activeOptions.forEach(optionSet => {
  //         const setIndex = draft.panels[panelIndex].layers[layerIndex].activeOptions.findIndex(e => e.property === optionSet.property)
  //         if (setIndex > -1){
  //           // The property array already exists, so update it -> removes and replaces current optionSet at index
  //           // draft.panels[panelIndex].layers[layerIndex].activeOptions.splice(setIndex, 1, optionSet)
  //           console.log(`activeOptions.optionSet REPLACED in layer: ${layer.type} with values: ${optionSet}`)
  //         }else{
  //           // Else it needs to be added
  //           draft.panels[panelIndex].layers[layerIndex].activeOptions.push(optionSet)
  //           console.log(`activeOptions.optionSet PUSHED to layer: ${layer.type} with values: ${optionSet}`)
  //         }
  //       })

  //       // draft.panels[panelIndex].layers[layerIndex].subFilters.forEach(sub => {
  //       //   const curOptionSet = activeOptions.find(e => e.property === sub.propName)
  //       //   console.log(curOptionSet)
  //       //   if (curOptionSet){
  //       //     // sub.activeOptions = curOptionSet.options
  //       //   }else{
  //       //     console.log(`WARNING: Property mismatch. Could not find sub.propName: ${sub.propName} in activeOptions: ${activeOptions}`)
  //       //   }
  //       // })
  //     }
  //   }
  // }))
  const props = layerPotashMines.props
  const context = layerPotashMines.context
  const changeFlags: ChangeFlags = layerPotashMines.getChangeFlags() ?? {dataChanged: false, propsChanged: false, viewportChanged: false, somethingChanged: false, propsOrDataChanged: false, stateChanged: false, updateTriggersChanged: false, extensionsChanged: false}
  const needsUpdate = layerPotashMines.needsUpdate()
  const temp = layerPotashMines
  const test = layerPotashMines.shouldUpdateState({props, oldProps: props, context: context, changeFlags:changeFlags})

  console.log(`needsUpdate?: ${needsUpdate}`)
  console.log(`test?: ${test}`)

  console.log("End of generateMines")
  return layerPotashMines
}

// AgInputs: PotashDeposits
export const generatePotashDeposits = () => {
  const layerPotashDeposits = new GeoJsonLayer({
    id: 'layer-potash-deposits',
    data: "https://storage.googleapis.com/cropai-mappable/MajorDeposits.geojson",
    pointRadiusMinPixels: 5.3,
    stroked: true,
    getLineColor: [255, 255, 255],
    getLineWidth: 1.0,
    lineWidthUnits: 'pixels',
    getFillColor: convertRGBtoArray(hexToRgb("#4928FF")),
    opacity: 1.4,
      pickable: true
  })
  return layerPotashDeposits
}

// AgInputs: Phosphate Mines
export const generatePhosphateMines = () => {
  enum PropertyName {
    ORE_TONNAGE = "Reserves & Resources: Ore Tonnage (tonnes)"
  }
  console.log("generate phosphate outside")

  const layerPhosphateMines = new GeoJsonLayer({
    id: 'layer-phosphate-mines',
    data: "https://storage.googleapis.com/cropai-mappable/phosphate_mines.geojson",
    pointRadiusMinPixels: 3,
    stroked: true,
    getLineColor: [255, 255, 255],
    getLineWidth: 1.0,
    lineWidthUnits: 'pixels',
    getFillColor: convertRGBtoArray(hexToRgb(("#598E9D"))),
    pointRadiusUnits: "pixels",
    getPointRadius: (d: any) => {
      console.log("phosphate mines inside point radius")
      return (getMineSizeByOreTonnage(d.properties[PropertyName.ORE_TONNAGE]))
    },
    opacity: .4,
    pickable: true

  })
  return layerPhosphateMines
}

// AgInputs: Phosphate Deposits
export const generatePhosphateDeposits = () => {
  const layerPhosphateDeposits = new GeoJsonLayer({
    id: 'layer-phosphate-deposits',
    data: "https://storage.googleapis.com/cropai-mappable/phosphate_deposits.geojson",
    pointRadiusMinPixels: 2.0,
    stroked: true,
    getLineColor: [255, 255, 255],
    getLineWidth: 1.0,
    lineWidthUnits: 'pixels',
    getFillColor: convertRGBtoArray(hexToRgb(("#598E9D"))),
    opacity: .4,
      pickable: true
  })
  return layerPhosphateDeposits
}
// left, bottom, right, top
const appBounds = {
  left: -180.00000000000006,
  bottom: -55.76147810530456,
  right: 180.00000000000017,
  top: 83.73852189469552
}

// Phosphorous Application
export const generatePhosphorousApplication = () => {
  const layer = new BitmapLayer({
    id: 'phosphorous-application',
    image: "https://storage.googleapis.com/cropai-mappable/pfertilizer_global_crest.png",
    bounds: [appBounds.left, appBounds.bottom, appBounds.right, appBounds.top],
    _imageCoordinateSystem:  COORDINATE_SYSTEM.LNGLAT //'epsg:4326'
  })
  return layer
}

// Nitrogen Application
export const generateNitrogenApplication = () => {
  const layer = new BitmapLayer({
    id: 'nitrogen-application',
    image: "https://storage.googleapis.com/cropai-mappable/nfertilizer_global_crest.png",
    bounds: [appBounds.left, appBounds.bottom, appBounds.right, appBounds.top],
    _imageCoordinateSystem:  COORDINATE_SYSTEM.LNGLAT //'epsg:4326'
  })
  return layer
}

// AgInputs: Nitrogen Facilities
export const generateNitrogenFacilities = () => {
  const layerNitrogenFacilities = new GeoJsonLayer({
    id: 'layer-nitrogen-facilities',
    data: "https://storage.googleapis.com/cropai-mappable/nitrogen_facilities.geojson",
    pointRadiusMinPixels: 3,
    stroked: true,
    getLineColor: [255, 255, 255],
    getLineWidth: 1.0,
    lineWidthUnits: 'pixels',
    getFillColor: convertRGBtoArray(hexToRgb(("#90CB73"))),
    pointRadiusUnits: "pixels",
    getPointRadius: (d: any) => {
      return (getFacilitySizeByNitrateCapacity(d.properties._2022_CAP_))
    },
    opacity: .4,
    pickable: true

  })
  return layerNitrogenFacilities
}

const getMineSizeByProdVolume = (productionValue: number, dProps: any) => {
  // Start with largest bucket first to be range max inclusive
  const sizeBucketes = [
    {minBound: 3360.01, pointSize: 24},
    {minBound: 1932.01, pointSize: 16.53},
    {minBound: 840.01, pointSize: 12.8},
    {minBound: 0.1, pointSize: 9.07},
    {minBound: 0, pointSize: 5.3},
  ]
  for (var pair of sizeBucketes){
    if (productionValue >= pair.minBound){
      // console.log("Point Size: " + pair.pointSize)
      return pair.pointSize
    }
  }
  // Else value was less than 0? Return smallest point size
  console.log("Warning: Unable to map mine size for val: " + productionValue)
  return 5.3
}

// Phosphate Mines
const getMineSizeByOreTonnage = (dVal: number) => {
  // Start with largest bucket first to be range max inclusive
  const sizeBucketes = [
    {minBound: 26_800_000_000.01, pointSize: 26},
    {minBound: 8_203_500_00.01, pointSize: 13},
    {minBound: 6_152_625_000.01, pointSize: 9},
    {minBound: 4_101_750_000.01, pointSize: 7},
    {minBound: 2_050_875_000.01, pointSize: 5},
    {minBound: 0.1, pointSize: 3},
    {minBound: 0, pointSize: 2},
  ]
  for (var pair of sizeBucketes){
    if (dVal >= pair.minBound){
      console.log(`Point Size: ${pair.pointSize} dVal: ${dVal}`)
      return pair.pointSize
    }
  }
  // Else value was less than 0? Return smallest point size
  console.log("Warning: Unable to map mine size for val: " + dVal)
  return 2
}

const getFacilitySizeByNitrateCapacity = (dVal: number) => {
  // Start with largest bucket first to be range max inclusive
  const sizeBucketes = [
    {minBound: 1_600.01, pointSize: 24},
    {minBound: 900.01, pointSize: 16.53},
    {minBound: 400.01, pointSize: 12.8},
    {minBound: 100.01, pointSize: 9.07},
    {minBound: 0.01, pointSize: 5.3},
    {minBound: 0, pointSize: 3},
  ]
  for (var pair of sizeBucketes){
    if (dVal === null){
      return 3
    }
    else if (dVal >= pair.minBound){
      return pair.pointSize
    }
  }
  // Else value was less than 0? Return smallest point size
  console.log("Warning: Unable to map facility size for val: " + dVal)
  return 3
}
