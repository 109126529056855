
import axios from "axios";
import { BACKEND_HOST, VERBOSE_DEBUG } from "../../store/config";
import { IBarPlot, ICharts, IChiPlot, IManageCache, INationalMetrics, IScatterPlot, ISettings } from "./CropAI";
import { IShapeData, IStateShapeData } from "../../store/StoryApi";
import { produce } from "immer";
import { CoordinatePair, IAddressPoint, IMapView } from "../../store/MapApi";
import { STATE_FIPS_MAPPING } from "../../store/MapApi";

// Async call to get combined county shape data
export function fetchCountyData(cache: IManageCache) {
  (async () => {
    if (VERBOSE_DEBUG) console.log(`AXIOS GET: ${BACKEND_HOST}/get_yield_forecast_combined`)
    axios.get(`${BACKEND_HOST}/get_yield_forecast_combined`, {timeout: 120_000})
    .then(response => {
      // console.log(response.data)
      // Update temperature cache
      cache.counties.setData(produce<IShapeData>(draft => {
        draft.features = response.data
      }))
      // return response.data
    })
    .catch(error => {
      console.log("ERROR: Failed to fetch county yield data.")
      console.log(error)
    })
  })();
}

// Async call to get combined state shape data
export function fetchStateData(cache: IManageCache) {
  (async () => {
    if (VERBOSE_DEBUG) console.log(`AXIOS GET: ${BACKEND_HOST}/get_state_forecast_combined`)
    axios.get(`${BACKEND_HOST}/get_state_forecast_combined`, {timeout: 120_000})
    .then(response => {
      if (VERBOSE_DEBUG) console.log(response.data)
      // Update temperature cache
      cache.states.setData(produce<IStateShapeData>(draft => {
        draft.features = response.data.features
      }))
      // return response.data
    })
    .catch(error => {
      console.log("ERROR: Failed to fetch county yield data.")
      console.log(error)
    })
  })();
}

// August 11, 2023 Forecasts
const PREVIOUS_CORN_USDA_YIELD_FORECAST = 175.1
const PREVIOUS_SOYBEANS_USDA_YIELD_FORECAST = 50.9

const computeDeltaPercent = (current: number, previous: number): number =>{
  return ((current - previous) / previous) * 100
}

// Async call to get combined state shape data
export function fetchNationalMetrics(cache: IManageCache) {
  (async () => {
    if (VERBOSE_DEBUG) console.log(`AXIOS GET: ${BACKEND_HOST}/get_national_numbers`)
    axios.get(`${BACKEND_HOST}/get_national_numbers`, {timeout: 120_000})
    .then(response => {
      if (VERBOSE_DEBUG) console.log(response.data)
      cache.metrics.setNationalData(produce<INationalMetrics>(draft => {
        draft.corn = response.data["CORN"]
        draft.soybeans = response.data["SOYBEANS"]
        // REVIEW: TEMP OVERRIDES for AUGUST
        if (Object.hasOwn(draft.corn, 'delta_national_yield_usda')){
          if (draft.corn['delta_national_yield_usda'] === 0){
            const prev = PREVIOUS_CORN_USDA_YIELD_FORECAST
            draft.corn.delta_national_yield_usda = computeDeltaPercent(draft.corn['national_yield_usda'], prev)
            console.log(`Override corn to ${draft.corn.delta_national_yield_usda}`)
          }
        }
        if (Object.hasOwn(draft.soybeans, 'delta_national_yield_usda')){
          if (draft.soybeans['delta_national_yield_usda'] === 0){
            const prev = PREVIOUS_SOYBEANS_USDA_YIELD_FORECAST
            draft.soybeans.delta_national_yield_usda = computeDeltaPercent(draft.soybeans['national_yield_usda'], prev)
            console.log(`Override soybeans to ${draft.soybeans.delta_national_yield_usda}`)
          }
        }
      }))
    })
    .catch(error => {
      console.log("ERROR: Failed to fetch county yield data.")
      console.log(error)
    })
  })();
}

export function fetchBarPlotData(cache: IManageCache, crop: string, level: string) {
  (async () => {

    // Set query based on crop and level
    let query = ""
    let stateFIPS: string | undefined;
    if (["NATIONAL", "COUNTY"].includes(level.toUpperCase())){
      query = `${BACKEND_HOST}/get_state_yield_forecast_data/${crop.toLowerCase()}`
    }else{
      stateFIPS = STATE_FIPS_MAPPING.find(e => e.name === level.toUpperCase())?.FIPS
      if (stateFIPS){
        query = `${BACKEND_HOST}/get_top_counties_by_state_data/${crop.toLowerCase()}/${stateFIPS}`
      }else{
        console.log("ERROR: fetchBarPlotData stateFIPS not defined.")
        return
      }
    }

    if (VERBOSE_DEBUG) console.log(`AXIOS GET: ${query}`)

    axios.get(query, {timeout: 60_000})
    .then(response => {
      if (VERBOSE_DEBUG) console.log(`Response received for ${query}`)
      if (VERBOSE_DEBUG) console.log(response.data)

      let bcgYield: number[] = [];
      let bcgProduction: number[] = [];
      let usdaYield: number[] = [];
      let usdaProduction: number[] = [];
      // States or Counties
      let regions: string[] = [];

      // Parse based on level (National or State). TODO: Level needs to be typed but not in this function (import an enum)
      if (["NATIONAL", "COUNTY"].includes(level.toUpperCase())){
        Object.values(response.data).forEach((item: any) => {
          regions.push(item.state_name)
          bcgYield.push(item.yield_bcg?.toFixed(2))
          usdaYield.push(item.yield_usda?.toFixed(2))
          bcgProduction.push(item.production_bcg)
          usdaProduction.push(item.production_usda)
      })
      }else if (stateFIPS){
        // The data itself is county data for Top 10 counties in State
        Object.values(response.data).forEach((item: any) => {
          regions.push(item.county_name)
          bcgYield.push(item.yield)
          bcgProduction.push(item.production)
      })
      }else {
        console.log("ERROR: Invalid level for fetch.")
        return;
      }

      const newChart:IBarPlot = {
        level: level.toUpperCase(),
        regions: regions,
        data: {
          bcg: {
            yield: bcgYield,
            production: bcgProduction,
          },
          usda: {
            yield: usdaYield,
            production: usdaProduction,
          }
        }
      }
      cache.charts.setCharts(produce<ICharts>(draft => {
        if (crop.toLowerCase() === "corn") {
          draft.barPlots.corn.push(newChart)
        } else if (crop.toLowerCase() === "soybeans") {
          draft.barPlots.soybeans.push(newChart)
        }
      }))
    })
    .catch(error => {
      console.log("Fetch error for chart.", error)
    })
  })();
}

export function fetchScatterPlotData(cache: IManageCache, crop: string, level: string) {
  (async () => {

    // Set query based on crop and level
    let query = ""
    let stateFIPS: string | undefined;
    if (["NATIONAL", "COUNTY"].includes(level.toUpperCase())){
      query = `${BACKEND_HOST}/get_national_yield_forecast_data/${crop.toLowerCase()}`
    }else{
      stateFIPS = STATE_FIPS_MAPPING.find(e => e.name === level.toUpperCase())?.FIPS
      if (stateFIPS){
        query = `${BACKEND_HOST}/get_state_yield_line_data/${crop.toLowerCase()}/${stateFIPS}`
      }else{
        console.log("ERROR: fetchScatterPlotData stateFIPS not defined.")
        return
      }
    }

    if (VERBOSE_DEBUG) console.log(`AXIOS GET: ${query}`)

    axios.get(query, {timeout: 60_000})
    .then(response => {
      if (VERBOSE_DEBUG) console.log(`Response received for ${query}`)
      if (VERBOSE_DEBUG) console.log(response.data)

      let yieldUSDA: any = [];
      let yieldBCG: (number | null)[] = [];
      let yieldLower: any = [];
      let yieldUpper: any = [];
      let dates: any = [];

      interface IForecastDate {
        date: string
        yield_bcg: number | null
        yield_usda: number | null
        yield_lower: number | null
        yield_upper: number | null
      }

      let forecastDates: IForecastDate[] = []

      // Set array of dates
      Object.keys(response.data).forEach((item)=>{
        dates.push(item)
      })

      // Push array of values
      Object.values(response.data).forEach((item: any) => {
        yieldBCG.push(item.yield_bcg?.toFixed(3) || null)
        yieldUSDA.push(item.yield_usda?.toFixed(3) || null)
        yieldLower.push(item.yield_lower?.toFixed(3) || null)
        yieldUpper.push(item.yield_upper?.toFixed(3) || null)
      })

      // Reorder structure
      let i = 0
      dates.forEach((date: string) =>{
        const forecastDate: IForecastDate = {
          date: date,
          yield_bcg: (i <= yieldBCG.length ? yieldBCG[i] : null),
          yield_usda: (i <= yieldUSDA.length ? yieldUSDA[i] : null),
          yield_lower: (i <= yieldLower.length ? yieldLower[i] : null),
          yield_upper: (i <= yieldUpper.length ? yieldUpper[i] : null),
        }
        forecastDates.push(forecastDate)
        i += 1
      })

      // // Sort structure by forecast dates, earliest at [0] --> latest
      forecastDates.sort(function(a, b){
        return a.date.localeCompare(b.date)
      })

      // NOTE: Temporarily drop Dec. 31 forecasts
      const decIndex = forecastDates.findIndex(e => e.date === '2023-12-31')
      if (decIndex > -1){
        forecastDates.splice(decIndex, 1)
      }
      // NOTE: Set yield_usda to null for Sept. 13 national forecast
      const septIndex = forecastDates.findIndex(e => e.date === '2023-09-13')
      if (level === "NATIONAL" && septIndex > -1){
        forecastDates[septIndex].yield_usda = null
      }

      const newChart:IScatterPlot = {
        level: level.toUpperCase(),
        date: forecastDates.map(e => e.date),
        data: {
          bcg: {
            yield: forecastDates.map(e => e.yield_bcg),
          },
          upper: {
            yield: forecastDates.map(e => e.yield_upper),
          },
          lower: {
            yield: forecastDates.map(e => e.yield_lower),
          },
          usda: {
            yield: forecastDates.map(e => e.yield_usda),
          }
        }
      }
      cache.charts.setCharts(produce<ICharts>(draft => {
        if (crop.toLowerCase() === "corn") {
          draft.scatterPlots.corn.push(newChart)
        } else if (crop.toLowerCase() === "soybeans") {
          draft.scatterPlots.soybeans.push(newChart)
        }
      }))
    })
    .catch(error => {
      console.log("Fetch error for chart.", error)
    })
  })();
}

export function fetchChiPlotData(cache: IManageCache, crop: string, healthIndex: string, level: string) {
  (async () => {

    // Set query based on crop and level
    let query = ""
    let stateFIPS: string | undefined;
    if (["NATIONAL", "COUNTY"].includes(level.toUpperCase())) {
      if (healthIndex.toUpperCase() === "EVI"){
        query = `${BACKEND_HOST}/get_national_${healthIndex.toLowerCase()}_plot_data/${crop.toLowerCase()}`
      }else{
        query = `${BACKEND_HOST}/get_national_${healthIndex.toLowerCase()}_plot_data/`
      }
    }else{
      stateFIPS = STATE_FIPS_MAPPING.find(e => e.name === level.toUpperCase())?.FIPS
      if (stateFIPS){
        if (healthIndex.toUpperCase() === "EVI"){
          query = `${BACKEND_HOST}/get_state_${healthIndex.toLowerCase()}_plot_data/${crop.toLowerCase()}/${stateFIPS}`
        }else{
          query = `${BACKEND_HOST}/get_state_${healthIndex.toLowerCase()}_plot_data/${stateFIPS}`
        }
      }else{
        console.log("ERROR: fetchChiPlotData stateFIPS not defined.")
        return
      }
    }

    if (VERBOSE_DEBUG) console.log(`AXIOS GET: ${query}`)

    axios.get(query, {timeout: 60_000})
    .then(response => {
      if (VERBOSE_DEBUG) console.log(`Response received for ${query}`)
      if (VERBOSE_DEBUG) console.log(response.data)

      let year2012: any = [];
      let year2018: any = [];
      let year2019: any = [];
      let year2020: any = [];
      let year2021: any = [];
      let year2022: any = [];
      let year2023: any = [];
      let median: any = [];
      let max: any = [];
      let min: any = [];
      let date: any = []

      // Parse based on level (National or State). TODO: Level needs to be typed but not in this function (import an enum)
      if (["NATIONAL", "COUNTY"].includes(level.toUpperCase())){
        if (["EVI", "PRECIPITATION"].includes(healthIndex.toUpperCase())){
          Object.keys(response.data).forEach((item)=>{
            date.push(item)
          })
          Object.values(response.data).forEach((item: any) => {
            year2012.push(item[2012]?.toFixed(3))
            year2018.push(item[2018]?.toFixed(3))
            year2019.push(item[2019]?.toFixed(3))
            year2020.push(item[2020]?.toFixed(3))
            year2021.push(item[2021]?.toFixed(3))
            year2022.push(item[2022]?.toFixed(3))
            year2023.push(item[2023]?.toFixed(3))
            median.push(item.median?.toFixed(3))
            max.push(item.max?.toFixed(3))
            min.push(item.min?.toFixed(3))
          })
        }else if(healthIndex.toUpperCase() === "TEMPERATURE"){
          Object.keys(response.data).forEach((item)=>{
            date.push(item)
          })
          Object.values(response.data).forEach((item: any) => {
            year2012.push(item.lst_day_1km_2012?.toFixed(3))
            year2018.push(item.lst_day_1km_2018?.toFixed(3))
            year2019.push(item.lst_day_1km_2019?.toFixed(3))
            year2020.push(item.lst_day_1km_2020?.toFixed(3))
            year2021.push(item.lst_day_1km_2021?.toFixed(3))
            year2022.push(item.lst_day_1km_2022?.toFixed(3))
            year2023.push(item.lst_day_1km_2023?.toFixed(3))
            median.push(item.lst_day_1km_median?.toFixed(3))
            max.push(item.lst_day_1km_max?.toFixed(3))
            min.push(item.lst_day_1km_min?.toFixed(3))
          })
        }
      }else if (stateFIPS){
        if (["EVI", "PRECIPITATION"].includes(healthIndex.toUpperCase())){
          Object.keys(response.data).forEach((item)=>{
            date.push(item)
          })
          Object.values(response.data).forEach((item: any) => {
            year2012.push(item[2012]?.toFixed(3))
            year2018.push(item[2018]?.toFixed(3))
            year2019.push(item[2019]?.toFixed(3))
            year2020.push(item[2020]?.toFixed(3))
            year2021.push(item[2021]?.toFixed(3))
            year2022.push(item[2022]?.toFixed(3))
            year2023.push(item[2023]?.toFixed(3))
            median.push(item.median?.toFixed(3))
            max.push(item.max?.toFixed(3))
            min.push(item.min?.toFixed(3))
          })
        }else if(healthIndex.toUpperCase() === "TEMPERATURE"){
          Object.keys(response.data).forEach((item)=>{
            date.push(item)
          })
          Object.values(response.data).forEach((item: any) => {
            console.log(item.lst_day_1km_2012)
            year2012.push(item.lst_day_1km_2012?.toFixed(3))
            year2018.push(item.lst_day_1km_2018?.toFixed(3))
            year2019.push(item.lst_day_1km_2019?.toFixed(3))
            year2020.push(item.lst_day_1km_2020?.toFixed(3))
            year2021.push(item.lst_day_1km_2021?.toFixed(3))
            year2022.push(item.lst_day_1km_2022?.toFixed(3))
            year2023.push(item.lst_day_1km_2023?.toFixed(3))
            median.push(item.lst_day_1km_median?.toFixed(3))
            max.push(item.lst_day_1km_max?.toFixed(3))
            min.push(item.lst_day_1km_min?.toFixed(3))
          })
        }
      }else {
        console.log("ERROR: Invalid level for fetch.")
        return;
      }

      const newChart:IChiPlot = {
        level: level.toUpperCase(),
        date: date,
        data: {
          median: {
            yield: median,
          },
          maximum: {
            yield: max,
          },
          minimum: {
            yield: min,
          },
          y2012: {
            yield: year2012,
          },
          y2018: {
            yield: year2018,
          },
          y2019: {
            yield: year2019,
          },
          y2020: {
            yield: year2020,
          },
          y2021: {
            yield: year2021,
          },
          y2022: {
            yield: year2022,
          },
          y2023: {
            yield: year2023,
          },
        }
      }
      cache.charts.setCharts(produce<ICharts>(draft => {
        if (crop.toLowerCase() === "corn") {
          if (healthIndex.toLowerCase() === "evi"){
            draft.chiPlots.corn.evi.push(newChart)
          }else if (healthIndex.toLowerCase() === "temperature"){
            draft.chiPlots.corn.temperature.push(newChart)
          }else if (healthIndex.toLowerCase() === "precipitation"){
            draft.chiPlots.corn.precipitation.push(newChart)
          }
        } else if (crop.toLowerCase() === "soybeans") {
          if (healthIndex.toLowerCase() === "evi"){
            draft.chiPlots.soybeans.evi.push(newChart)
          }else if (healthIndex.toLowerCase() === "temperature"){
            draft.chiPlots.soybeans.temperature.push(newChart)
          }else if (healthIndex.toLowerCase() === "precipitation"){
            draft.chiPlots.soybeans.precipitation.push(newChart)
          }        }
      }))
    })
    .catch(error => {
      console.log("Fetch error for chart.", error)
    })
  })();
}

// Retries fetching if errors. No delay...
export const fetchCountyData2 = async (cache: IManageCache) => {
    // Async call to get combined county shape data
    let retries = 0;
    const maxRetries = 100
    let success = false;

    while (retries <= maxRetries && !success) {
        try {

          let response = await axios.get(`${BACKEND_HOST}/get_yield_forecast_combined`, {timeout: 120_000});
            success = true
            console.log("Axios SUCCESS")
            if (response){
              console.log("updating shapes")
              cache.counties.setData(produce<IShapeData>(draft => {
                draft.features = response.data
              }))
            }
            break;
        } catch (error) {
            console.log(error)
        }
        retries++
    }
    if(retries >= maxRetries) console.log(`Too many  request retries.`);
 }

export const fetchSettings = async (cache: IManageCache) => {
  (async () => {
    const SETTINGS_PATH = process.env.NODE_ENV === 'development' ? './' : 'https://earthintel.app/'

    console.log(`${SETTINGS_PATH}settings.json`)
    axios.get(`${SETTINGS_PATH}settings.json`, {timeout: 120_000})
    .then(response => {
      console.log("found settings json")
      // console.log(response.data)

      if (response.data.dashboard){
        cache.settings.setSettings(produce<ISettings>(draft => {
          draft.dashboard = response.data["dashboard"]
        }))
        if (response.data.version){
          console.log(`Read in ${SETTINGS_PATH}settings.json version: ${response.data.version}`)
        }
      }else{
        console.log("ERROR: Invalid ./settings.json")
      }
    })
    .catch(error => {
      console.log("ERROR: Failed to fetch ./settings.json")
      console.log(error)
    })
  })();
}

export enum AddressQueryResponse {
  INITIAL = "INITIAL",
  OK = "OK",
  ZERO_RESULTS = "ZERO_RESULTS",
  REQUEST_DENIED = "REQUEST_DENIED",
  REQUEST_FAILED = "REQUEST_FAILED",
  UNKNOWN = "UNKNOWN",
  REMOVED = "REMOVED",
}


export const queryAddress = async (query: string, handleMapViewChange: (newMapView: IMapView) => void, cache: IManageCache, clearPrevious: boolean = false): Promise<AddressQueryResponse> => {
  return new Promise(resolve => {
    let responseType = AddressQueryResponse.UNKNOWN
    console.log(`AXIOS GET: ${BACKEND_HOST}/address_search/${query}`)
    axios.get(`${BACKEND_HOST}/address_search/${query}`, {timeout: 60_000})
    .then(response => {
      console.log("parsing address response")
      console.log(response.data)
      // Set responseType
      responseType = response.data.status ?? AddressQueryResponse.UNKNOWN
      console.log(`responseType set: ${responseType}`)

      if ((responseType === AddressQueryResponse.OK) && response.data.location?.lat &&
          response.data.location?.lng && response.data.formatted_address){

        // Create new address point
        const address: IAddressPoint = {
          active: true,
          query: query,
          formattedAddress: response.data.formatted_address,
          geometry: {
            latitude: response.data.location.lat,
            longitude: response.data.location.lng,
          }
        }

        // Add address to addressArray in cache
        console.log("Address Array:")
        console.log(cache.addresses.addressArray)
        cache.addresses.setAddressArray(produce<IAddressPoint[]>(draft => {
          if (clearPrevious){
            // Clear Array
            draft.splice(0, draft.length)
          }
          draft.push(address)
          // Invert array so that the latest search is always first
          draft.reverse()
          console.log(draft)
        }))
        // Zoom to location
        const newZoomView: IMapView = {
          longitude: address.geometry.longitude,
          latitude: address.geometry.latitude,
          zoom: 8,
          pitch: 0,
          bearing: 0,
        }
        handleMapViewChange(newZoomView)
        // Return status: "OK"
        console.log("RESOLVE OK")
        resolve(responseType)
        // return responseType
      }else{
        console.log("ERROR: Invalid queryAddress data.")
        resolve(responseType)
      }
    })
    .catch(error => {
      console.log("ERROR: Failed to fetch address query.")
      console.log(error)
      responseType = AddressQueryResponse.REQUEST_FAILED
      console.log("RESOLVE ERROR")
      resolve(responseType)
    })
    // console.log("RESOLVE")
    // resolve(responseType)
  })
}
