import { Checkbox, FormGroup, FormControlLabel, Divider, styled } from '@mui/material';
import { IGeoLayer, IStoryPanel, ISubFilter, layerType } from '../../store/StoryApi';
import { StoryType } from '../../store/StoryApi';
import { PanelAgInputs } from '../PanelAgInputs/PanelAgInputs';
import { PanelRailAndShipping } from '../PanelRailAndShipping/PanelRailAndShipping';
import { PanelTradeflows } from '../PanelTradeflows/PanelTradeflows';
import { PanelFarmDemand } from '../PanelFarmDemand/PanelFarmDemand';
import { PanelGlobalAg } from '../PanelGlobalAg/PanelGlobalAg';
import { PanelGrain } from '../PanelGrain/PanelGrain';
import { YearSlider } from '../Graveyard/YearSlider';
import { IMapView, MAP_VIEW_IOWA } from '../../store/MapApi';
import { IManageState, IUpdateLayerSubFilters } from '../CropAI/CropAI';
import Scrollbars from 'react-custom-scrollbars';

interface IControlPanel {
  state: IManageState,
  panel: IStoryPanel,
  handleLayerChange: (panelName: StoryType, newLayers: IGeoLayer[]) => void,
  mapView: IMapView,
  handleMapViewChange: (newMapView: IMapView) => void,
}

export const ControlCheckbox = styled(Checkbox)(() => ({
  padding: "0px 0px 0px 0px",
  margin: "0px 9px 0px 9px"

}));

export const ControlPanel = (params: IControlPanel) =>{

  const {state, panel, handleLayerChange, mapView, handleMapViewChange} = params

  const updateLayer = (layerToToggle: layerType, panel: IStoryPanel, handleLayerChange: (panelName: StoryType, newLayers: IGeoLayer[]) => void) => {
    // const newLayers = {...panel}.layers
    // console.log(newLayers)
    const ind = panel.layers.findIndex(e => e.type === layerToToggle)
    if (ind !== -1){

      // If layer was Farm 360 and is now being toggle on, Zoom into Iowa
      if ((layerToToggle === layerType.CALI_SAM) && !panel.layers[ind].active){
        handleMapViewChange(MAP_VIEW_IOWA)
      }

      // newLayers[ind].active = !newLayers[ind].active
      handleLayerChange(panel.name, [panel.layers[ind]])

    }
    console.log("Toggled: " + layerToToggle)
    // console.log(newLayers)
    return;
  }

  // Generates Checkbox control component array for each panel layer. Remove pointerEvents styling to allow toggling by clicking text label
  const panelFilterArray = panel.layers.map((layer) => (
    <FormControlLabel
    sx={{marginRight:"0px", pointerEvents: 'none', paddingY: "4px"}}
    key={layer.type}
    // control={getControlCheckbox(layer.type)}
    control={
      <ControlCheckbox
      checked={layer.active}
      onClick={() => updateLayer(layer.type, panel, handleLayerChange)}
      sx={{pointerEvents: 'auto'}}
       />
    }
      label={layer.label}
    />
    ))

  const getPanelFilters = (panel: IStoryPanel, mapView: IMapView) => {

    switch (panel.name){
      case StoryType.GLOBAL_AG:
        return(
          <PanelGlobalAg
            state={state}
            panel={panel}
            panelFilterArray={panelFilterArray}
            mapView={mapView}
          />
        )
      case StoryType.AG_INPUTS:
        return(
          <PanelAgInputs
            state={state}
            panel={panel}
            panelFilterArray={panelFilterArray}
          />
        )
      case StoryType.RAIL_AND_SHIPPING:
        return(
          <PanelRailAndShipping
            state={state}
            panel={panel}
            panelFilterArray={panelFilterArray}
          />
        )
      case StoryType.TRADEFLOWS:
        return(
          <PanelTradeflows
            state={state}
            panel={panel}
            panelFilterArray={panelFilterArray}
          />
        )
      case StoryType.FARM_DEMAND:
        return(
          <PanelFarmDemand
            state={state}
            panel={panel}
            panelFilterArray={panelFilterArray}
            handleMapViewChange={handleMapViewChange}
          />
        )
        case StoryType.GRAIN:
          return(
            <PanelAgInputs
              state={state}
              panel={panel}
              panelFilterArray={panelFilterArray}
            />
          )
      case StoryType.CENTRAL_VALLEY_AG:
        break;
      case StoryType.SUPPLY_CHAIN:
        break;
      default:
        // If not matched to a known panel, populate panel with checkboxes for all layers
        const checkBoxLayers = panel.layers
        return (
          checkBoxLayers.map((layer) => (
            <FormControlLabel
            key={layer.type}
            control={<Checkbox checked={layer.active}
              onClick={() => updateLayer(layer.type, panel, handleLayerChange)} />}
              label={layer.label}
            />
            ))
        )
    }


  }

  return (
  <FormGroup className='Panel FormGroup'
    sx={{
      opacity: 1.0,
      overflowY: "auto",
      maxHeight: "calc(100vh - 220px)",
      // This margin shifts the scrollbar between the right padding and inner content
      marginRight: "3px",
    }}>
      {/* style={{right: "0px", top: "40px", bottom: "40px"}} */}
    <Scrollbars
      autoHeight
      autoHeightMin={0}
      autoHeightMax={"calc(100vh - 220px)"}
      renderTrackHorizontal={props => <div {...props} className="track-horizontal-2" style={{display:"none"}}/>}
      renderThumbHorizontal={props => <div {...props} className="thumb-horizontal-2" style={{display:"none"}}/>}
      hideTracksWhenNotNeeded={true}
    >
    {getPanelFilters(panel, mapView)}
    </Scrollbars>
    {/* <Divider sx={{padding: "10px"}} variant="middle" />
    <FormControlLabel control={<Checkbox disabled />} label="LEGENDS" />
    <Divider sx={{padding: "5px"}} variant="middle" />
    <FormControlLabel control={<Checkbox disabled />} label="CHARTS" />  */}
  </FormGroup>
  );
}
