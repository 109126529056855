import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MAPBOX_STYLE_OPTIONS } from "../../store/MapApi";
import { IManageState } from "../CropAI/CropAI";
import { FormControl } from "@mui/material";
import { useState } from "react";

interface MapSelectorParams {
  state: IManageState,
  style: string,
  handleChange: (value: string) => void;
}

export const MapSelector = (props: MapSelectorParams) => {

  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <FormControl sx={{minWidth: "160px", maxWidth: "200px"}} size={"small"}>
        {/* <InputLabel id="map-style-picker-label">Map Style</InputLabel> */}
        <Select
          labelId="map-style-picker-label"
          id="map-style-picker"
          value={props.style}
          onOpen={handleOpen}
          onClose={handleClose}
          // label="Map Style"
          variant="outlined"
          onChange={(e) => props.handleChange(e.target.value)}
          size={"small"}
          sx={{
            backgroundColor: "#FFFCFC",
            height: "32px",
            opacity: isOpen ? '100%' : '70%',
          }}
        >
          {MAPBOX_STYLE_OPTIONS.map((styleInfo) => (
            <MenuItem key={styleInfo.value} value={styleInfo.value}>
              {styleInfo.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
