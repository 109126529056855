import {
  styled,
  Button as MuiButton
} from '@mui/material'
import { NUM_STORIES } from '../../store/StoryApi';

const buttonWidth = "calc((90vh / " + NUM_STORIES + ") - 6px)"
const buttonMarginLeft = "calc(-.5 * ((90vh / " + NUM_STORIES + ") - 60px) + 0px)"
const heightBreakpoint = "calc((64px * " + NUM_STORIES + ") + 40px)"
const mediaQuery = "@media(min-height: " + heightBreakpoint + ")"

export const Button = styled(MuiButton)(({theme}) => ({

  // mediaQuery:{
  //   "width": buttonWidth,
  //   "opacity": "50%",
  // },

  "@media(min-height: 720px)":{
    "width": buttonWidth,
    "marginLeft": buttonMarginLeft,
  },

  backgroundColor: "#fffcfc",
  whiteSpace: "nowrap",
  // width: buttonWidth,
  opacity: '90%',
  borderRadius: '10px',
  // Disables .toUPPERCASE of button text
  textTransform: "none",
  // fontFamily: "Roboto",
  // width:"inherit",
  // rotate: "270deg",
  // background: theme.palette.background.default,
  color: "black",
  // color: theme.palette.primary.dark,
  height: "fit-content",
  // display: "flex",
  zIndex: "1200",
  // rotate: "270deg"
  // minWidth: "144px",
  // fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "22px",
  textAlign: "center",
  letterSpacing: "-0.02em",
  '&:hover': {
    backgroundColor: '#fffcfc',
    color: '#6E76FA',
},
}));

export const Button2 = styled(MuiButton)`

  backgroundColor: "#fffcfc",
  opacity: '90%',
  borderRadius: '10px',
  // Disables .toUPPERCASE of button text
  textTransform: "none",
  // fontFamily: "Roboto",
  width:"inherit",
  // rotate: "270deg",
  // background: theme.palette.background.default,
  color: "black",
  // color: theme.palette.primary.dark,
  height: "fit-content",
  // display: "flex",
  zIndex: "1200",
  // rotate: "270deg"
  // minWidth: "144px",
  // fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "22px",
  textAlign: "center",
  letterSpacing: "-0.02em",
  '&:hover': {
    backgroundColor: '#fffcfc',
    color: '#6E76FA',
}
`;


// font-family: 'Inter';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// line-height: 22px;
// /* or 138% */

// text-align: center;
// letter-spacing: -0.02em;
// font-feature-settings: 'calt' off;
