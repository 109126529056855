import Plot from "react-plotly.js";
import { IScatterPlot, IManageCache } from "../CropAI/CropAI";
import { ChartSkeleton } from "./ChartSkeleton";

export interface IScatterChartParams {
  cache: IManageCache,
  crop: string,
}

// TODO: Move this interface to explicitly define in bar chart interface from cache

export const NationalYieldChart = (params: IScatterChartParams) => {

  const {cache, crop} = (params)

  let chart: IScatterPlot | undefined;
  let yRange: any;

  switch(crop){
    case "corn":
      chart = cache.charts.chartData.scatterPlots.corn.find(e => e.level === "NATIONAL")
      yRange = [145, 195]
      break;
    case "soybeans":
      chart = cache.charts.chartData.scatterPlots.soybeans.find(e => e.level === "NATIONAL")
      yRange = [45, 60]
      break;
    default:
      console.log("ERROR: Invalid croptype in NationalBarChart")
      break
  }

  // If chart is undefined, either plot does not exist in cache or invalid crop was supplied
  if (chart){
    return (
      <>
        <Plot
          data={[
            {
              type: 'scatter',
              mode: "lines+markers",
              x: chart.date,
              y: chart.data.bcg.yield,
              name: 'BCG',
              marker: {
                color: "#007B57"
              },
              line: {
                shape: "hv",
                color: "#007B57"
              }
            },
            {
              type: 'scatter',
              mode: "lines",
              x: chart.date,
              y: chart.data.upper.yield,
              name: "BCG (Upper)",
              marker: {
                color: "#bab6b6"
              },
              line: {
                width: 0
              },
              showlegend: false
            },
            {
              type: 'scatter',
              mode: "lines",
              x: chart.date,
              y: chart.data.lower.yield,
              name: "BCG (Lower)",
              marker: {
                color: "#bab6b6"
              },
              line: {
                width: 0
              },
              fill: "tonexty",
              fillcolor: "rgba(68, 68, 68, 0.3)",
              showlegend: false
            },
            {
              type: 'scatter',
              mode: "lines+markers",
              connectgaps: true,
              x: chart.date,
              y: chart.data.usda.yield,
              name: 'USDA',
              marker: {
                color: "#8AC481",
                symbol: "diamond",
                size: 8,
              },
              line: {
                shape: "hv",
                color: "#8AC481"
              },
            },
          ]}
          config={{displayModeBar:false, responsive: true}}
          layout={{
            autosize: true,
            // width: 390,
            height: 342,
            margin: {t: 10, l: 50, r: 0},
            xaxis: {automargin: true, tickformat: '%b-%d', showgrid: false},
            yaxis: {title: 'Yield (Bu/Acre)', showgrid: false, range: yRange},
            legend: {x:0.5, y: -0.09, xanchor: "center", orientation: 'h'}
           }}
           useResizeHandler={true}
           style={{width: '100%'}}
        />
      </>
    );
  }else{
    console.log("NO DATA: Chart does not exist and could not be rendered.")
    return (
      <ChartSkeleton/>
    )
  }
}
