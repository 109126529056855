import { COORDINATE_SYSTEM } from "@deck.gl/core/typed"
import { H3HexagonLayer, MVTLayer } from "@deck.gl/geo-layers/typed"
import { BitmapLayer } from "@deck.gl/layers/typed"
import { hexToRgb } from "@mui/system"
import { convertRGBtoArray, hexToRGB } from "../../utils/color"

// GlobalAg: Cropland layer
export const generateGlobalCropland = () => {
  const globalCroplandLayer = new BitmapLayer({
    id: 'global-cropland',
    image: "https://storage.googleapis.com/cropai-mappable/Global_cropland_3km_2019_styled_lowpass.png",
    bounds: [-170, -60, 180, 70],
    _imageCoordinateSystem:  COORDINATE_SYSTEM.LNGLAT //'epsg:4326'
  })
  return globalCroplandLayer
}

// GlobalAg: Population Layer
export const generatePopulationLayer = () => {
  const population_layer = new MVTLayer({
    id: `pop-layer`,
    data: "https://storage.googleapis.com/cropai-mappable/population_h3_3km/{z}/{x}/{-y}.mvt",
    getFillColor: (d: any) => {
        // console.log(`${d.properties.population} + ${scaleQuantile(d.properties.population)}`)
        let km = 0
        if (d.properties.layerName === "Population_H3_22km") {
          km = 22
        } else {
          km = 3
        }
        return (convertRGBtoArray(hexToRgb(mapPopToHexColor(d.properties.population, km, d))))
    },
    minZoom: 0,
    maxZoom: 5,
    opacity: .8
  })
  return population_layer
}

const population_layer_h3 = new H3HexagonLayer({
  id: "pop-layer-h3",
  data: "https://storage.googleapis.com/cropai-mappable/pop_h3.json",
  getFillColor: (d: any) => {
    const val = d.pop ** .3 * 10
    return [0,val, 0]
  },
})

const mapPopToHexColor = (popVal: number, popLevelKm: number, data: any ) => {

  // const logPercent = .00
  // const logEnabled = (Math.random() * 100 <= logPercent)
  // >> Log arbitrary data points
  // if(logEnabled) console.log(`popVal: ${popVal}`)
  // if(logEnabled) console.log(data.properties)

  // >> Log 3km < 10, and 22km < 10
  // if (data.properties.population < 10){
  //   if (popLevelKm === 3){
  //     console.log(data.properties)
  //   }else if (popLevelKm === 22){
  //     console.log(data.properties)
  //   }
  // }

  if (!(popLevelKm === 22 || popLevelKm === 3)){
    // Invalid popLevel
    return "#000000"
  }

  const hexColors = ["#3D004C", "#3A3D7A", "#256C80", "#1E9877", "#6FBE48", "#E6D222"]
  const popRange3km = [40, 400, 1600, 4000, 10_000]
  const popRange22km = [2000, 20_000, 80_000, 200_000, 460_000]

  // If the popLevel is 3km, use popRange3km else use 22km
  const bounds = (popLevelKm === 3) ? popRange3km : popRange22km

  for (let i = 0; i < bounds.length; i++){
    if (popVal <= bounds[i]){
      return hexColors[i]
    }
  }
  // Then the value is greater than the first 5 ranges, so map last color
  return hexColors[hexColors.length - 1]
}
