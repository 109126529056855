import { Box, Skeleton } from "@mui/material"

export const ChartSkeleton = () => {

return (
  <Box className="Chart-Skeleton-Box" sx={{ width: "100%", display: "grid", justifyItems: "center"}}>
    <Skeleton height={'10vh'} width={"90%"} animation="wave" />
    <Skeleton height={'5vh'} width={"90%"} animation="wave" />
    <Skeleton height={'5vh'} width={"90%"} animation="wave" />
    <Skeleton height={'5vh'} width={"90%"} animation="wave" />
  </Box>
)
}
