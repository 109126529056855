import { FormGroup, FormControlLabel, Divider, Typography, Box } from '@mui/material';
import { IStoryPanel, layerType } from '../../store/StoryApi';
import { useState } from 'react';
import { LegendAccordion } from '../LegendAccordion/LegendAccordion';
import { ControlCheckbox } from '../ControlPanel/ControlPanel';
import { IMapView } from '../../store/MapApi';
import { LayerReset } from '../LayerReset/LayerReset';
import { IManageState } from '../CropAI/CropAI';

interface IPanelInputParams {
  state: IManageState,
  panel: IStoryPanel
  panelFilterArray: JSX.Element[]
  mapView: IMapView
}

export const PanelGlobalAg = (params: IPanelInputParams) =>{
  const {panel, panelFilterArray, mapView} = params

  const [legendOpen, setLegendOpen] = useState(false);

  const toggleLegendOpen = () => {
    setLegendOpen(!legendOpen)
  }

  return (
    <FormGroup sx={{paddingLeft: "10px"}}>
    <Box sx={{paddingBottom: "5px"}}>
      <LayerReset
        state={params.state}
        panel={params.panel}
      />
    </Box>
      {panelFilterArray.find(e => e.key === layerType.GLOBAL_CROP) || <></>}
      {panelFilterArray.find(e => e.key === layerType.GLOBAL_POP) || <></>}
      <Divider sx={{padding: "5px"}} variant="fullWidth" />
      <FormControlLabel sx={{paddingTop: "10px"}} control={<ControlCheckbox onClick={toggleLegendOpen} />} label="LEGENDS" />
      <LegendAccordion
        panelLayers={panel.layers}
        legendOpen={legendOpen}
        legendMaxHeight={"35vh"}
        mapView={mapView}
      />
      <Divider sx={{padding: "5px"}} variant="fullWidth" />
      <FormControlLabel sx={{paddingTop: "10px"}} control={<ControlCheckbox disabled />} label="CHARTS" />
    </FormGroup>
  );
}
