import {
  Grid as MuiGrid,
  Box,
  styled,
} from '@mui/material';

export const GridOutline = styled(MuiGrid)(() => ({
  // margin: "initial",
  height: "100%",
  // paddingLeft: '80px',
  // paddingRight: '80px',
}));

export const Grid = styled(MuiGrid)(() => ({
  flexGrow: "1",
  // paddingLeft: '80px',
  // paddingRight: '80px',
  // rotate: "270deg"
}));

export const SliderBox = styled(Box) (() => ({
  position: "absolute",
  bottom: "0vh",
  left: "340px",

  width: "40vh",

  backgroundColor: "#fffcfc",
  opacity: '90%',
  borderRadius: '10px',
}));
