import * as S from './styles'
import { StoryGrid } from "../StoryGrid/StoryGrid";
import { IGeoLayer, IStoryData, ISubFilter, StoryType, layerType } from "../../store/StoryApi";
import { IMapView } from "../../store/MapApi";
import { IManageState, IUpdateLayerSubFilters } from "../CropAI/CropAI";

interface IStoryPanelParams {
  state: IManageState,
  storyData: IStoryData,
  mapView: IMapView,
  handleLayerChange: (panelName: StoryType, newLayers: IGeoLayer[]) => void,
  handleMapViewChange: (newMapView: IMapView) => void,
}

export const StoryPanel = (params: IStoryPanelParams) => {
  return (
    <S.StoryBox className="StoryBox">
      <StoryGrid
        state={params.state}
        mapView={params.mapView}
        storyData={params.storyData}
        handleLayerChange={params.handleLayerChange}
        handleMapViewChange={params.handleMapViewChange}
      />
    </S.StoryBox>
  );
};
