import { FormGroup, FormControlLabel, Divider, Typography, Box } from '@mui/material';
import { IStoryPanel, layerType } from '../../store/StoryApi';
import { LegendAccordion } from '../LegendAccordion/LegendAccordion';
import { ControlCheckbox } from '../ControlPanel/ControlPanel';
import { useState } from 'react';
import { FilterPorts } from './FilterPorts';
import { FilterPotashShippingRoutes } from './FilterPotashShippingRoutes';
import { FilterPhosphateShippingRoutes } from './FilterPhosphateShippingRoutes';
import { FilterNitrogenShippingRoutes } from './FilterNitrogenShippingRoutes';
import { IManageState } from '../CropAI/CropAI';
import { LayerReset } from '../LayerReset/LayerReset';

interface IPanelInputParams {
  state: IManageState,
  panel: IStoryPanel
  panelFilterArray: JSX.Element[]
}

export const PanelRailAndShipping = (params: IPanelInputParams) =>{
  const {state, panel, panelFilterArray} = params
  const [legendOpen, setLegendOpen] = useState(false);

  const toggleLegendOpen = () => {
    console.log("Toggled Legend")
    setLegendOpen(!legendOpen)
  }

  return (
  <FormGroup sx={{paddingLeft: "10px"}}>
    <Box sx={{paddingBottom: "5px"}}>
      <LayerReset
        state={params.state}
        panel={params.panel}
      />
    </Box>
    {panelFilterArray.find(e => e.key === layerType.GLOBAL_RAIL) || <></>}
    {panelFilterArray.find(e => e.key === layerType.PORTS) || <></>}
    {panelFilterArray.find(e => e.key === layerType.POTASH_SHIPPING_ROUTES) || <></>}
    <FormControlLabel control={<ControlCheckbox disabled />} label="Phosphate Shipping Routes 2022" />
    <FormControlLabel control={<ControlCheckbox disabled />} label="Nitrogen Shipping Routes 2022" />
    {/* <FilterPorts
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.PORTS) || <></>}
    />
    <FilterPotashShippingRoutes
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.POTASH_SHIPPING_ROUTES) || <></>}
    />
    <FilterPhosphateShippingRoutes
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.PHOSPHATE_SHIPPING_ROUTES) || <></>}
    />
    <FilterNitrogenShippingRoutes
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.NITROGEN_SHIPPING_ROUTES) || <></>}
    /> */}
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel  sx={{paddingTop: "10px"}} control={<ControlCheckbox onClick={toggleLegendOpen} />} label="LEGENDS" />
    <LegendAccordion
      panelLayers={panel.layers}
      legendOpen={legendOpen}
    />
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel sx={{paddingTop: "10px"}} control={<ControlCheckbox disabled />} label="CHARTS" />
  </FormGroup>
  );
}
