

export enum StoryType_Main {
  _NONE = "_NONE",
  GLOBAL_AG = "GLOBAL_AG",
  AG_INPUTS = "AG_INPUTS",
  RAIL_AND_SHIPPING = "RAIL_AND_SHIPPING",
  TRADEFLOWS = "TRADEFLOWS",
  FARM_DEMAND = "FARM_DEMAND",
  GRAIN = "GRAIN",
}

export enum StoryType {
  _NONE = "_NONE",
  SUPPLY_CHAIN = "SUPPLY_CHAIN",
  CENTRAL_VALLEY_AG = "CENTRAL_VALLEY_AG",
  ADDRESS_SEARCH = "ADDRESS_SEARCH",
  GLOBAL_AG = "GLOBAL_AG",
  AG_INPUTS = "AG_INPUTS",
  RAIL_AND_SHIPPING = "RAIL_AND_SHIPPING",
  TRADEFLOWS = "TRADEFLOWS",
  FARM_DEMAND = "FARM_DEMAND",
  GRAIN = "GRAIN",
}

export const STORIES = [
  { label: "Global Ag", type: StoryType.GLOBAL_AG},
  { label: "Ag Inputs", type: StoryType.AG_INPUTS},
  { label: "Rail and Shipping", type: StoryType.RAIL_AND_SHIPPING},
  { label: "Tradeflows", type: StoryType.TRADEFLOWS},
  { label: "Farm Demand", type: StoryType.FARM_DEMAND},
  // { label: "Grain", type: StoryType.GRAIN},
]

// NOTE: this variable is reference in styling the width and position of StoryButtons
export const NUM_STORIES = STORIES.length

export interface IStoryData {
  panels: IStoryPanel[],
};

export interface IStoryPanel {
  name: StoryType,
  storyOpen: boolean,
  chartsActive: boolean,
  layers: IGeoLayer[],
}

export interface optionSet {
  property: string,
  options: string[],
}

export interface IGeoLayer {
  type: layerType,
  label: string,
  active: boolean,
  activeOptions: optionSet[],
  subFilters: ISubFilter[],
}

export interface ISubFilter {
  field: fieldType,
  label: string,
  propName: string,
  activeValues: string[],
  activeOptions: string[],
  options: string[],
}

export interface IWeatherData {
  fips: string,
  value: number,
}

export interface IShapeData {
  features: any,
}

export interface IStateShapeData {
  features: IStateDataPoint[],
}

export interface IStateDataPoint {
  geometry: any,
  properties: {
    STATEFP: string,
    state_name: string,
    report: {
      corn: any,
      soybeans: any,
    },
    yield: {
      corn: {
        actual: number,
        delta: number,
      },
      soybeans: {
        actual: number,
        delta: number,
      },
    },
  },
  type: string,
}

export enum fieldType {
  POTASH_MINES_COMPANY = "POTASH_MINES_COMPANY",
  POTASH_MINES_COUNTRY = "POTASH_MINES_COUNTRY",
  POTASH_MINES_PRODUCTION_VOLUME = "POTASH_MINES_PRODUCTION_VOLUME",
  PHOSPHATE_MINES_COMPANY = "PHOSPHATE_MINES_COMPANY",
  PHOSPHATE_MINES_COUNTRY = "PHOSPHATE_MINES_COUNTRY",
  PHOSPHATE_MINES_PRODUCTION_VOLUME = "PHOSPHATE_MINES_PRODUCTION_VOLUME",
  NITROGEN_MANUFACTURING_COMPANY = "NITROGEN_MANUFACTURING_COMPANY",
  NITROGEN_MANUFACTURING_COUNTRY = "NITROGEN_MANUFACTURING_COUNTRY",
  NITROGEN_MANUFACTURING_PRODUCTION_VOLUME = "NITROGEN_MANUFACTURING_PRODUCTION_VOLUME",
  NITROGEN_MANUFACTURING_TYPE = "NITROGEN_MANUFACTURING_TYPE",
  YIELD_FORECAST_CROP = "YIELD_FORECAST_CROP",
  YIELD_FORECAST_MODEL = "YIELD_FORECAST_MODEL",
  YIELD_FORECAST_LEVEL = "YIELD_FORECAST_LEVEL",
  PORTS_COMMODITY = "PORTS_COMMODITY",
  SHIPPING_ROUTES_ORIGIN_PORT = "SHIPPING_ROUTES_ORIGIN_PORT",
  SHIPPING_ROUTES_ORIGIN_COUNTRY = "SHIPPING_ROUTES_ORIGIN_COUNTRY",
  SHIPPING_ROUTES_DESTINATION_PORT = "SHIPPING_ROUTES_DESTINATION_PORT",
  SHIPPING_ROUTES_DESTINATION_COUNTRY = "SHIPPING_ROUTES_DESTINATION_COUNTRY",
  SHIPPING_ROUTES_MMSI = "SHIPPING_ROUTES_MMSI",
  SHIPPING_ROUTES_SHIP_TYPE = "SHIPPING_ROUTES_SHIP_TYPE",
  SHIPPING_ROUTES_VESSEL_NAME = "SHIPPING_ROUTES_VESSEL_NAME",
  SHIPPING_ROUTES_ETA = "SHIPPING_ROUTES_ETA",
  TRADEFLOWS_ORIGIN_COUNTRY = "TRADEFLOWS_ORIGIN_COUNTRY",
  TRADEFLOWS_ORIGIN_PORT = "TRADEFLOWS_ORIGIN_PORT",
  TRADEFLOWS_DESTINATION_COUNTRY = "TRADEFLOWS_DESTINATION_COUNTRY",
  TRADEFLOWS_DESTINATION_PORT = "TRADEFLOWS_DESTINATION_PORT",
  AG_RETAILERS_COMPANY = "AG_RETAILERS_COMPANY",
  FARM360_CROP = "FARM360_CROP",
}

export enum layerType {
  GLOBAL_CROP = "GLOBAL_CROP",
  GLOBAL_POP = "GLOBAL_POP",
  POTASH_DEPOSITS = "POTASH_DEPOSITS",
  POTASH_MINES = "POTASH_MINES",
  PHOSPHATE_MINES = "PHOSPHATE_MINES",
  PHOSPHATE_DEPOSITS = "PHOSPHATE_DEPOSITS",
  PHOSPHOROUS_APPLICATION = "PHOSPHOROUS_APPLICATION",
  NITROGEN_MANUFACTURING = "NITROGEN_MANUFACTURING",
  GLOBAL_RAIL = "GLOBAL_RAIL",
  PORTS = "PORTS",
  POTASH_SHIPPING_ROUTES = "POTASH_SHIPPING_ROUTES",
  PHOSPHATE_SHIPPING_ROUTES = "PHOSPHATE_SHIPPING_ROUTES",
  NITROGEN_SHIPPING_ROUTES = "NITROGEN_SHIPPING_ROUTES",
  NITROGEN_APPLICATION = "NITROGEN_APPLICATION",
  POTASH_TRADEFLOWS = "POTASH_TRADEFLOWS",
  PHOSPHATE_TRADEFLOWS = "PHOSPHATE_TRADEFLOWS",
  NITROGEN_TRADEFLOWS = "NITROGEN_TRADEFLOWS",
  AG_RETAILERS = "AG_RETAILERS",
  CALI_EVI = "CALI_EVI",
  CALI_SAM = "CALI_SAM",
  YIELD_FORECAST = "YIELD_FORECAST",
  FD_TEMPERATURE = "FD_TEMPERATURE",
  FD_PRECIPITATION = "FD_PRECIPITATION",
  FD_DROUGHT = "FD_DROUGHT",
}

// Setup panels for each active Story
export const globalAg: IStoryPanel = {
  name: StoryType.GLOBAL_AG,
  storyOpen: false,
  chartsActive: false,
  layers: [
    {
      type: layerType.GLOBAL_CROP,
      label: "Cropland",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.GLOBAL_POP,
      label: "Population Density",
      active: false,
      activeOptions: [],
      subFilters: []
    },
  ]
}

export const agInputs: IStoryPanel = {
  name: StoryType.AG_INPUTS,
  storyOpen: false,
  chartsActive: false,
  layers: [
    {
      type: layerType.POTASH_MINES,
      label: "Potash Mines",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.POTASH_MINES_COMPANY,
          label: "Company",
          propName: "Company",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.POTASH_MINES_COUNTRY,
          label: "Country",
          propName: "Country",
          activeValues: [],
          activeOptions: [],
          options: [
              "Australia",
              "Austria",
              "Belarus",
              "Belgium",
              "Bolivia",
              "Brazil",
              "Canada",
              "Chile",
              "China",
              "Colombia",
              "Denmark",
              "Egypt",
              "Finland",
              "Germany",
              "India",
              "Indonesia",
              "Iran",
              "Israel",
              "Italy",
              "Japan",
              "Jordan",
              "Laos",
              "Pakistan",
              "Peru",
              "Philippines",
              "Poland",
              "Russia",
              "Saudi Arabia",
              "South Africa",
              "South Korea",
              "Spain",
              "Sweden",
              "Taiwan",
              "Turkey",
              "Turkmenistan",
              "UK",
              "Ukraine",
              "USA",
              "Uzbekistan",
              "Vietnam"
          ],
        },
        {
          field: fieldType.POTASH_MINES_PRODUCTION_VOLUME,
          label: "2022 Production vol (kt) greater than",
          propName: "2022 Production vol (kt) greater than",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.POTASH_DEPOSITS,
      label: "Potash Deposits",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.PHOSPHATE_MINES,
      label: "Phosphate Mines",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.PHOSPHATE_MINES_COMPANY,
          label: "Company",
          propName: "Company",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.PHOSPHATE_MINES_COUNTRY,
          label: "Country",
          propName: "Country",
          activeValues: [],
          activeOptions: [],
          options: [
              "Australia",
              "Austria",
              "Belarus",
              "Belgium",
              "Bolivia",
              "Brazil",
              "Canada",
              "Chile",
              "China",
              "Colombia",
              "Denmark",
              "Egypt",
              "Finland",
              "Germany",
              "India",
              "Indonesia",
              "Iran",
              "Israel",
              "Italy",
              "Japan",
              "Jordan",
              "Laos",
              "Pakistan",
              "Peru",
              "Philippines",
              "Poland",
              "Russia",
              "Saudi Arabia",
              "South Africa",
              "South Korea",
              "Spain",
              "Sweden",
              "Taiwan",
              "Turkey",
              "Turkmenistan",
              "UK",
              "Ukraine",
              "USA",
              "Uzbekistan",
              "Vietnam"
          ],
        },
        {
          field: fieldType.PHOSPHATE_MINES_PRODUCTION_VOLUME,
          label: "2022 Production vol (kt) greater than",
          propName: "2022 Production vol (kt) greater than",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.PHOSPHATE_DEPOSITS,
      label: "Phosphate Deposits",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.NITROGEN_MANUFACTURING,
      label: "Nitrogen Manufacturing",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.NITROGEN_MANUFACTURING_COMPANY,
          label: "Company",
          propName: "Company",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.NITROGEN_MANUFACTURING_COUNTRY,
          label: "Country",
          propName: "Country",
          activeValues: [],
          activeOptions: [],
          options: [
              "Australia",
              "Austria",
              "Belarus",
              "Belgium",
              "Bolivia",
              "Brazil",
              "Canada",
              "Chile",
              "China",
              "Colombia",
              "Denmark",
              "Egypt",
              "Finland",
              "Germany",
              "India",
              "Indonesia",
              "Iran",
              "Israel",
              "Italy",
              "Japan",
              "Jordan",
              "Laos",
              "Pakistan",
              "Peru",
              "Philippines",
              "Poland",
              "Russia",
              "Saudi Arabia",
              "South Africa",
              "South Korea",
              "Spain",
              "Sweden",
              "Taiwan",
              "Turkey",
              "Turkmenistan",
              "UK",
              "Ukraine",
              "USA",
              "Uzbekistan",
              "Vietnam"
          ],
        },
        {
          field: fieldType.NITROGEN_MANUFACTURING_PRODUCTION_VOLUME,
          label: "2022 Production vol (kt) greater than",
          propName: "2022 Production vol (kt) greater than",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.NITROGEN_MANUFACTURING_TYPE,
          label: "Type",
          propName: "Type",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ],
    },
    {
      type: layerType.PHOSPHOROUS_APPLICATION,
      label: "Phosphorous Application",
      active: false,
      activeOptions: [],
      subFilters: [],
    },
    {
      type: layerType.NITROGEN_APPLICATION,
      label: "Nitrogen Application",
      active: false,
      activeOptions: [],
      subFilters: [],
    },
  ]
}

export const railAndShipping: IStoryPanel = {
  name: StoryType.RAIL_AND_SHIPPING,
  storyOpen: false,
  chartsActive: false,
  layers: [
    {
      type: layerType.GLOBAL_RAIL,
      label: "Global Rail",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.PORTS,
      label: "Ports",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.PORTS_COMMODITY,
          label: "Commodity",
          propName: "Commodity",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.POTASH_SHIPPING_ROUTES,
      label: "Potash Shipping Routes 2022",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.SHIPPING_ROUTES_ORIGIN_PORT,
          label: "Origin Port",
          propName: "Origin Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_ORIGIN_COUNTRY,
          label: "Origin Country",
          propName: "Origin Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_DESTINATION_PORT,
          label: "Destination Port",
          propName: "Destination Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_DESTINATION_COUNTRY,
          label: "Destination Country",
          propName: "Destination Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_MMSI,
          label: "MMSI",
          propName: "MMSI",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_SHIP_TYPE,
          label: "Ship type",
          propName: "Ship type",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_VESSEL_NAME,
          label: "Vessel Name",
          propName: "Vessel Name",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_ETA,
          label: "ETA",
          propName: "ETA",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.PHOSPHATE_SHIPPING_ROUTES,
      label: "Phosphate Shipping Routes 2022",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.SHIPPING_ROUTES_ORIGIN_PORT,
          label: "Origin Port",
          propName: "Origin Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_ORIGIN_COUNTRY,
          label: "Origin Country",
          propName: "Origin Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_DESTINATION_PORT,
          label: "Destination Port",
          propName: "Destination Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_DESTINATION_COUNTRY,
          label: "Destination Country",
          propName: "Destination Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_MMSI,
          label: "MMSI",
          propName: "MMSI",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_SHIP_TYPE,
          label: "Ship type",
          propName: "Ship type",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_VESSEL_NAME,
          label: "Vessel Name",
          propName: "Vessel Name",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_ETA,
          label: "ETA",
          propName: "ETA",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.NITROGEN_SHIPPING_ROUTES,
      label: "Nitrogen Shipping Routes 2022",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.SHIPPING_ROUTES_ORIGIN_PORT,
          label: "Origin Port",
          propName: "Origin Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_ORIGIN_COUNTRY,
          label: "Origin Country",
          propName: "Origin Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_DESTINATION_PORT,
          label: "Destination Port",
          propName: "Destination Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_DESTINATION_COUNTRY,
          label: "Destination Country",
          propName: "Destination Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_MMSI,
          label: "MMSI",
          propName: "MMSI",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_SHIP_TYPE,
          label: "Ship type",
          propName: "Ship type",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_VESSEL_NAME,
          label: "Vessel Name",
          propName: "Vessel Name",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.SHIPPING_ROUTES_ETA,
          label: "ETA",
          propName: "ETA",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
  ]
}

export const tradeflows: IStoryPanel = {
  name: StoryType.TRADEFLOWS,
  storyOpen: false,
  chartsActive: false,
  layers: [
    {
      type: layerType.POTASH_TRADEFLOWS,
      label: "Potash Tradeflows 2022",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.TRADEFLOWS_ORIGIN_COUNTRY,
          label: "Origin Country",
          propName: "Origin Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_ORIGIN_PORT,
          label: "Origin Port",
          propName: "Origin Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_DESTINATION_COUNTRY,
          label: "Destination Country",
          propName: "Destination Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_DESTINATION_PORT,
          label: "Destination Port",
          propName: "Destination Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.PHOSPHATE_TRADEFLOWS,
      label: "Phosphate Tradeflows 2022",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.TRADEFLOWS_ORIGIN_COUNTRY,
          label: "Origin Country",
          propName: "Origin Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_ORIGIN_PORT,
          label: "Origin Port",
          propName: "Origin Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_DESTINATION_COUNTRY,
          label: "Destination Country",
          propName: "Destination Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_DESTINATION_PORT,
          label: "Destination Port",
          propName: "Destination Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.NITROGEN_TRADEFLOWS,
      label: "Nitrogen Tradeflows 2022",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.TRADEFLOWS_ORIGIN_COUNTRY,
          label: "Origin Country",
          propName: "Origin Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_ORIGIN_PORT,
          label: "Origin Port",
          propName: "Origin Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_DESTINATION_COUNTRY,
          label: "Destination Country",
          propName: "Destination Country",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
        {
          field: fieldType.TRADEFLOWS_DESTINATION_PORT,
          label: "Destination Port",
          propName: "Destination Port",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
  ]
}

export const farmDemand: IStoryPanel = {
  name: StoryType.FARM_DEMAND,
  storyOpen: false,
  chartsActive: false,
  layers: [
    {
      type: layerType.AG_RETAILERS,
      label: "Ag Retailers US & CA",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.AG_RETAILERS_COMPANY,
          label: "Company",
          propName: "Company",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.CALI_SAM,
      label: "Farm 360 (Iowa)",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.FARM360_CROP,
          label: "Crop",
          propName: "Crop",
          activeValues: [],
          activeOptions: [],
          options: [],
        },
      ]
    },
    {
      type: layerType.CALI_EVI,
      label: "False Color Phenological EVI",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.YIELD_FORECAST,
      label: "Yield Forecast",
      active: false,
      activeOptions: [],
      subFilters: [
        {
          field: fieldType.YIELD_FORECAST_CROP,
          label: "Crop",
          propName: "---",
          activeValues: [],
          activeOptions: [],
          options: [
            "Corn",
            "Soybeans"
          ],
        },
        {
          field: fieldType.YIELD_FORECAST_MODEL,
          label: "Yield",
          propName: "---",
          activeValues: [],
          activeOptions: [],
          options: [
            "Actual",
            "Delta"
          ],
        },
        {
          field: fieldType.YIELD_FORECAST_LEVEL,
          label: "Level",
          propName: "---",
          activeValues: [],
          activeOptions: [],
          options: [
            "County",
            "State"
          ],
        },
      ]
    },
    {
      type: layerType.FD_TEMPERATURE,
      label: "Temperature",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.FD_PRECIPITATION,
      label: "Precipitation",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.FD_DROUGHT,
      label: "Drought",
      active: false,
      activeOptions: [],
      subFilters: []
    },
  ]
}

export const grain: IStoryPanel = {
  name: StoryType.GRAIN,
  storyOpen: false,
  chartsActive: false,
  layers: []
}

// WWOM Demo Panels
export const supplyChain: IStoryPanel = {
  name: StoryType.SUPPLY_CHAIN,
  storyOpen: false,
  chartsActive: false,
  layers: [
    {
      type: layerType.GLOBAL_CROP,
      label: "Cropland",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.GLOBAL_POP,
      label: "Population",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.POTASH_DEPOSITS,
      label: "Potash Deposits",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.POTASH_MINES,
      label: "Potash Mines",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.GLOBAL_RAIL,
      label: "Global Rail",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.PORTS,
      label: "Ports",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.POTASH_SHIPPING_ROUTES,
      label: "Potash Shipping Routes 2022",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.POTASH_TRADEFLOWS,
      label: "Potash Tradeflows 2022",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.AG_RETAILERS,
      label: "Ag Retailers US & CA",
      active: false,
      activeOptions: [],
      subFilters: []
    },
  ]
}

export const centralValley: IStoryPanel = {
  name: StoryType.CENTRAL_VALLEY_AG,
  storyOpen: false,
  chartsActive: false,
  layers: [
    {
      type: layerType.CALI_EVI,
      label: "False Color Phenological EVI",
      active: false,
      activeOptions: [],
      subFilters: []
    },
    {
      type: layerType.CALI_SAM,
      label: "Agriculture Fields Segmentation",
      active: false,
      activeOptions: [],
      subFilters: []
    },
  ]
}
