import { MVTLayer } from "@deck.gl/geo-layers/typed"
import { hexToRgb } from "@mui/system"
import { convertRGBtoArray } from "../../utils/color"
import { GeoJsonLayer } from "@deck.gl/layers"

// RailAndShipping: Global Rail
export const generateRail = () => {
  const rail_layer = new MVTLayer({
    id: `rail-layer`,
    data: `https://storage.googleapis.com/cropai-mappable/global_rail/{z}/{x}/{-y}.mvt`,
    getLineColor: convertRGBtoArray(hexToRgb(("#00A887"))),
    getLineWidth: 2.0,
    lineWidthUnits: 'pixels',
    // lineWidthMinPixels: 1,
    minZoom: 0,
    maxZoom: 9,
    opacity: .75
  })
  return rail_layer
}

// Ports
export const generatePortsLayer = () => {
  const portsLayer = new GeoJsonLayer({
    id: 'ports-layer',
    data: "https://storage.googleapis.com/cropai-mappable/05262023_Ports.geojson",
    // data: "https://storage.googleapis.com/cropai-mappable/GlobalPorts.geojson",
    getFillColor: convertRGBtoArray(hexToRgb(("#F6D647"))),
    pointRadiusMinPixels: 4,
    stroked: true,
    getLineColor: [255, 255, 255],
    getLineWidth: 0.8,
    lineWidthUnits: 'pixels',
    opacity: .75,
      pickable: true
  })
  return portsLayer
}

// Potash Shipping Routes 2022
export const generatePotashShippingPoints = () => {
  const route_points_layer = new GeoJsonLayer({
    id: 'route-points-layer',
    data: "https://storage.googleapis.com/cropai-mappable/03292023_Routes_Points_WithTime_V2.geojson",
    pointRadiusMinPixels: 1.5,
    pointRadiusUnits: "pixels",
    getPointRadius: (d: any) => {
      return 1.5
      // return (getMineSizeByProdVolume(d.properties._22_ProdVo))
      // return Math.min(d.properties._22_ProdCa**.3, 24)
    },
    getFillColor: convertRGBtoArray(hexToRgb(("#B4B7B6"))),
    opacity: .3,
      pickable: true
  })
  return route_points_layer
}
