import Box from '@mui/material/Box';
import { DialogTitle, IconButton, Skeleton, Typography } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { StoryType } from '../../store/StoryApi';
import { useState, useEffect, useRef } from 'react'
import { IGeoLayer, IStoryData } from '../../store/StoryApi';
import { ControlPanel } from '../ControlPanel/ControlPanel';
import * as S from './styles'
import { IMapView } from '../../store/MapApi';
import { renderYieldForecastLegend } from '../LegendAccordion/LegendAccordion';


export function DangerouslySetHtmlContent(props:{html:string, style:object, level: string, path:string, setTitleText: (value: React.SetStateAction<string>) => void}) {
  // if (!props.html){return <div/>}
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef(null)

  useEffect(() => {
      if (props.html && divRef.current){
        const slotHtml = document.createRange().createContextualFragment(props.html) // Create a 'tiny' document and parse the html string
        // @ts-ignore
        divRef.current.innerHTML = '' // Clear the container
        // @ts-ignore
        divRef.current.appendChild(slotHtml) // Append the new content
        console.log(`set divRef for ${props.path}`)
        if (props.html !== "<div/>"){
          props.setTitleText(`${props.level} YIELD FORECAST`)
        }
      }else{
        console.log(`Failed load HTML Chart for ${props.path}`)
        // props.setTitleText("Failed to fetch yield data.")
              }
  }, [props.html, divRef, props.path, props.level])

  // eslint-disable-next-line react/react-in-jsx-scope
    return <div style={props.style} ref={divRef}/>
}

export enum chartType {
  PANEL = "PANEL",
  DASHBOARD = "DASHBOARD",
}

interface IGenerateCharmParams {
  host: string,
  path: string,
  level: string,
  type: chartType,
}

export const GenerateChart = (params: IGenerateCharmParams) =>{

  const {host, path, level, type} = params

  const [chartHtml, setChartHtml] = useState<string>("<div/>");
  const [titleText, setTitleText] = useState<string>(`${level}: FETCHING DATA...`);

  const query = host + path

  // useEffect(() => {
  //     // fetch("http://0.0.0.0:5000/plots/demo?crop=corn&year=2020")
  //     fetch(query)
  //         // @ts-ignore
  //         .then(response => response.json())
  //         .then((data) => {
  //           console.log(data)
  //           if (data['html']){
  //             setChartHtml(data['html'])
  //           }
  //         })
  //         .catch(error => {
  //           console.log("Fetch error for chart.", error)
  //           setTitleText(`${level}: Failed to fetch yield data.`)
  //         });
  // }, [query]);

  useEffect(() => {
    // fetch("http://0.0.0.0:5000/plots/demo?crop=corn&year=2020")
    fetch(query, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then((data) => {
          // console.log(data)
          if (data['html']){
            setChartHtml(data['html'])
          }
        })
        .catch(error => {
          console.log("Fetch error for chart.", error)
          setTitleText(`${level}: Failed to fetch yield data.`)
        });
}, [query]);

  const chart =
    <DangerouslySetHtmlContent
      path = {path}
      html={chartHtml}
      style={{}}
      level={level}
      setTitleText={setTitleText}
    />

  const renderForPanel = () => {
    return (
      <>
        {(level !== "COUNTY") &&
        <Typography variant="subtitle1">{titleText}</Typography>
        }
        {(titleText  === `${level}: FETCHING DATA...`) &&
        <Box sx={{ width: 300 }}>
          <Skeleton height={'10vh'} animation="wave" />
          <Skeleton height={'5vh'} animation="wave" />
          <Skeleton height={'5vh'} animation="wave" />
        </Box>
        }
        {chart ?? <div/>}
        {(titleText  === `STATE YIELD FORECAST`) &&
        <Box sx={{width: 300}}>
          {renderYieldForecastLegend()}
        </Box>
        }
      </>
    );
  }

  const renderForDashboard = () => {
    return (
      <>
        {(titleText  === `${level}: FETCHING DATA...`) &&
        <Box sx={{ width: "25vw" }}>
          <Skeleton height={'10vh'} animation="wave" />
          <Skeleton height={'5vh'} animation="wave" />
          <Skeleton height={'5vh'} animation="wave" />
          <Skeleton height={'5vh'} animation="wave" />
        </Box>
        }
        {chart ?? <div/>}
      </>
    );
  }

  return (
    <>
      {type === chartType.PANEL ? renderForPanel() : renderForDashboard()}
    </>
  );
}
