import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams, autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import { Box, Checkbox } from '@mui/material';
import { IManageState } from '../CropAI/CropAI';
import { IStoryData, IStoryPanel, ISubFilter, layerType } from '../../store/StoryApi';
import { produce } from 'immer';

const LISTBOX_PADDING = 8; // px

type ListChildComponentProps = typeof ListChildComponentProps
type VariableSizeList = typeof VariableSizeList


function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography  component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {/* {`#${dataSet[2] + 1} - ${dataSet[1]}`} */}
      {`${dataSet[1]}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 16) {
      return 16 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  const calcWidth = () => {
    const bound = window.innerWidth
  }

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          class="VariableSizeList"
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="400px"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

function random(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

// const StyledPopper = styled(Popper)({
//   width: "400px",
//   color: "blue",
//   backgroundColor: 'black',
//   [`& .${autocompleteClasses.listbox}`]: {
//     width: "400px",
//     color: "red",
//     backgroundColor: 'blue',
//     boxSizing: 'border-box',
//     '& ul': {
//       backgroundColor: 'green',

//       width: "401px",
//       padding: 0,
//       margin: 0,
//     },
//   },
// });

const popperStyles = {
  maxWidth: "fit-content",
  [`& .${autocompleteClasses.listbox}`]: {
    width: "400px",
    color: "red",
    boxSizing: 'border-box',
    '& ul': {
      width: "401px",
      padding: 0,
      margin: 0,
    },
  },
}

const StyledPopper = function (props: any) {
  return <Popper {...props}
  style={{width:'fit-content'}}
  sx={{
    width: "fit-content",
    color: "blue",
    // backgroundColor: 'black',
    [`& .${autocompleteClasses.listbox}`]: {
      width: "400px",
      // color: "red",
      opacity: '90%',
      boxSizing: 'border-box',
      '& ul': {

        width: "401px",
        padding: 0,
        margin: 0,
      },
    },
  }}
  placement="bottom-start"
  />;
};

const OPTIONS = Array.from(new Array(10000))
  .map(() => random(10 + Math.ceil(Math.random() * 20)))
  .sort((a: string, b: string) =>
    a.toUpperCase().localeCompare(b.toUpperCase())
  );

interface IFilterParams {
  state: IManageState,
  panel: IStoryPanel,
  layerName: layerType,
  subFilter: ISubFilter,
}

// const inputChange = (inParams: AutocompleteRenderInputParams) => {
//   // console.log({...inParams.InputProps})
//   return inParams
// }

export const VirtualAutoMultiFilter = (params: IFilterParams)  => {
  const {state, panel, layerName, subFilter} = params

  const inputChange = (event: React.SyntheticEvent, value: string, reason:string ) => {
    // const {
    //   target: { value },
    // } = event;
    console.log(value)
    console.log("here")
  }

  const handleChange = (event: React.SyntheticEvent, value: Array<string>, reason:string ) => {
    console.log(value)

    state.setStoryData(produce<IStoryData>(draft => {
      const panelIndex = draft.panels.findIndex(e => e.name === panel.name)
      if (panelIndex !== -1){
        const layerIndex = panel.layers.findIndex(e => e.type === layerName)
        if (layerIndex !== -1){
          const subFilterIndex = panel.layers[layerIndex].subFilters.findIndex(e => e.field === subFilter.field)
          if (subFilterIndex !== -1){
            draft.panels[panelIndex].layers[layerIndex].subFilters[subFilterIndex].activeValues = [...value]
            console.log("VAM Filter update values")
          }
        }
      }
    }))

  }

  return (
    <Box sx={{paddingY: "5px"}}>
      <Typography fontSize={"14px"} color={"#667080"}>{subFilter.label}</Typography>
      <Autocomplete
        className="Autocomplete Class"
        id="virtualize-autocomplete"
        sx={{ minWidth: 240, maxWidth: 280, minHeight: 30, backgroundColor: 'white'}}
        disableListWrap
        disableCloseOnSelect
        PopperComponent={StyledPopper}
        multiple={true}
        limitTags={2}
        ListboxComponent={ListboxComponent}
        options={subFilter.options}
        // onInputChange={inputChange}
        onChange={handleChange}
        // groupBy={(option) => option[0].toUpperCase()}
        renderInput={(input) =>
          <TextField
            {...input} placeholder='Add Filter'
            size="small"
            // sx={{
            //   input: {
            //     paddingRight: 0,
            //     color: "red"
            //   },
            //   root: {
            //     paddingRight: 0,
            //     backgroundColor: "blue"
            //   }
            // }}
          />
        }
        renderOption={(props, option, state) =>
          [props, option, state.index] as React.ReactNode
        }
        // TODO: Post React 18 update - validate this conversion, look like a hidden bug
        renderGroup={(params) => params as unknown as React.ReactNode}
      />
    </Box>
  );
}
