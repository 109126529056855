import { Box, Divider, FormControl, Grid, IconButton, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { IRenderMapParams, RenderMap } from "../MapLayers/RenderMap";
import { PanelSubFilterSingle } from "../PanelSubFilter/PanelSubFilterSingle";
import { IManageCache, IManageState } from "../CropAI/CropAI";
import { IGeoLayer, IStoryPanel, StoryType, fieldType, layerType } from "../../store/StoryApi";
import {South as SouthIcon, North as NorthIcon, Launch, Info } from '@mui/icons-material';
import { getLegendYieldForecastImage} from "../LegendAccordion/LegendAccordion";
import { useState } from "react";
import { GeographyStateFilter } from "../PanelSubFilter/GeographyStateFilter";
import moment from "moment";
import { IMapView, STATE_FIPS_MAPPING } from "../../store/MapApi";
import { MapOverlayMenu } from "./MapOverlayMenu";
import { useNavigate } from 'react-router-dom';
import { AddressSearch } from "../MapLayers/AddressSearch";
import { NationalBarChart } from "../Charts/NationalBarChart";
import { CountyBarChart } from "../Charts/CountyBarChart";
import { NationalYieldChart } from "../Charts/NationalYieldChart";
import { StateYieldChart } from "../Charts/StateYieldChart";
import { NationalChiChart } from "../Charts/NationalChiChart";
import { StateChiChart } from "../Charts/StateChiChart";

interface IDashboardParams {
  mapParams: IRenderMapParams,
  state: IManageState,
  panel: IStoryPanel,
  layer: IGeoLayer,
  cache: IManageCache,
  handleMapViewChange: (newMapView: IMapView) => void,
  manualToggleLayer: (panelName: StoryType, layerName: layerType, activate: boolean) => void,
}

// Dashboard Export
export const Dashboard = (params: IDashboardParams) => {
  const {mapParams, state, panel, layer, cache, handleMapViewChange, manualToggleLayer} = params

  enum forecastType {
    PRODUCTION = "production",
    YIELD = "yield",
    ACRE = "acreage",
  }

  enum cropType {
    CORN = "CORN",
    SOYBEANS = "SOYBEANS",
  }

  // Name will be "Forecast", actual state value is "actual"
  enum yieldType {
    FORECAST = "ACTUAL",
    DELTA = "DELTA",
  }

  const textBlurb2 =
  "This forecast is the product of a joint initiative between BCG X and BCG's Industrial Goods Practice Area. "
  + "The forecast is enabled by Machine Learning, advanced satellite and remote sensing data processing from Google Earth Engine, and more. "
  + "It aims to provide U.S. Corn and Soybean yield and production estimates earlier and with more accuracy than those from the USDA. "
  + "The information contained in this dashboard is not intended as, and shall not be understood or construed as, financial advice."

  // Access values from state
  const cropFilter = layer.subFilters.find(e => e.field === fieldType.YIELD_FORECAST_CROP)
  // TODO: Needs to be restructured for County/State and then sub state options? Or if state selected then display other?
  const geoFilter = layer.subFilters.find(e => e.field === fieldType.YIELD_FORECAST_LEVEL)
  const modelFilter = layer.subFilters.find(e => e.field === fieldType.YIELD_FORECAST_MODEL)

  // Get YF options: Fall back to Corn/National/Forecast
  const CURRENT_CROP = cropFilter?.activeValues[0]?.toUpperCase() ?? cropType.CORN
  const CURRENT_GEOGRAPHY = geoFilter?.activeValues[0]?.toUpperCase() ?? "COUNTY"
  const CURRENT_YIELD = modelFilter?.activeValues[0]?.toUpperCase() ?? yieldType.FORECAST

  const getNonPluralTitle = () => {
    if (CURRENT_CROP === 'SOYBEANS'){
      return 'SOYBEAN'
    }else{
      return CURRENT_CROP
    }
  }

  const renderCropGeoFilters = () => {
    return (
      <Box className="CropGeoFilters">
        <Grid container spacing={0} columns={12} marginTop={4} sx={{justifyContent:"space-around"}}>
          <Grid item xs={12}>
            <Typography variant='h5' fontWeight={"Bold"}>{`${getNonPluralTitle()} FORECASTS - ${CURRENT_GEOGRAPHY === "COUNTY" ? "NATIONAL" : CURRENT_GEOGRAPHY}`}</Typography>
          </Grid>
          <Grid item xs={3.5}>
            {cropFilter &&
              <PanelSubFilterSingle
                key={cropFilter.field}
                panel={panel}
                layerName={layer.type}
                subFilter={cropFilter}
                state={state}
                defaultSelection={"Corn"}
                override=
                {{
                  width: "100%",
                  backgroundColor: "#EDEDED",
                }}
              />
            }
          </Grid>
          <Grid item xs={3.5}>
            {geoFilter &&
              <GeographyStateFilter
                key={geoFilter.field}
                panel={panel}
                layerName={layer.type}
                subFilter={geoFilter}
                state={state}
                defaultSelection={"County"}
                handleMapViewChange={handleMapViewChange}
                override=
                {{
                  width: "100%",
                  label: "Geography",
                  backgroundColor: "#EDEDED",
                }}
              />
            }
            </Grid >
            <Grid item xs={3.5}>
            <Typography fontSize={"14px"} color={"#667080"}>Date</Typography>
            <FormControl sx={{ width: "100%" }} size="small" disabled>
            <Select
              labelId="select-disabled-label"
              id="select-disabled"
              multiple={false}
              displayEmpty={true}
              defaultValue={1}
              variant="outlined"
              sx={{
                backgroundColor: "white",
                height: "30px",
                maxWidth: "200px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              >
                <MenuItem value={1}>{moment().format('LL')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
    </Box>
    )
  }

  // Get a yield caption specifying % difference between BCG and USDA yield forecasts
  const getYieldCaption = (cropData: any) => {
    let diffPercent: number = 0;
    let yieldBCG: number = 0;
    let yieldUSDA: number = 0;

    if (cropData[`national_yield_bcg`] && +(cropData[`national_yield_bcg`]).toFixed(1) !== 0){
      yieldBCG = +(cropData[`national_yield_bcg`]).toFixed(1);
    }
    if (cropData[`national_yield_usda`] && +(cropData[`national_yield_usda`]).toFixed(1) !== 0){
      yieldUSDA = +(cropData[`national_yield_usda`]).toFixed(1);
    }

    const scope = (CURRENT_GEOGRAPHY.toUpperCase()==="COUNTY" ? "National" : capitalizeFirst(CURRENT_GEOGRAPHY))

    // Set caption
    if (yieldBCG === 0 || yieldUSDA === 0){
      return(`Projections for ${scope} ${(CURRENT_CROP).toLowerCase()} yield.`);
    }else{
      diffPercent = +(((yieldBCG / yieldUSDA) - 1) * 100).toFixed(1);
    }

    if (diffPercent > 0){
      return(`BCG projections for ${scope} ${(CURRENT_CROP).toLowerCase()} yield are ${Math.abs(diffPercent)}% above USDA.`)
    } else if (diffPercent < 0){
      return(`BCG projections for ${scope} ${(CURRENT_CROP).toLowerCase()} yield are ${Math.abs(diffPercent)}% below USDA.`)
    }else{
      return(`BCG projections for ${scope} ${(CURRENT_CROP).toLowerCase()} yield match USDA projections.`)
    }
  }

    // Get a yield caption specifying % difference between BCG and USDA yield forecasts
    const getProductionCaption = (cropData: any) => {
      let difference: number = 0;
      let productionBCG: number = 0;
      let productionUSDA: number = 0;

      const scope = (CURRENT_GEOGRAPHY.toUpperCase()==="COUNTY" ? "National" : capitalizeFirst(CURRENT_GEOGRAPHY))

      if (cropData[`national_production_bcg`] && +(cropData[`national_production_bcg`]).toFixed(1) !== 0){
        productionBCG = +(cropData[`national_production_bcg`]).toFixed(1);
      }
      if (cropData[`national_production_usda`] && +(cropData[`national_production_usda`]).toFixed(1) !== 0){
        productionUSDA = +(cropData[`national_production_usda`]).toFixed(1);
      }

      // Set caption
      if (productionBCG === 0 || productionUSDA === 0){
        return(`Projections for ${scope} ${(CURRENT_CROP).toLowerCase()} production.`);
      }else{
        difference = +((productionBCG - productionUSDA).toFixed(1))
      }

      let divisor = 1e9
      let units = "billion"
      // If difference is less than 1 billion, use millions as unit
      if (Math.abs(difference) < 1e9){
        divisor = 1e6
        units = "million"
      }

      if (difference > 0){
        return(`BCG projections for ${scope} ${(CURRENT_CROP).toLowerCase()} production are ${(Math.abs(difference) / divisor).toFixed(divisor === 1e9 ? 1 : 0)} ${units} bushels above USDA.`)
      } else if (difference < 0){
        return(`BCG projections for ${scope} ${(CURRENT_CROP).toLowerCase()} production are ${(Math.abs(difference) / divisor).toFixed(divisor === 1e9 ? 1 : 0)} ${units} bushels below USDA.`)
      }else{
        return(`BCG projections for ${scope} ${(CURRENT_CROP).toLowerCase()} production match USDA projections.`)
      }
    }

  const renderMetricHeader = (cropData: any, metric: forecastType) => {


    let header = ""
    let metricBody = ""
      if (metric === forecastType.PRODUCTION){
        metricBody = getProductionCaption(cropData);
        header = "Production"
      } else if (metric === forecastType.YIELD){
        metricBody = getYieldCaption(cropData);
        header = "Yield"
      } else if (metric === forecastType.ACRE){
        metricBody = "BCG adopts the USDA planted and harvested acres.";
        header = "Acreage"
      }

    return (
      <Grid container spacing={0} columns={12} sx={{
        display: "flex",
        alignItems: "center",
      }}>
      <Grid item direction={"column"} xs={12} sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Typography variant='h6' fontWeight={"Bold"}>{header}</Typography>
      {/* <Typography marginTop={"-8px"} marginBottom={"2px"} fontSize={"12px"} variant='caption'>{metric.subtitle}</Typography> */}
      <Typography align="center" fontSize={12}>{metricBody}</Typography>
      </Grid>
    </Grid>
    )
  }

  const renderNationalForecast = (cropData: any, forecast: forecastType) => {

    // Set units
    let unit: string =''
    if (forecast=== "production"){
      unit = "billion bushels"
    } else if (forecast ==="yield"){
      unit = "bushels per acre"
    } else if (forecast === "acreage"){
      unit = "million acres"
    }

    // Set divisor, 1 billion for production, 1 million for acreage, 1 for yield
    let divisor = 1
    switch (forecast){
     case forecastType.PRODUCTION:
      divisor = 1e9
      break;
    case forecastType.ACRE:
      divisor = 1e6
      break;
    default:
      divisor = 1
      break
    }

    // Get up or down arrow based on delta value for {type}: where {type} is either 'bcg' or 'usda'
    const getChangeArrow = (type: string) => {
      if (['bcg', 'usda'].includes(type)){
        const delta = cropData[`delta_national_${forecast}_${type}`] && cropData[`delta_national_${forecast}_${type}`].toFixed(1)
        if (delta > 0){
          return (<NorthIcon style={{color: "#28B428", fontSize: "medium" }} />)
        } else if (delta < 0){
          return(<SouthIcon style={{color: "#B42828", fontSize: "medium" }} />)
        }
      }
      // Otherwise return no arrow
      return <></>
    }

    // Avoid displaying a negative sign in front of a rounded value of 0.0
    const displayDeltaValue = (value: number, numDecimals: number = 1) => {
      const ZERO = 0
      if (value.toFixed(numDecimals) === `-${ZERO.toFixed(numDecimals)}`){
        return ZERO.toFixed(numDecimals)
      }else{
        return value.toFixed(numDecimals)
      }
    }

    return (
      <Grid container spacing={0} columns={12} sx={{
        display: "flex",
        alignItems: "center",
        margin: "10px"
      }}>
        <Grid item direction={"column"} xs={5} sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid rgb(0,123,87)",
          margin: "auto",
          p: "4px"
        }}>
        <Box
          component="img"
          sx={{
            backgroundColor: "transparent",
            height: 20,
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            width: "100%"
          }}
          alt="BCG Logo"
          src="https://storage.googleapis.com/cropai-mappable2/assets/BCG%20X%20color/01-bcgx-logo-color-positive-RGB-small.svg"
        />
          {/* <Typography variant='body1'>{"BCG"}</Typography> */}
          <Typography fontSize={"24px"} variant='h6'>{
            cropData[`national_${forecast}_bcg`] ?
            (cropData[`national_${forecast}_bcg`]/divisor).toFixed(1) : "---"
          }</Typography>
          <Typography marginTop={"-8px"} marginBottom={"4px"} fontSize={"10px"} variant='caption'>{unit}</Typography>
          <Typography variant='body2' display="flex" align="center" alignItems={"center"} sx={{color: "#8D8A8A"}}>
            {getChangeArrow('bcg')}
            {(cropData[`delta_national_${forecast}_bcg`] || cropData[`delta_national_${forecast}_bcg`] === 0) ?
            displayDeltaValue(cropData[`delta_national_${forecast}_bcg`]) + '%' : "---"}
          </Typography>
          <Typography align="center" sx={{ fontSize: "9px", fontFamily: "Inter", color: "#8D8A8A" }}>since last month</Typography>
        </Grid>
      <Grid item direction={"column"} xs={5} sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid rgb(138,196,129)",
          margin: "auto",
          p: "4px"
        }}>
          {/* <Typography variant='body1'>{"USDA"}</Typography> */}
          <Box
            component="img"
            sx={{
              backgroundColor: "transparent",
              height: 20,
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              width: "100%"
            }}
            alt="USDA Logo"
            src="https://storage.googleapis.com/cropai-mappable2/assets/usda-logo-color.svg"
          />
          <Typography fontSize={"24px"} variant='h6'>{
            cropData[`national_${forecast}_usda`] ?
            (cropData[`national_${forecast}_usda`]/divisor).toFixed(1) : "---"
          }</Typography>
          <Typography marginTop={"-8px"} marginBottom={"4px"} fontSize={"10px"} variant='caption'>{unit}</Typography>
          <Typography variant='body2' display="flex" align="center" alignItems={"center"} sx={{color: "#8D8A8A"}}>
            {getChangeArrow('usda')}
            {(cropData[`delta_national_${forecast}_usda`] || cropData[`delta_national_${forecast}_usda`] === 0) ?
            displayDeltaValue(cropData[`delta_national_${forecast}_usda`])+'%' : "---"}
          </Typography>
          <Typography align="center" sx={{ fontSize: "9px", fontFamily: "Inter", color: "#8D8A8A" }}>since last month</Typography>
      </Grid>
    </Grid>
    )
  }

  const renderStateForecast = (cropData: any, forecast: forecastType | string) => {

    if (forecast === forecastType.ACRE){
      forecast = 'acres_harvested'
    }

    // Set units
    let unit: string =''
    if (forecast=== "production"){
      unit = "billion bushels"
    } else if (forecast ==="yield"){
      unit = "bushels per acre"
    } else if (forecast === "acres_harvested"){
      unit = "million acres"
    }

    // Set divisor, 1 billion for production, 1 million for acreage, 1 for yield
    let divisor = 1
    switch (forecast){
     case forecastType.PRODUCTION:
      divisor = 1e9
      if (cropData[`${forecast}_bcg`] && Math.abs(cropData[`${forecast}_bcg`]) < 1e9){
        divisor = 1e6
        unit = "million bushels"
      }
      break;
    case forecastType.ACRE:
    case "acres_harvested":
      divisor = 1e6
      break;
    default:
      divisor = 1
      break
    }

    // Set signs
    let signBCG: any;
    if (cropData[`delta_${forecast}_bcg`] && cropData[`delta_${forecast}_bcg`].toFixed(1) > 0){
      signBCG = <NorthIcon style={{color: "#28B428", fontSize: "medium" }} />
    } else if (cropData[`delta_${forecast}_bcg`] && cropData[`delta_${forecast}_bcg`].toFixed(1) < 0){
      signBCG = <SouthIcon style={{color: "#B42828", fontSize: "medium" }} />
    }

    let signUSDA: any;
    if (cropData[`delta_${forecast}_usda`] && cropData[`delta_${forecast}_usda`].toFixed(1) > 0){
      signUSDA = <NorthIcon style={{color: "#28B428", fontSize: "medium" }}/>
    } else if (cropData[`delta_${forecast}_usda`] && cropData[`delta_${forecast}_usda`].toFixed(1) < 0){
      signUSDA = <SouthIcon style={{color: "#B42828", fontSize: "medium" }} />
    }

    // Avoid displaying a negative sign in front of a rounded value of 0.0
    const displayDeltaValue = (value: number, numDecimals: number = 1) => {
      const ZERO = 0
      if (value.toFixed(numDecimals) === `-${ZERO.toFixed(numDecimals)}`){
        return ZERO.toFixed(numDecimals)
      }else{
        return value.toFixed(numDecimals)
      }
    }


    return (
      <Grid container spacing={0} columns={12} sx={{
        display: "flex",
        alignItems: "center",
        margin: "10px"
      }}>
        <Grid item direction={"column"} xs={5} sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid rgb(0,123,87)",
          margin: "auto",
          p: "4px"
        }}>
        <Box
          component="img"
          sx={{
            backgroundColor: "transparent",
            height: 20,
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            width: "100%"
          }}
          alt="BCG Logo"
          src="https://storage.googleapis.com/cropai-mappable2/assets/BCG%20X%20color/01-bcgx-logo-color-positive-RGB-small.svg"
        />
          {/* <Typography variant='body1'>{"BCG"}</Typography> */}
          <Typography fontSize={"24px"} variant='h6'>{
            cropData[`${forecast}_bcg`] ?
            (cropData[`${forecast}_bcg`]/divisor).toFixed(1) : "---"
          }</Typography>
          <Typography marginTop={"-8px"} marginBottom={"4px"} fontSize={"10px"} variant='caption'>{unit}</Typography>
          <Typography variant='body2' display="flex" align="center" alignItems={"center"} sx={{color: "#8D8A8A"}}>
            {signBCG}
            {(cropData[`delta_${forecast}_bcg`] || cropData[`delta_${forecast}_bcg`] === 0) ?
            displayDeltaValue(cropData[`delta_${forecast}_bcg`]) + '%' : "---"}
          </Typography>
          <Typography align="center" sx={{ fontSize: "9px", fontFamily: "Inter", color: "#8D8A8A" }}>since last month</Typography>
        </Grid>
      <Grid item direction={"column"} xs={5} sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "3px solid rgb(138,196,129)",
          margin: "auto",
          p: "4px"
        }}>
          {/* <Typography variant='body1'>{"USDA"}</Typography> */}
          <Box
            component="img"
            sx={{
              backgroundColor: "transparent",
              height: 20,
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              width: "100%"
            }}
            alt="USDA Logo"
            src="https://storage.googleapis.com/cropai-mappable2/assets/usda-logo-color.svg"
          />
          <Typography fontSize={"24px"} variant='h6'>{
            cropData[`${forecast}_usda`] ?
            (cropData[`${forecast}_usda`]/divisor).toFixed(1) : "---"
          }</Typography>
          <Typography marginTop={"-8px"} marginBottom={"4px"} fontSize={"10px"} variant='caption'>{unit}</Typography>
          <Typography variant='body2' display="flex" align="center" alignItems={"center"} sx={{color: "#8D8A8A"}}>
            {signUSDA}
            {(cropData[`delta_${forecast}_usda`] || cropData[`delta_${forecast}_usda`] === 0) ?
          displayDeltaValue(cropData[`delta_${forecast}_usda`]) + '%' : "---"}
          </Typography>
          <Typography align="center" sx={{ fontSize: "9px", fontFamily: "Inter", color: "#8D8A8A" }}>since last month</Typography>
      </Grid>
    </Grid>
    )
  }


  const renderMetricsByRow = () => {

    let cropData = []
    // National Level
    if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
      // Set yield data for active crop
      if ((CURRENT_CROP === cropType.CORN) && cache.metrics.nationalData.corn){
        cropData = cache.metrics.nationalData.corn
      }else if ((CURRENT_CROP === cropType.SOYBEANS) && cache.metrics.nationalData.soybeans){
        cropData = cache.metrics.nationalData.soybeans
      }
    }else{
      // State Level
      const stateStruct = cache.states.data.features.find(e => e.properties.state_name.toUpperCase() === CURRENT_GEOGRAPHY.toUpperCase())
      if (stateStruct){
        if ((CURRENT_CROP === cropType.CORN)){
          cropData = stateStruct.properties.report.corn
        }else if ((CURRENT_CROP === cropType.SOYBEANS) && cache.metrics.nationalData.soybeans){
          cropData = stateStruct.properties.report.soybeans
        }
      }else{
        console.log(`Could not set cropData for crop:${CURRENT_CROP} at level:${CURRENT_GEOGRAPHY}`)
      }
    }

    return (
      <Box className="National-Or-State-Metrics">
        <Grid container rowSpacing={0} columns={12} sx={{
          display: "flex",
          alignItems: "baseline",
          marginTop: 2
        }}>
          <Grid item direction={"row"} xs={12} sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            columnGap: "20px",
          }}>
            {renderMetricHeader(cropData, forecastType.PRODUCTION)}
            {renderMetricHeader(cropData, forecastType.YIELD)}
            {renderMetricHeader(cropData, forecastType.ACRE)}
          </Grid>
          <Grid item direction={"row"} xs={12} sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}>
            {CURRENT_GEOGRAPHY.toUpperCase()==="COUNTY" ?
              renderNationalForecast(cropData, forecastType.PRODUCTION) : renderStateForecast(cropData, forecastType.PRODUCTION)
            }
            {CURRENT_GEOGRAPHY.toUpperCase()==="COUNTY" ?
              renderNationalForecast(cropData, forecastType.YIELD) : renderStateForecast(cropData, forecastType.YIELD)
            }
            {CURRENT_GEOGRAPHY.toUpperCase()==="COUNTY" ?
              renderNationalForecast(cropData, forecastType.ACRE) : renderStateForecast(cropData, forecastType.ACRE)
            }
          </Grid>
      </Grid>
    </Box>
    )
  }

  const navigate = useNavigate()

  const renderLeftGridByRow = () => {

    const launchFullMap = () => {
      navigate("/map")
    }

    return (
      <Box className="LeftGrid">
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} direction={"row"} >
            {renderCropGeoFilters()}
            {renderMetricsByRow()}
          </Grid>
          <Grid item xs={12} direction={"row"}>
            <Box className="DashboardMap" sx={{height: "52vh", width: "100%", position:"relative", paddingRight: "20px", paddingLeft: "20px"}}>
              <RenderMap
                state={mapParams.state}
                cache={mapParams.cache}
                mapView={mapParams.mapView}
                setMapView={mapParams.setMapView}
                mapStyle={mapParams.mapStyle}
                handleFilterUpdate={mapParams.handleFilterUpdate}
                fullscreen={mapParams.fullscreen}
                />
                  <Box className="Launch-Fullscreen-Box" sx={{
                    color: "gray",
                    // borderRadius: "4px",
                    position: "absolute",
                    zIndex: "800",
                    top: "4px",
                    right: "8px",
                  }}>
                    <Tooltip title="Launch Fullscreen Map" placement="bottom">
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2, padding:"4px" }}
                        id="basic-button"
                        onClick={launchFullMap}
                      >
                        <Launch />
                      </IconButton>
                    </Tooltip>
                  </Box>
                <Box className="Address-Search-Dashboard" sx={{
                    // marginLeft: '4px',
                    // backgroundColor: "#35363a",
                    color: "white",
                    borderRadius: "4px",
                    // paddingLeft: "4px",
                    // paddingRight: "4px",
                    position: "absolute",
                    zIndex: "2500",
                    top: "8px",
                    left: "32px",
                    maxHeight: "calc(480px - 16px)",
                    // width:"100%",
                    // minWidth:"160px",
                    maxWidth:"180px",
                }}>
                    { true &&
                    <AddressSearch
                      cache={cache}
                      handleMapViewChange={handleMapViewChange}
                     />
                    }
                </Box>
                <Box className="Map-Yield-Select" sx={{
                      // marginLeft: '4px',
                      // backgroundColor: "#35363a",
                      color: "white",
                      borderRadius: "4px",
                      // paddingLeft: "4px",
                      // paddingRight: "4px",
                      position: "absolute",
                      zIndex: "800",
                      bottom: "6px",
                      left: "26px",
                      width:"200px",
                  }}>
                    {modelFilter &&
                      <MapOverlayMenu
                        manualToggleLayer={manualToggleLayer}
                        key={modelFilter.field}
                        panel={panel}
                        layerName={layer.type}
                        subFilter={modelFilter}
                        state={state}
                        defaultSelection={"Actual"}
                        override=
                        {{
                          width: "100%",
                          label: "Map Overlay"
                          // backgroundColor: "#EDEDED",
                        }}
                      />
                    }
                  </Box>
                  <Box className="Map-Legend" sx={{
                      // marginLeft: '4px',
                      // backgroundColor: "#35363a",
                      color: "white",
                      borderRadius: "4px",
                      // paddingLeft: "4px",
                      // paddingRight: "4px",
                      position: "absolute",
                      zIndex: "800",
                      bottom: "0px",
                      right: "26px",
                      // width:"100%",
                      maxWidth:"160px",
                  }}>
                      {true && getLegendYieldForecastImage(CURRENT_CROP, CURRENT_YIELD)}
                  </Box>
              </Box>
          </Grid>
        </Grid>
        <Typography fontSize={"10px"} sx={{paddingTop:"20px", whiteSpace: "pre-line",}}>{textBlurb2}</Typography>

    </Box>
    )
  }

  // /get_state_yield_line/{crop}/{statefp}

  const renderNationalLineChart = (chartHeight: number) => {
    return(
    <Box className="Dashboard-Nat-Yield">
    <Typography fontSize={"16px"} variant={"subtitle1"}>{`National Yield Forecast (${capitalizeFirst(CURRENT_CROP)})`}</Typography>
      {/* <GenerateGraphNationalLineChart
        host={BACKEND_HOST}
        path={`/get_national_yield_forecast_data/${CURRENT_CROP.toLowerCase()}/?height=${chartHeight}`}
        level={"NATIONAL"}
        type={graphTypeYieldForecast.DASHBOARD}
      /> */}
      <NationalYieldChart
        cache={cache}
        crop={CURRENT_CROP.toLowerCase()}
      />
    </Box>
    )
  }

  const renderStateLineChart = (chartHeight: number) => {
    const STATE_FIPS = STATE_FIPS_MAPPING.find(e => e.name === CURRENT_GEOGRAPHY.toUpperCase())
    if (STATE_FIPS){
      return(
        <Box className="Dashboard-State-Yield">
        <Typography fontSize={"16px"} variant={"subtitle1"}>{`${capitalizeFirst(CURRENT_GEOGRAPHY)} Yield Forecast (${capitalizeFirst(CURRENT_CROP)})`}</Typography>
          {/* <GenerateGraphStateYieldLine
            host={BACKEND_HOST}
            path={`/get_state_yield_line_data/${CURRENT_CROP.toLowerCase()}/${STATE_FIPS.FIPS}?height=${chartHeight}`}
            level={"NATIONAL"}
            type={graphTypeYieldForecast.DASHBOARD}
          /> */}
          <StateYieldChart
          cache={cache}
          crop={CURRENT_CROP.toLowerCase()}
          stateName={CURRENT_GEOGRAPHY}
          />
        </Box>
        )
    }else{
      console.log(`ERROR: Could not look up STATE FIPS code for State: ${CURRENT_GEOGRAPHY.toUpperCase()}`)
      return (
        <></>
      )
    }
  }

  const renderTextSummary = () => {
    let forecast;
    let textSummary = "BCG Yield Forecast Dashboard"
    let showHeader = false
    if (cache.settings.values.dashboard?.forecastSummary?.addHeader?.valueOf()){
      showHeader = true
    }

    if (CURRENT_CROP === cropType.CORN){
      if (cache.settings.values.dashboard?.forecastSummary?.corn){
        forecast = cache.settings.values.dashboard.forecastSummary.corn
      }else{
        console.log("ERROR: Invalid settings.json! Missing dashboard.forecastSummary.corn property.")
      }
    }else if (CURRENT_CROP === cropType.SOYBEANS){
      if (cache.settings.values.dashboard?.forecastSummary?.soybeans){
        forecast = cache.settings.values.dashboard.forecastSummary.soybeans
      }else{
        console.log("ERROR: Invalid settings.json! Missing dashboard.forecastSummary.soybeans property.")
      }
    }

    // If forecast was set
    if (forecast && forecast.lines?.length >= 0){
      textSummary = ""
      for (let line of forecast.lines){
        textSummary += `${line}\n `
      }
    }else{
      console.log(`ERROR: Invalid settings.json! Missing dashboard.forecastSummary.${CURRENT_CROP.toLowerCase()}.lines property! `)
    }

    return (
      <>
      {showHeader && (forecast && forecast.headerText?.length > 0) &&
      <Typography variant='h6' fontWeight={"Bold"} sx={{paddingTop:"20px"}}>{forecast.headerText}</Typography>
      }
      <Typography fontSize={"12px"} sx={{paddingTop:"20px", whiteSpace: "pre-line",}}>{textSummary}</Typography>
      </>
    )
  }

  // Right half of dashboard components
  const renderNationalYieldForecast = (chartHeight: number) => {
    return (
        <Grid container columns={24} sx={{height: chartHeight + 45, overflow: "hidden", marginTop:"32px"}}>
          <Grid item xs={13} direction={"column"} >
            {CURRENT_GEOGRAPHY === "COUNTY" ? renderNationalLineChart(chartHeight) : renderStateLineChart(chartHeight)}
            {/* {renderHealthIndexChart(400)} */}
          </Grid>
          <Grid item xs={11} direction={"column"} alignSelf={"center"}>
            <Box
              component="img"
              sx={{
                backgroundColor: "transparent",
                height: 100,
                display:"flex",
                // marginTop:"-12px",
                // marginRight: "-24px",
                position: "absolute",
                top: "0px",
                right: "0px",
              }}
              alt="CEI Logo"
              src="https://storage.googleapis.com/cropai-mappable2/assets/CEI_LOGO_COLOR.svg"
            />
            {/* <Typography variant='h6' fontWeight={"Bold"} sx={{paddingTop:"20px"}}>{"About this forecast:"}</Typography> */}
            {/* <Typography fontSize={"12px"} sx={{paddingTop:"20px", whiteSpace: "pre-line",}}>{textBlurb}</Typography> */}
            {renderTextSummary()}
            </Grid>
      </Grid>
    )
  }

  enum healthIndexType {
    EVI = "EVI",
    PRECIPITATION = "PRECIPITATION",
    TEMPERATURE = "TEMPERATURE",
  }

  const [index, setIndex] = useState<healthIndexType>(healthIndexType.EVI)
  interface IManageHealthIndex {
    index: healthIndexType,
    setIndex: React.Dispatch<React.SetStateAction<healthIndexType>>,
  }
  const health: IManageHealthIndex = {
    index: index,
    setIndex: setIndex
  }

  const renderHealthIndexSelector = () => {

    const INDEX_OPTIONS = [
      { label: "EVI", value: healthIndexType.EVI },
      { label: "Temperature", value: healthIndexType.TEMPERATURE },
      { label: "Precipitation", value: healthIndexType.PRECIPITATION },
    ]

    const handleChange = (str: string) => {
      switch (str) {
        case healthIndexType.PRECIPITATION:
          health.setIndex(healthIndexType.PRECIPITATION)
          break;
        case healthIndexType.TEMPERATURE:
          health.setIndex(healthIndexType.TEMPERATURE)
        break;
        case healthIndexType.EVI:
          health.setIndex(healthIndexType.EVI)
        break;
        default:
          console.log("WARNING: Invalid target value for healthIndexSelector handleChange. Reverting to EVI index")
          health.setIndex(healthIndexType.EVI)
        break;
      }
    }

    const getSectionTitle = (index: healthIndexType) => {
      switch (index) {
        case healthIndexType.PRECIPITATION:
          // return("PRECIPITATION - Accumulation To Date (Inches)")
          if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
            return(`National Accumulation To Date (Inches)`)
          }else{
            return(`${capitalizeFirst(CURRENT_GEOGRAPHY)} Accumulation To Date (Inches)`)
          }

        case healthIndexType.TEMPERATURE:
          if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
            return(`National Daytime Land Surface (Celsius)`)
          }else{
            return(`${capitalizeFirst(CURRENT_GEOGRAPHY)} Daytime Land Surface (Celsius)`)
          }
        case healthIndexType.EVI:
          if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
            return(`National Enhanced Vegetation Index  (${capitalizeFirst(CURRENT_CROP)})`)
          }else{
            return(`${capitalizeFirst(CURRENT_GEOGRAPHY)} Enhanced Vegetation Index (${capitalizeFirst(CURRENT_CROP)})`)
          }
        default:
          console.log("WARNING: Invalid target value for getSectionTitle handleChange. Reverting to EVI index")
          return("Crop Health Indices")
      }
    }

    return (
      <Box marginBottom={"2px"}>
        <Typography fontSize={"16px"} variant={"subtitle1"}>{getSectionTitle(health.index)}</Typography>
        {/* <Typography fontSize={"14px"} color={"#667080"}>{"Crop Health Index"}</Typography> */}
        <FormControl size="small" sx={{
          position: "relative",
          // width: "60%",
          minWidth: "140px",
          maxWidth: "200px",
          zIndex: "1200",
          top: "0px",
          left: "0px",
        }}>
          {/* <InputLabel id="map-style-picker-label">Map Style</InputLabel> */}
          <Select
            labelId="dashboard-health-index"
            id="map-style-picker"
            value={health.index}
            // label="Map Style"
            variant="outlined"
            onChange={(e) => handleChange(e.target.value)}
            sx={{
              backgroundColor: "#EDEDED",
              height: "30px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            {INDEX_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        </FormControl>
      </Box>
    )
  }

  const renderHealthIndexChart = (chartHeight: number) => {

    // National level
    if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
      return (
        <NationalChiChart
        cache={cache}
        crop={CURRENT_CROP.toLowerCase()}
        healthIndex={health.index}
        />
      )
      // if (health.index === "EVI"){
      //   return (
      //     <GenerateGraphEVI
      //     host={BACKEND_HOST}
      //     path={`/get_national_${health.index.toLowerCase()}_plot_data/${CURRENT_CROP.toLowerCase()}?height=${chartHeight}`}
      //     level={"NATIONAL"}
      //     type={graphTypeCropHealthIndex.DASHBOARD}
      //     />
      //   )
      // } else if (health.index === "TEMPERATURE") {
      //   return (
      //     <GenerateGraphTemperature
      //     host={BACKEND_HOST}
      //     path={`/get_national_${health.index.toLowerCase()}_plot_data/?height=${chartHeight}`}
      //     level={"NATIONAL"}
      //     type={graphTypeCropHealthIndex.DASHBOARD}
      //     />
      //   )
      // } else if (health.index === "PRECIPITATION") {
      //   return (
      //     <GenerateGraphPrecipitation
      //     host={BACKEND_HOST}
      //     path={`/get_national_${health.index.toLowerCase()}_plot_data/?height=${chartHeight}`}
      //     level={"NATIONAL"}
      //     type={graphTypeCropHealthIndex.DASHBOARD}
      //     />
      //   )
      // }
    } else {
      // State level

      // Get state fips code
      const STATE_FIPS = STATE_FIPS_MAPPING.find(e => e.name === CURRENT_GEOGRAPHY.toUpperCase())
      if (STATE_FIPS) {
        return (
          <StateChiChart
          cache={cache}
          crop={CURRENT_CROP.toLowerCase()}
          stateName={CURRENT_GEOGRAPHY}
          healthIndex={health.index}
          />
        )
        // if (health.index.toUpperCase() === "EVI") {
        //   return (
        //     <GenerateGraphEVI
        //     host={BACKEND_HOST}
        //     path={`/get_state_${health.index.toLowerCase()}_plot_data/${health.index === healthIndexType.EVI ? `${CURRENT_CROP.toLowerCase()}/` : ""}${STATE_FIPS.FIPS}?height=${chartHeight}`}
        //     level={"NATIONAL"}
        //     type={graphTypeCropHealthIndex.DASHBOARD}
        //     />
        //   )
        // } else if (health.index.toUpperCase() === "TEMPERATURE") {
        //   return (
        //     <GenerateGraphTemperature
        //     host={BACKEND_HOST}
        //     path={`/get_state_${health.index.toLowerCase()}_plot_data/${STATE_FIPS.FIPS}?height=${chartHeight}`}
        //     level={"NATIONAL"}
        //     type={graphTypeCropHealthIndex.DASHBOARD}
        //     />
        //   )
        // } else if (health.index.toUpperCase() === "PRECIPITATION"){
        //   return (
        //     <GenerateGraphPrecipitation
        //     host={BACKEND_HOST}
        //     path={`/get_state_${health.index.toLowerCase()}_plot_data/${STATE_FIPS.FIPS}?height=${chartHeight}`}
        //     level={"NATIONAL"}
        //     type={graphTypeCropHealthIndex.DASHBOARD}
        //     />
        //   )
        // }
      } else {
        console.log(`ERROR: Could not look up STATE FIPS code for State: ${CURRENT_GEOGRAPHY.toUpperCase()}`)
        return (
          <NationalChiChart
          cache={cache}
          crop={CURRENT_CROP.toLowerCase()}
          healthIndex={health.index}
          />
        )
        // REVIEW: Currently set back to national to avoid having no chart here.
        // if (health.index === "EVI"){
        //   return (
        //     <GenerateGraphEVI
        //     host={BACKEND_HOST}
        //     path={`/get_national_${health.index.toLowerCase()}_plot_data/${CURRENT_CROP.toLowerCase()}?height=${chartHeight}`}
        //     level={"NATIONAL"}
        //     type={graphTypeCropHealthIndex.DASHBOARD}
        //     />
        //   )
        // } else if (health.index === "TEMPERATURE") {
        //   return (
        //     <GenerateGraphTemperature
        //     host={BACKEND_HOST}
        //     path={`/get_national_${health.index.toLowerCase()}_plot_data/?height=${chartHeight}`}
        //     level={"NATIONAL"}
        //     type={graphTypeCropHealthIndex.DASHBOARD}
        //     />
        //   )
        // } else if (health.index === "PRECIPITATION") {
        //   return (
        //     <GenerateGraphPrecipitation
        //     host={BACKEND_HOST}
        //     path={`/get_national_${health.index.toLowerCase()}_plot_data/?height=${chartHeight}`}
        //     level={"NATIONAL"}
        //     type={graphTypeCropHealthIndex.DASHBOARD}
        //     />
        //   )
        }
      }
    }

  const renderYieldBarChart = () => {
    // National level
    if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
      return (
      // PREVIOUS NON CACHED
      //   <GenerateGraphNational
      //   host={BACKEND_HOST}
      //   path={`/get_state_yield_forecast_data/${CURRENT_CROP.toLowerCase()}`}
      //   level={"STATE"}
      //   type={graphType.DASHBOARD}
      // />
      // GENERATE FROM CACHE
      <Box className="Bottom-Right-Bar-Chart-Box" sx={{maxWidth: '25vw'}}>
        <NationalBarChart
          cache={cache}
          crop={CURRENT_CROP.toLowerCase()}
        />
      </Box>
      )
    }
    else{
      // Get top ten counties by state

      // Get state fips code
      const STATE_FIPS = STATE_FIPS_MAPPING.find(e => e.name === CURRENT_GEOGRAPHY.toUpperCase())
      if (STATE_FIPS){
        return (
          // /test_get_top_counties_{crop}_{state_fips}
        //   <GenerateChart
        //   host={BACKEND_HOST}
        //   path={`/test_get_top_counties_${CURRENT_CROP.toLowerCase()}_${STATE_FIPS.FIPS}`}
        //   level={"STATE"}
        //   type={chartType.DASHBOARD}
        // />
        // NON CACHED
        //   <GenerateGraphCounty
        //   host={BACKEND_HOST}
        //   path={`/get_top_counties_by_state_data/${CURRENT_CROP.toLowerCase()}/${STATE_FIPS.FIPS}`}
        //   level={"STATE"}
        //   type={graphType.DASHBOARD}
        // />
        // CACHED
        <CountyBarChart
          cache={cache}
          crop={CURRENT_CROP.toLowerCase()}
          stateName={CURRENT_GEOGRAPHY}
        />
        )
      }else{
        console.log(`ERROR: Could not look up STATE FIPS code for State: ${CURRENT_GEOGRAPHY.toUpperCase()}`)
        // REVIEW: Currently set back to national to avoid having no chart here.
        return (
          <></>
        )
      }
    }
  }

  const bottomRightChartTitle = () => {
    if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
      return `Yield Forecast by State (${capitalizeFirst(CURRENT_CROP)})`
    }else{
      return (
        `Top Counties in ${capitalizeFirst(CURRENT_GEOGRAPHY)}`)
    }
  }
  const renderBarChartTitle = () => {
    let title = ""
    if (CURRENT_GEOGRAPHY.toUpperCase() === "COUNTY"){
      title = `Yield Forecast by State (${capitalizeFirst(CURRENT_CROP)})`
      return (
        <Typography fontSize={"16px"} variant={"subtitle1"}>{title}</Typography>
      )
    }else{
      title = `Top Counties in ${capitalizeFirst(CURRENT_GEOGRAPHY)}`
      return (
        <Box sx={{display: "flex", flexDirection:"row", alignItems: "center"}}>
          <Typography fontSize={"16px"} variant={"subtitle1"}>{title}</Typography>
          <Tooltip placement="top" title="Sorted by highest production">
            <Info sx={{color:"gray", fontSize: "16px"}} />
          </Tooltip>
        </Box>
      )
    }
  }

  const testRenderBottomCharts = (chartHeight: number) => {
    return (
      <Grid container columns={24} sx={{marginTop: "12px"}}>
        <Grid item xs={13} direction={"column"} >
          {/* {CURRENT_GEOGRAPHY === "COUNTY" ? renderNationalLineChart(chartHeight) : renderStateLineChart(chartHeight)} */}
          <Box className='Health-Index-Selector' sx={{marginBottom:"16px"}}>
            {renderHealthIndexSelector()}
          </Box>
          {renderHealthIndexChart(chartHeight)}
        </Grid>
        {/* <Grid item xs={1}>
          <Divider orientation="vertical" variant="middle" flexItem sx={{width: "4px", marginTop:"-12px", display: "flex", alignSelf: "flex-start", height: "100%", borderRightWidth: "2px"}} style={{height: '50vh'}}/>
        </Grid> */}
        <Grid item xs={11} sx={{paddingLeft:"16px"}} direction={"column"}>
          <Box className="STATEYIELD" sx={{height: "100%"}}>
            {renderBarChartTitle()}
              {/* <Typography fontSize={"16px"} variant={"subtitle1"}>{bottomRightChartTitle()}</Typography> */}
              {/* {
                (CURRENT_GEOGRAPHY.toUpperCase() !== "COUNTY") &&
                  <Typography fontSize={"10px"} sx={{justifySelf: "center"}} variant={"subtitle2"}>{`Sorted by highest Production`}</Typography>
              } */}
            {renderYieldBarChart()}
          </Box>
        </Grid>
      </Grid>
    )
  }

  const renderRightGridByRow = () => {
    return (
      <Box className="RightGrid" sx={{height:"100%"}}>
        <Grid container spacing={0} columns={12} sx={{height:"100%", alignContent: "baseline"}}>
        {/* <Grid item xs={12} direction={"column"} sx={{display: "flex", alignItems:"flex-end", paddingTop:"0px"}}>
          <Box
            component="img"
            sx={{
              backgroundColor: "transparent",
              height: 130,
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
            alt="CEI Logo"
            src="https://storage.googleapis.com/cropai-mappable2/assets/CEI_LOGO_COLOR.svg"
          />
          </Grid> */}
          <Grid item xs={12} direction={"row"}>
            {renderNationalYieldForecast(300)}
          </Grid>
          <Divider variant="middle" flexItem sx={{height: 2, display: "flex"}} style={{width:'100%', borderBottomWidth: "2px"}} />
          <Grid item xs={12} direction={"row"}>
            {testRenderBottomCharts(360)}
          </Grid>

          {/* <Divider variant="middle" flexItem sx={{height: 2, display: "flex"}} style={{width:'100%', borderBottomWidth: "2px"}} /> */}
          {/* <Grid className="Crop-Health-Chart" item xs={6} direction={"column"}
            sx={{
              display: "flex",
              alignSelf: "flex-start",
            }}>
            <Box className='Health-Index-Selector' sx={{marginBottom:"16px"}}>
              {renderHealthIndexSelector()}
            </Box>
            <Box className="NationalEvi" sx={{height: "100%", display:"flex", alignSelf:"baseline"}}>
              {renderHealthIndexChart(400)}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Divider orientation="vertical" variant="fullWidth" flexItem sx={{width: 4, display: "flex", alignSelf: "flex-start", height: "100%", borderRightWidth: "2px"}} style={{height: '50vh'}}/>
          </Grid>
          <Grid item xs={5} direction={"column"}>
            <Box className="STATEYIELD" sx={{height: "100%"}}>
            <Typography fontSize={"16px"} variant={"subtitle1"}>{`State Yield Forecast (${capitalizeFirst(CURRENT_CROP)})`}</Typography>
            {renderYieldBarChart()}
            </Box>
          </Grid> */}
        </Grid>
    </Box>
    )
  }

  const capitalizeFirst = (str: string) => {
    if (str.length >= 2){
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }else{
      return str.toUpperCase()
    }
  }

  return(
    <Box padding="12px 36px" className="DashboardBox">
      <Grid container spacing={2} columns={24} marginTop={"1vh"}>
        <Grid item xs={12}>
          {renderLeftGridByRow()}
        </Grid>
        <Grid item xs={12}>
          {renderRightGridByRow()}
        </Grid>
      </Grid>
  </Box>
  );
}
