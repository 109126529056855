
const LOCAL = "http://localhost:5000"
const DEPLOYED = "https://backend-api-vaq6vemkla-uc.a.run.app"

// Backend for developing locally. Set to DEPLOYED if not testing a LOCAL backend
const DEV_HOST = DEPLOYED
// Backend host set to DEV_HOST if running UI locally in development, otherwise backend is DEPLOYED version
export const BACKEND_HOST = process.env.NODE_ENV === 'development' ? DEV_HOST : DEPLOYED

const ENABLE_DEBUG_WHEN_DEPLOYED = false
const ENABLE_DEBUG_WHEN_LOCAL = true
export const VERBOSE_DEBUG = (process.env.NODE_ENV === 'development') ? ENABLE_DEBUG_WHEN_LOCAL : ENABLE_DEBUG_WHEN_DEPLOYED
