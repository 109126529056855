import { QueryStats } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IManageState } from "../CropAI/CropAI";
import { layerType } from "../../store/StoryApi";
import { resetMapLayers } from "../LayerReset/MapReset";

interface ILaunchDashboardParams {
  state: IManageState,
  excludeSet?: layerType[],
}

export const LaunchYieldDashboardButton = (params: ILaunchDashboardParams) =>{

  const {state, excludeSet=[]} = (params)

  const navigate = useNavigate()

  const launchFullMap = () => {
    navigate("/dashboard")
    resetMapLayers(state, excludeSet)
  }

  return (
  <Box className="Launch-Dashboard-Box" sx={{
    // display: "flex",
    color: "gray",
    // borderRadius: "4px",
    position: "absolute",
    // zIndex: "800",
    // top: "4px",
    right: "16px",
    // marginRight: "8px",
    // marginTop: "-4px",
  }}>
    <Tooltip title="Launch Yield Dashboard" placement="bottom">
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{
          padding:"3px",
          // marginRight: "8px",
          // marginTop: "-4px",
          color: "black",
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "#00A887",
        }}
        id="query-stats-button"
        onClick={launchFullMap}
      >
        <QueryStats fontSize="small" />
      </IconButton>
    </Tooltip>
  </Box>
  );
}
