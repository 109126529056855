import React from 'react';
import './App.css';

import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";

  import { LandingPage } from './components/LandingPage/LandingPage';

// import ControlPanel from  './ControlPanel'
// import CesiumMap from "./components/UNUSED/Map"
// import DMap from "./DMap"

// @ts-ignore
// import {ColorBar, scaleRange} from "./components/UNUSED/Color";
// import DetailBar from "./DetailBar";
import { CropAI, viewType } from './components/CropAI/CropAI';

import {
    styled,
    Box,
    CssBaseline
  } from '@mui/material'
import Cargill from "./components/Cargill/Cargill";


const BASE_PATH = "" //"/pages/cropai"

type State = {
    mode: string;
    feature: string;
    year: number;
    crop: string;
    yieldFeature: string;
    FIPS: string | null;
    showElevators: boolean
    month: number
    fly: boolean
    darkTheme: boolean
};
class App extends React.Component<{}, State> {
    state: State = {
        mode: "COUNTY",
        feature: "Yield",
        year: 2022,
        crop: "CORN",
        yieldFeature: "FORECAST",
        FIPS: null,
        showElevators: false,
        month: 4,
        fly: true,
        darkTheme: true
    };

    setMode = (mode: string) => {
        console.log("setting mode");
        this.setState({ mode });
        if (mode === "CLIMATE"){
            this.setState({feature: "precip_change"})
        } else if (mode === "COUNTY"){
            this.setState({feature: "Yield"})
        }
        this.setState({fly: false})
        setTimeout(()=>this.setState({fly: true}), 1000)
        // this.setState({fly: true})

      };

    setFeature = (feature: string) => {
        console.log("setting feature");
        this.setState({ feature });
      };

    setYear = (year: number) => {
        console.log("setting year");
        this.setState({ year });
      };

    setCrop = (crop: string) => {
        console.log("setting crop");
        this.setState({ crop });
      };

    setYieldFeature = (yieldFeature: string) => {
        console.log("setting crop");
        this.setState({ yieldFeature });
        if (yieldFeature === "ACTUAL"){
             this.setState({ year: 2021 });
        }
      };

    handleToggleShowElevators = () => {
        console.log("toggling show elevators");
        this.setState((prevState) => ({
           showElevators: !prevState.showElevators
        }))
    }

    handleToggleDarkTheme = () => {
        console.log("toggling dark theme")
        this.setState((prevState) => ({
           darkTheme: !prevState.darkTheme
        }))
    }

    setMonth = (month: number) => {
        console.log("setting month");
        this.setState({month})
    }

    setFips = (FIPS: string) => {
        console.log("setting FIPS")
        this.setState({ FIPS });
    }

    render() {
        // const url = "https://storage.googleapis.com/testkomal/maps/illinois_cloudless_ndvi_202005/{z}/{x}/{y}"
        const MainContent = styled(Box)(() => ({
            height: '100%',
            width: '100%',
            }));

        // NOTE: For Github deployement, use /pages/cropai/cropai/#/ as the base path and swap BrowserRouter with <HashRouter>
        // const BASE_PATH = "/pages/cropai/cropai/#/"
        const BASE_PATH = "/"

        return (
            <>
                <CssBaseline />
                    <MainContent>
                        <BrowserRouter>
                            {/* <AppHeader></AppHeader> */}
                            <div className='App-Routes' style={{height: 'calc(100% - 64px)'}}>
                                <Routes>
                                    <Route path={``} element={<CropAI view={viewType.DASHBOARD_VIEW}/>}/>
                                    <Route path={"/*"} element={<CropAI view={viewType.DASHBOARD_VIEW}/>}/>
                                    <Route path={`/home`} element={<LandingPage/>}/>
                                    <Route path={`/home/index.html`} element={<LandingPage/>}/>
                                    <Route path={`/map`} element={<CropAI view={viewType.DEFAULT_MAP_VIEW}/>}/>
                                    <Route path={`/map/index.html`} element={<CropAI view={viewType.DEFAULT_MAP_VIEW}/>}/>
                                    <Route path={`/dashboard`} element={<CropAI view={viewType.DASHBOARD_VIEW}/>}/>
                                    <Route path={`/dashboard/index.html`} element={<CropAI view={viewType.DASHBOARD_VIEW}/>}/>
                                    <Route path={`/elevator`} element={<Cargill/>}/>
                                    <Route path={`/elevator/index.html`} element={<Cargill/>}/>
                                    {/* <Route path={`${BASE_PATH}`} element={<CropAI view={viewType.DASHBOARD_VIEW}/>}/>
                                    <Route path={`${BASE_PATH}home`} element={<LandingPage/>}/>
                                    <Route path={`${BASE_PATH}map`} element={<CropAI view={viewType.DEFAULT_MAP_VIEW}/>}/>
                                    <Route path={`${BASE_PATH}dashboard`} element={<CropAI view={viewType.DASHBOARD_VIEW}/>}/>
                                    <Route path={`${BASE_PATH}*`} element={<CropAI view={viewType.DASHBOARD_VIEW}/>}/> */}
                                </Routes>
                            </div>
                        </BrowserRouter>
                    </MainContent>
            </>
        );
    }
}
export default App;
