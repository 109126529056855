import { FormGroup, FormControlLabel, Divider, Box } from '@mui/material';
import { IStoryPanel, layerType } from '../../store/StoryApi';
import { LegendAccordion } from '../LegendAccordion/LegendAccordion';
import { ControlCheckbox } from '../ControlPanel/ControlPanel';
import { useState } from 'react';
import { IManageState } from '../CropAI/CropAI';
import { FilterPotashTradeflows } from './FilterPotashTradeflows';
import { FilterPhosphateTradeflows } from './FilterPhosphateTradeflows';
import { FilterNitrogenTradeflows } from './FilterNitrogenTradeflows';
import { LayerReset } from '../LayerReset/LayerReset';

interface IPanelInputParams {
  state: IManageState,
  panel: IStoryPanel
  panelFilterArray: JSX.Element[]
}

export const PanelTradeflows = (params: IPanelInputParams) =>{
  const {state, panel, panelFilterArray} = params
  const [legendOpen, setLegendOpen] = useState(false);

  const toggleLegendOpen = () => {
    setLegendOpen(!legendOpen)
  }

  return (
  <FormGroup sx={{paddingLeft: "10px"}}>
    <Box sx={{paddingBottom: "5px"}}>
      <LayerReset
        state={params.state}
        panel={params.panel}
      />
    </Box>
    {panelFilterArray.find(e => e.key === layerType.POTASH_TRADEFLOWS) || <></>}
    <FormControlLabel control={<ControlCheckbox disabled />} label="Phosphate Tradeflows 2022" />
    <FormControlLabel control={<ControlCheckbox disabled />} label="Nitrogen Tradeflows 2022" />
    {/* <FilterPotashTradeflows
      state={state}
      panel={panel}
      layerCheckbox= {panelFilterArray.find(e => e.key === layerType.POTASH_TRADEFLOWS) || <></>}
    />
    <FilterPhosphateTradeflows
      state={state}
      panel={panel}
      layerCheckbox= {panelFilterArray.find(e => e.key === layerType.PHOSPHATE_TRADEFLOWS) || <></>}
    />
    <FilterNitrogenTradeflows
      state={state}
      panel={panel}
      layerCheckbox= {panelFilterArray.find(e => e.key === layerType.NITROGEN_TRADEFLOWS) || <></>}
    /> */}
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel sx={{paddingTop: "10px"}} control={<ControlCheckbox onClick={toggleLegendOpen} />} label="LEGENDS" />
    <LegendAccordion
      panelLayers={panel.layers}
      legendOpen={legendOpen}
      legendMaxHeight={"35vh"}
    />
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel sx={{paddingTop: "10px"}} control={<ControlCheckbox disabled />} label="CHARTS" />
  </FormGroup>
  );
}
