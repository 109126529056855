/// app.js
import React, {useEffect, useState} from "react";
import DeckGL from '@deck.gl/react/typed';
import {GeoJsonLayer} from "@deck.gl/layers/typed";
import {MVTLayer} from '@deck.gl/geo-layers/typed';
import StaticMap from 'react-map-gl';  // "^7.0.21"
import 'maplibre-gl/dist/maplibre-gl.css';
import * as tf from '@tensorflow/tfjs';


import {interpolateRdYlGn, interpolateRdYlBu} from "d3-scale-chromatic";
import * as d3 from "d3";
// import {BCGX_THEME_COLORS} from "./Color"
import {Dayjs} from "dayjs";
import { PickingInfo } from "@deck.gl/core/typed";
import { TooltipContent } from "@deck.gl/core/typed/lib/tooltip";


export const BCGX_THEME_COLORS = [
    [0, 224, 181],
    [110, 118, 250],
    [20, 230, 230],
    [43, 11, 158]
]


const scheme = interpolateRdYlBu
// const colorScale = d3.scaleSequential(scheme).domain([630, 680]);
const colorScaleWL = d3.scaleSequential(scheme).domain([-10, 10]);
const colorScaleFOB = d3.scaleSequential(scheme).domain([630, 680]);



function getColor(val: number, scale: any) {
    let rgb = scale(val)
    return rgb.slice(
        rgb.indexOf("(") + 1,
        rgb.indexOf(")")
        // @ts-ignore
    ).split(", ").map(k => parseInt(k, 10))

}

const MAPBOX_TOKEN = "pk.eyJ1IjoibGlhbWNvbm5lbGwiLCJhIjoiY2xkbmMzeHN4MGY5YzN2a2R2aWVqaWM1aSJ9.iGVgL3TNOVe3b5MMdsErEQ";
const api_url = "http://localhost:5000"

// Viewport settings
const INITIAL_VIEW_STATE = {
    latitude: 42,
    longitude: -91.8,
    zoom: 9,
    minZoom: 0,
    maxZoom: 15,
    bearing: 0,
    useDevicePixels: false,
};

interface Props {
    elevatorDate: Dayjs
    elevatorMode: string
}

const Map = (props: Props) => {
    const [distanceData, setDistanceData] = useState<object>({});
    const [fieldPricesMap, setFieldPricesMap] = useState<object>({});
    const [elevatorPrices, setElevatorPrices] = useState<object>({});
    const [fieldsLayer, setFieldsLayer] = useState<MVTLayer | null>(null);

    useEffect(() => {
        if (Object.keys(distanceData).length == 0) {
            console.time("Distance load timer")
            console.log("Loading Distances")
            fetch(`${api_url}/elevator_demo/distances`)
                .then(response => response.json())
                .then(data => setDistanceData(data))
                .then(() => console.log("Distances Loaded"))
                .then(() => console.timeEnd("Distance load timer"))
        }
    }, [])

    useEffect(() => {
        if (Object.keys(distanceData).length > 0) {
            console.log("Starting DeckGL map render")
            const elevatorPrices = fetch(`${api_url}/elevator_demo/elevator_prices/${props.elevatorDate.format('YYYY-MM-DD')}`)
                .then(response => response.json())
            elevatorPrices.then((elevatorPrices) => setElevatorPrices(elevatorPrices))
            elevatorPrices
                .then(elevatorPrices => getFieldPricesMap(elevatorPrices, distanceData, props.elevatorMode))
                .then(fieldPricesMap => setFieldPricesMap(fieldPricesMap))
                .then(() => console.log("Field Prices set"))
        }
    }, [props.elevatorDate, props.elevatorMode, distanceData])

    useEffect(() => {
        if (Object.keys(fieldPricesMap).length !== 0){
            console.log("rendering mvt layer")
            const fieldsLayer_ = new MVTLayer({
                id: `id-${props.elevatorDate}-${Date.now()}`,
                // data: `${api_url}/mvt/{z}/{x}/{-y}`,
                data: "https://storage.googleapis.com/cropai-mappable/cedar_rapids_fields/{z}/{x}/{-y}.mvt",
                minZoom: 0,
                maxZoom: 10,
                pickable: true,
                getFillColor: (d: any) => {
                    // @ts-ignore
                    return getColor(fieldPricesMap[d.properties.FieldID], scale)
                },
                onClick: (info, event) => console.log('Clicked:', info.object.properties),
                autoHighlight: true
            })
            setFieldsLayer(fieldsLayer_)
        }
    }, [fieldPricesMap])

    console.log("Rendering")
    let scale: d3.ScaleSequential<string, never>
    if (props.elevatorMode === "FOB") {
        scale = colorScaleFOB
    } else {
        scale = colorScaleWL
    }
    // @ts-ignore
    const elevatorLayer = new GeoJsonLayer({
        id: 'geojson-layer2',
        data: `${api_url}/elevator_demo/elevators`,
        pickable: true,
        stroked: false,
        pointType: 'circle',
        // radiusScale: 1000,
        getRadius: 8,
        pointRadiusUnits: 'pixels',
        getFillColor: (d: any) => {
            // @ts-ignore
            return elevator_owners_colors[d.properties.category] || [165, 165, 169] // grey default
        },
        getPosition: (d: { position: any; }) => d.position,
        onClick: (info: { object: { properties: any; }; }, event: any) => console.log('Clicked:', info.object.properties),
        extruded: false,
        elevationScale: 10000,
        getElevation: 10000,
        autoHighlight: true
    });

    return <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={[
            fieldsLayer,
            elevatorLayer,
        ]}
        getTooltip={(info) => getTooltip(info, fieldPricesMap, elevatorPrices)}
        style={{overflow: "hidden"}}
    >
        <StaticMap
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/mapbox/light-v9"
      />
        {/*<NavigationControl/>*/}
    </DeckGL>;

}


function getTooltip(info: PickingInfo, fieldPricesMap: object, elevatorPrices: object): TooltipContent {
    const obj = info.object
    if (obj !== undefined) {
        // @ts-ignore
        const ent_properties = obj.properties
        let table: string = "<tr>" +
           "<th id='q'>Key</th>" +
           "<th id='o'>Val</th>" +
           "</tr>"
        const ignoreKeys = [
            "objectid", "elevatorid", "FieldID",
            "shape_length", "shape_area", "Shape_Length", "Shape_Area", "geography",
            "layerName", "OBJECTID", "lat", "lon", "long"
        ]
        for (const [key, value] of Object.entries(ent_properties)) {
            if (!ignoreKeys.includes(key)) {
                table += '<tr><td>' + key + '</td><td>' + value + '</td></tr>'
            }
        }
        table = `<table>${table}</table>`
        let header
        if (ent_properties.FieldID !== undefined) {
            header = `<h2>Farm: ${ent_properties.Farm_Owner}</h2>`
            // @ts-ignore
            header += `<h4>Price: $${(fieldPricesMap[ent_properties.FieldID]/100).toFixed(2)}</h4>`
        } else {
            header = `<h2>Elevator: ${ent_properties.name}</h2>`
            // @ts-ignore
            header += `<h4>Price: $${(elevatorPrices[ent_properties.elevatorid] / 100).toFixed(2)}</h4>`
        }
        return {html: `${header}${table}`, style: {overflow: "clip"}}
    } else {
        return null
    }
}


function getFieldPricesMap(elevatorPrices: object, distanceData: object, elevatorMode: string): Promise<object> {
    let elevatorPricesArray: number[] = [];
    let targetElevatorPrice: number;
    let elevatorIdx: string[] = [];
    const targetElevatorId = "650"
    Object.keys(elevatorPrices).map((key) => {
        if (key !== targetElevatorId || elevatorMode === "FOB") {
            // @ts-ignore
            elevatorPricesArray.push(elevatorPrices[key])
            elevatorIdx.push(key)
        } else{
            // @ts-ignore
            targetElevatorPrice = elevatorPrices[key]
        }
    });
    console.log("extracted prices")
    console.log(elevatorPricesArray)

    console.log("Extracting distances")
    let distanceMatrix: number[][] = []
    let fieldsIdx: string[] = []
    let targetDistanceArray: number[] = []
    Object.keys(distanceData).map((key) => {
        fieldsIdx.push(key)
        let elevators_of_field_ar = []
        for (let elevator_id of elevatorIdx) {
            // @ts-ignore
            elevators_of_field_ar.push(distanceData[key][elevator_id])
        }
        distanceMatrix.push(elevators_of_field_ar)
        // @ts-ignore
        targetDistanceArray.push(distanceData[key][targetElevatorId])
    });
    console.log("extracted distances")
    console.log(distanceMatrix)

    const bestPrices = calculateBestPrices(elevatorPricesArray, distanceMatrix)

    return bestPrices.then((bestPrices) => {
        console.log(bestPrices)
        let calcPrices = {}
        // @ts-ignore
        for (let [idx, field_id] of fieldsIdx.entries()) {
            // @ts-ignore
            const fobPrice = bestPrices[idx]
            if (elevatorMode === "WINLOSS"){
                // @ts-ignore
                calcPrices[field_id] = (-1) * (fobPrice - ((-.5*targetDistanceArray[idx]) + targetElevatorPrice))
            } else {
                // @ts-ignore
            calcPrices[field_id] = fobPrice
            }
        }
        console.log(calcPrices)
        return calcPrices
    })
}

function calculateBestPrices(elevator_prices: number[], farm_elevator_travel_distances: number[][]) {
    console.log(`Calculating best prices on ${tf.getBackend()}`)
    var startTime = performance.now()
    let elevator_prices_t = tf.tensor(elevator_prices).toFloat()
    let farm_elevator_travel_distances_t = tf.tensor(farm_elevator_travel_distances)
    let farm_prices = tf.tidy(() => {
        farm_elevator_travel_distances_t = tf.where(farm_elevator_travel_distances_t.isNaN(), [9999], farm_elevator_travel_distances_t)
        return farm_elevator_travel_distances_t.mul([-0.5]).add(elevator_prices_t).max(1)
    })
    var endTime = performance.now()
    console.log(`Call to calculateBestPrices took ${endTime - startTime} milliseconds`)
    elevator_prices_t.dispose()
    farm_elevator_travel_distances_t.dispose()
    const res = farm_prices.array()
    farm_prices.min().array().then(a => console.log(a))
    farm_prices.max().array().then(a => console.log(a))
    farm_prices.dispose()
    return res
}


export const elevator_owners_colors = {
    "CARGILL": BCGX_THEME_COLORS[0],
    "Cooperative": BCGX_THEME_COLORS[1],
    "Heartland": BCGX_THEME_COLORS[2],
    "Innovative AG Services": BCGX_THEME_COLORS[3]
// Landus
// KEY COOPERATIVE
// MID IOWA
// Viafield
//
}

export default Map;
