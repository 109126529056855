import * as S from "./styles"
import { StoryButton } from "../StoryButton/StoryButton";
import { useEffect, useState } from "react";
import { DialogPanel } from '../DialogPanel/DialogPanel';
import { STORIES, StoryType, layerType } from '../../store/StoryApi';
import { IGeoLayer, IStoryData } from '../../store/StoryApi';
import { IMapView } from '../../store/MapApi';
import { Box } from '../DialogPanel/styles';
import { YieldForecastChartPanel } from "../YieldForecast/YieldForecastChartPanel";
import { IManageState } from '../CropAI/CropAI';
import { produce } from "immer";

interface IStoryGridParams {
  state: IManageState,
  storyData: IStoryData,
  mapView: IMapView,
  handleLayerChange: (panelName: StoryType, newLayers: IGeoLayer[]) => void,
  handleMapViewChange: (newMapView: IMapView) => void,
}

export const StoryGrid = (params: IStoryGridParams) => {

  const {storyData, mapView, handleLayerChange, state} = params

  const isYieldForecastActive = (storyData: IStoryData) => {
    const panel = storyData.panels.find(e => e.name === StoryType.FARM_DEMAND)
    if (panel){
      const yfLayer = panel.layers.find(e => e.type === layerType.YIELD_FORECAST)
      return (yfLayer?.active && panel.chartsActive) || false
    }else{
      return false
    }
  }

  const [open, setOpen] = useState(false);
  const [story, setStory] = useState(StoryType._NONE);
  const [showButtons, setShowButtons] = useState(true)

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  useEffect(() => {

    state.setStoryData(produce<IStoryData>(draft => {
      const panelIndex = draft.panels.findIndex(e => e.name === story)

      if (panelIndex > -1){
        draft.panels[panelIndex].storyOpen = open
        console.log(`Set storyOpen for ${story} to ${open}`)
      }else if (story === StoryType._NONE){
        console.log("Reset all panels to storyOpen=False")
        draft.panels.forEach(panel => panel.storyOpen = false)
      }
    }))

  // NOTE: that adding "state" below causes multiple re-renders of state.
  }, [open, story]);

  const handlePanelClose = () => {
    setOpen(false);
    setShowButtons(true);
  };


  return (
    <>
    <Box className="Control Panel Box" sx={{
      height: "90vh",
      position: "fixed",
      top: "0px",
    }}>
      <DialogPanel
        state={params.state}
        storyType={story}
        storyLabel={STORIES.find(e => e.type===story)?.label || "_NONE"}
        panelOpen={open}
        handlePanelClose={handlePanelClose}
        storyData={storyData}
        mapView={mapView}
        handleLayerChange={handleLayerChange}
        handleMapViewChange={params.handleMapViewChange}
      />
    { (open) && isYieldForecastActive(storyData) &&
      <Box>
        <YieldForecastChartPanel
        storyData={state.storyData}
        />
      </Box>
      // <S.SliderBox>
      // <YearSlider/>
      // </S.SliderBox>
    }
    </Box>
    <S.GridOutline gridAutoColumns={"1fr"} display="inline-grid" className="Rotate TEST" alignItems={"stretch"} container>
      {STORIES.map((stories) => (
        <S.Grid key={stories.label} display={"grid"} alignContent={"center"} item>
          <StoryButton
            showButtons={showButtons}
            setShowButtons={setShowButtons}
            panelState={open}
            setPanelState={setOpen}
            currentStory={story}
            setStory={setStory}
            label={stories.label}
            storyId={stories.type}
            handleMapViewChange={params.handleMapViewChange}
          />
        </S.Grid>
        ))}
    </S.GridOutline>
    </>
  );
}
