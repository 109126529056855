import * as d3 from "d3";
import { MVTLayer } from "@deck.gl/geo-layers/typed"
import { hexToRgb } from "@mui/system";
import { convertRGBtoArray } from "../../utils/color";

export const generateShippingRoutesLine = () => {
  const shipping_routes_lines_layer = new MVTLayer({
    id: 'routes-lines-layer',
    data: "https://storage.googleapis.com/cropai-mappable/2022_global_shipping_routelines/{z}/{x}/{-y}.mvt",
    maxZoom: 6,
    lineWidthMinPixels: 1,
    getLineColor: (d: any) => {
      return convertRGBtoArray(hexToRgb(getColorByCountry(d.properties.Origin__Co)))
    },
    getLineWidth: (d: any) => {
      // console.log("D11 tonnage: " + d.properties.Tonnage_11)
      const width = scaleTonnageLineWidth(d.properties.Tonnage_11)
      // console.log("\twidth: " + width)
      return width
      // return d.properties.Tonnage_11 / 50000
    },
    lineWidthUnits: 'pixels',
    opacity: .1,
      pickable: true
  })
  return shipping_routes_lines_layer
}

const scaleTonnageLineWidth = d3.scaleQuantile([1, 2, 3, 4, 5, 6, 7, 8]).domain([0, 500000])

const getColorByCountry = (country: string) => {
  switch (country) {
    case "Canada":
      return("#BEE8FF")
    case "Germany":
      return("#F4B3FD")
    case "Israel":
      return("#FEBDB3")
    case "Jordan":
      return("#CAE1FE")
    case "Lithuania":
      return("#B4B8FD")
    case "Russia":
      return("#E9FFBE")
    case "Spain":
      return("#FEE3C1")
    case "USA":
      return("#D3FFBE")
    default:
      return("#E1E1E1")
  }
}
