import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Grid, Stack } from '@mui/material';
import { IGeoLayer, fieldType, layerType } from '../../store/StoryApi';
import { IMapView } from '../../store/MapApi';
import { Scrollbars } from 'react-custom-scrollbars'

interface ILegendAccordionParams {
  legendOpen: boolean,
  panelLayers: IGeoLayer[],
  legendMaxHeight?: string,
  mapView?: IMapView,
}

interface ILegendPairAttributes {
  hexColor?: string,
  borderRadius?: string,
  boxHeight?: string,
  boxWidth?: string,
  stroke?: string,
  strokeWidth?: string,
}

const colorStackPair = (textValue: string, attributes?: ILegendPairAttributes) => {
  return(
  <Stack
      direction="row"
      alignItems="center"
      spacing={1}
    >
    <Box sx={{
      borderRadius: attributes?.borderRadius || "0px",
      backgroundColor: attributes?.hexColor || "",
      height: attributes?.boxHeight || "16px",
      width: attributes?.boxWidth || "16px",
      borderStyle: "solid",
      borderWidth: attributes?.strokeWidth || "0px",
      borderColor: attributes?.stroke,
    }} />
    <Typography color={"#667080"}>{textValue}</Typography>
    </Stack>
  )
}

const legendGridPair = (textValue: string, attributes?: ILegendPairAttributes) => {
  return(
    <>
    <Grid container spacing={0} sx={{
      display: "flex",
      alignItems: "center",
    }}>
      <Grid item xs={2} sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Box sx={{
          borderRadius: attributes?.borderRadius || "0px",
          backgroundColor: attributes?.hexColor || "",
          height: attributes?.boxHeight || "16px",
          width: attributes?.boxWidth || "16px",
          borderStyle: "solid",
          borderWidth: attributes?.strokeWidth || "0px",
          borderColor: attributes?.stroke,
        }} />
      </Grid>
      <Grid item xs={10}>
        <Typography fontSize={"14px"} color={"#667080"}>{textValue}</Typography>
      </Grid>
    </Grid>
    </>
  )
}

const legendCroplandImage = (
  <Box
  // style={{ cursor: 'move' }} id="draggable-dialog-title"
  component="img"
  sx={{
    backgroundColor: "transparent",
    height: 16,
    width: 96,
  }}
  alt="Seaborn Color Palette"
  src="https://storage.googleapis.com/cropai-mappable2/assets/seaborn%20color%20palette%20rocket%20crop30.png"
  // src="https://bcg.widen.net/content/iin91jlach/jpeg/02-bcgx-logo-black-RGB-medium.jpeg?w=2048&h=1152&position=c&quality=80&use=clzzmzwm&x.portal_shortcode_generated=pfusj1sw&x.collection_sharename=9tmumvft&x.app=portals"
/>
)

const renderColorScale = (source: string) => [
  <Box
    // style={{ cursor: 'move' }} id="draggable-dialog-title"
    component="img"
    sx={{
      backgroundColor: "transparent",
      height: 16,
      width: "100%",
    }}
    alt="Color Scale"
    src={source}
/>
]

export const getLegendYieldForecastImage = (crop: string, model: string, dashboard: boolean = false) => {

  let imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/yellow-to-green-scale.png"
  let minBound = "0"
  let maxBound = "200"

  switch (model.toUpperCase()) {
    case "ACTUAL":
      if (crop.toUpperCase() === "SOYBEANS"){
        maxBound = "70"
      }
      break;
    case "DELTA":
      imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/RdYlBu.png"
      minBound = "-0.25"
      maxBound = "0.25"
      break;
    case "FD_TEMPERATURE":
      imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/BuYlRd.png"
      minBound = "10"
      maxBound = "30"
      break;
    case "FD_PRECIPITATION":
      imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/RdBu.png"
      minBound = "-2.0"
      maxBound = "2.0"
      break;
    case "FD_DROUGHT":
      imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/Spectral.png"
      minBound = "-5"
      maxBound = "5"
      break;

    default:
      break;
  }

  return (
    <Box>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {/* {colorStackPair("0.001-30", {boxWidth: "25px"})}
        {colorStackPair("30.001-40", {hexColor: "#FFA800"})}
        {colorStackPair("40.001-70",  {hexColor: "#FA7B25"})}
        {colorStackPair("70.001-100",  {hexColor: "#FC001C"})} */}
        <Box sx={{
          height: 20,
          width: "100%",
        }}>
          <Grid container spacing={0}>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>{minBound}</Typography>
            </Grid>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>{maxBound}</Typography>
            </Grid>
          </Grid>
        </Box>
        {renderColorScale(imageSource)}
      </Grid>
    </Grid>
  </Box>
  )
}

const renderColorScaleLegend = (title: string, min: string, max: string, src:string) => {
  return (
    <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>{title}</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {/* {colorStackPair("0.001-30", {boxWidth: "25px"})}
        {colorStackPair("30.001-40", {hexColor: "#FFA800"})}
        {colorStackPair("40.001-70",  {hexColor: "#FA7B25"})}
        {colorStackPair("70.001-100",  {hexColor: "#FC001C"})} */}
        <Box sx={{
          height: 20,
          width: "70%",
        }}>
          <Grid container spacing={0}>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>{min}</Typography>
            </Grid>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>{max}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{width: "70%"}}>
          {renderColorScale(src)}
        </Box>
      </Grid>
    </Grid>
  </Box>
  )
}

const legendApplicationImage = (
  <Box
  // style={{ cursor: 'move' }} id="draggable-dialog-title"
  component="img"
  sx={{
    backgroundColor: "transparent",
    height: 16,
    width: 96,
  }}
  alt="Crest Color Palette"
  src="https://storage.googleapis.com/cropai-mappable2/assets/seaborn-color-palette-crest.png"
/>
)

const legendPhosphorousApplication = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Phosphorous Application</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        <Box sx={{
          height: 20,
          width: 96,
        }}>
          <Grid container spacing={0}>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>0</Typography>
            </Grid>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>30</Typography>
            </Grid>
          </Grid>
        </Box>
        {legendApplicationImage}
      </Grid>
    </Grid>
  </Box>
)

const legendNitrogenApplication = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Nitrogen Application</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        <Box sx={{
          height: 20,
          width: 96,
        }}>
          <Grid container spacing={0}>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>0</Typography>
            </Grid>
            <Grid item xs={6} sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}>
              <Typography fontSize={"14px"} color={"#667080"}>100</Typography>
            </Grid>
          </Grid>
        </Box>
        {legendApplicationImage}
      </Grid>
    </Grid>
  </Box>
)

const getLegendPopulation = (mapView?: IMapView) => {
  let popLevel = "???"
  let popBounds = ["?", "?", "?", "?", "?", "?"]
  if (mapView){
    if (mapView.zoom >= 4.5){
      popLevel = "3km"
      popBounds = [
        "0 - 40",
        "41 - 400",
        "401 - 1,600",
        "1,601 - 4,000",
        "4,001 - 10,000",
        "10,000+"
      ]
    }else{
      popLevel = "22km"
      popBounds = [
        "0 - 2,000",
        "2,001 - 20,000",
        "20,001 - 80,000",
        "80,001 - 200,000",
        "200,001 - 460,000",
        "460,001+"
      ]
    }
  }

  return(
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Population {popLevel}</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {legendGridPair(popBounds[0],  {hexColor: "#3D004C"})}
        {legendGridPair(popBounds[1], {hexColor: "#3A3D7A"})}
        {legendGridPair(popBounds[2], {hexColor: "#256C80"})}
        {legendGridPair(popBounds[3], {hexColor: "#1E9877"})}
        {legendGridPair(popBounds[4], {hexColor: "#6FBE48"})}
        {legendGridPair(popBounds[5], {hexColor: "#E6D222"})}
      </Grid>
    </Grid>
  </Box>
  )
}

const legendPotashMines = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Potash Mines</Typography>
    <Typography variant='caption' fontSize={"14px"} color={"#667080"} sx={{paddingLeft: "12px"}}>2022 Production Volume (kt)</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {legendGridPair("3,360.01-10,416",  {hexColor: "#4928FF", boxHeight: "24px", boxWidth: "24px", borderRadius:"12px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("1,932.01-3,360",  {hexColor: "#4928FF", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("840.01-1,932",  {hexColor: "#4928FF", boxHeight: "13px", boxWidth: "13px", borderRadius:"6.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("0.1-840",  {hexColor: "#4928FF", boxHeight: "9px", boxWidth: "9px", borderRadius:"4.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("0",  {hexColor: "#4928FF", boxHeight: "5px", boxWidth: "5px", borderRadius:"2.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
      </Grid>
    </Grid>
  </Box>
)

export const renderYieldForecastLegend = (size: number = 12) => {
  return (
    <Box className="Legend-Yield-Forecast" sx={{display:"flex", alignItems:"center"}}>
    <Box sx={{
      borderRadius: "0px",
      backgroundColor: "#007B57",
      height: `${size}px`,
      width: `${size}px`,
      borderStyle: "solid",
      borderWidth: "0px",
      marginRight: "4px",
      }} />
    <Typography fontSize={`${size}px`} color={"#667080"}>{"BCG"}</Typography>
    <Box sx={{
      borderRadius: "0px",
      backgroundColor: "#8AC481",
      height: `${size}px`,
      width: `${size}px`,
      borderStyle: "solid",
      borderWidth: "0px",
      marginLeft: "8px",
      marginRight: "4px",
     }} />
    <Typography fontSize={`${size}px`} color={"#667080"}>{"USDA"}</Typography>
  </Box>
  )
}

export const renderYieldForecastLegendv2 = (size: number = 12) => {
  return (
    <Box className="Legend-Yield-Forecast" sx={{display:"flex", alignItems:"center"}}>
    <Box sx={{
      borderRadius: "0px",
      backgroundColor: "#007B57",
      height: `${size}px`,
      width: `${size}px`,
      borderStyle: "solid",
      borderWidth: "0px",
      marginRight: "4px",
      }} />
    <Typography fontSize={`${size}px`} color={"#667080"}>{"BCG"}</Typography>
    <Box sx={{
      borderRadius: "0px",
      backgroundColor: "#8AC481",
      height: `${size}px`,
      width: `${size}px`,
      borderStyle: "solid",
      borderWidth: "0px",
      marginLeft: "8px",
      marginRight: "4px",
     }} />
    <Typography fontSize={`${size}px`} color={"#667080"}>{"USDA (Yet to be published)"}</Typography>
  </Box>
  )
}

const legendPotashDeposits = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Potash Deposits</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {legendGridPair("Potash Deposits",  {hexColor: "#4928FF", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px", stroke:"#FFFDFD", strokeWidth: "1px"})}
      </Grid>
    </Grid>
  </Box>
)

const legendPhosphateMines = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Phosphate Mines</Typography>
    <Typography variant='caption' fontSize={"14px"} color={"#667080"} sx={{paddingLeft: "12px"}}>2022 Production Volume (kt)</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {legendGridPair("26,800,000,000.01-40,000,000,000",  {hexColor: "#598E9D", boxHeight: "26px", boxWidth: "26px", borderRadius:"13px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("8,203,500,000.01-26,800,000,000",  {hexColor: "#598E9D", boxHeight: "20px", boxWidth: "20px", borderRadius:"10px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("6,152,625,000,01-8,203,500,000",  {hexColor: "#598E9D", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("4,101,750,000.01-6,152,625,000",  {hexColor: "#598E9D", boxHeight: "12px", boxWidth: "12px", borderRadius:"6px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("2,050,875,000.01-4,101,750,000",  {hexColor: "#598E9D", boxHeight: "9px", boxWidth: "9px", borderRadius:"4.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("0.1-2,050,875,000",  {hexColor: "#598E9D", boxHeight: "7px", boxWidth: "7px", borderRadius:"3.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("0",  {hexColor: "#598E9D", boxHeight: "5px", boxWidth: "5px", borderRadius:"2.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
      </Grid>
    </Grid>
  </Box>
)

const legendPhosphateDeposits = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Phosphate Deposits</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {legendGridPair("Phosphate Deposits",  {hexColor: "#598E9D", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px", stroke:"#FFFDFD", strokeWidth: "1px"})}
      </Grid>
    </Grid>
  </Box>
)


const legendNitrogenFacilities = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Nitrogen Facilities</Typography>
    <Typography variant='caption' fontSize={"14px"} color={"#667080"} sx={{paddingLeft: "12px"}}>2022 Production Volume</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {legendGridPair("1,600.01-3,500",  {hexColor: "#90CB73", boxHeight: "26px", boxWidth: "26px", borderRadius:"13px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("900.01-1,600",  {hexColor: "#90CB73", boxHeight: "20px", boxWidth: "20px", borderRadius:"10px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("400.01-900",  {hexColor: "#90CB73", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("100.01-400",  {hexColor: "#90CB73", boxHeight: "12px", boxWidth: "12px", borderRadius:"6px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("0.01-100",  {hexColor: "#90CB73", boxHeight: "9px", boxWidth: "9px", borderRadius:"4.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
        {legendGridPair("0",  {hexColor: "#90CB73", boxHeight: "5px", boxWidth: "5px", borderRadius:"2.5px", stroke:"#FFFDFD", strokeWidth: "1px"})}
      </Grid>
    </Grid>
  </Box>
)

const legendGlobalRail = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Global Rail</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
        {legendGridPair("Global Rail Lines",  {hexColor: "#00A887", boxHeight: "1px", boxWidth: "16px", stroke:"#00A887", strokeWidth: "2.5px"})}
      </Grid>
    </Grid>
  </Box>
)

const legendPorts = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Ports</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
      {legendGridPair("Ports",  {hexColor: "#F6D647", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px", stroke:"#FFFFFF", strokeWidth: "2px"})}
      </Grid>
    </Grid>
  </Box>
)

const legendPotashShippingRoutes = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Potash Shipping Routes 2022</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
      {legendGridPair("AIS Messages",  {hexColor: "#B4B7B6", boxHeight: "12px", boxWidth: "12px", borderRadius:"8px"})}
      </Grid>
    </Grid>
  </Box>
)

const legendPotashTradeflows = (
  <Box >
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Potash Trade Flows</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
      {legendGridPair("Canada",  {hexColor: "#BEE8FF", boxHeight: "1px", boxWidth: "16px", stroke:"#BEE8FF", strokeWidth: "2.5px"})}
      {legendGridPair("Germany",  {hexColor: "#F4B3FD", boxHeight: "1px", boxWidth: "16px", stroke:"#F4B3FD", strokeWidth: "2.5px"})}
      {legendGridPair("Israel",  {hexColor: "#FEBDB3", boxHeight: "1px", boxWidth: "16px", stroke:"#FEBDB3", strokeWidth: "2.5px"})}
      {legendGridPair("Jordan",  {hexColor: "#CAE1FE", boxHeight: "1px", boxWidth: "16px", stroke:"#CAE1FE", strokeWidth: "2.5px"})}
      {legendGridPair("Lithuania",  {hexColor: "#B4B8FD", boxHeight: "1px", boxWidth: "16px", stroke:"#B4B8FD", strokeWidth: "2.5px"})}
      {legendGridPair("Russia",  {hexColor: "#E9FFBE", boxHeight: "1px", boxWidth: "16px", stroke:"#E9FFBE", strokeWidth: "2.5px"})}
      {legendGridPair("Spain",  {hexColor: "#FEE3C1", boxHeight: "1px", boxWidth: "16px", stroke:"#FEE3C1", strokeWidth: "2.5px"})}
      {legendGridPair("USA",  {hexColor: "#D3FFBE", boxHeight: "1px", boxWidth: "16px", stroke:"#D3FFBE", strokeWidth: "2.5px"})}
      {legendGridPair("Other",  {hexColor: "#E1E1E1", boxHeight: "1px", boxWidth: "16px", stroke:"#E1E1E1", strokeWidth: "2.5px"})}
    </Grid>
    </Grid>
  </Box>
)

const legendAgRetailers = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Ag Retailers</Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
      {legendGridPair("Nutrien Ag Solutions",  {hexColor: "#02786E", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("GROWMARK",  {hexColor: "#08887D", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("Helena Agri-Enterprises",  {hexColor: "#00A887", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("CHS Agronomy",  {hexColor: "#0BBA98", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("Simplot",  {hexColor: "#00E0B5", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("MFA Inc.",  {hexColor: "#0CEFC3", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("Wilbur-Ellis",  {hexColor: "#00FFCC", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("Titan Pro",  {hexColor: "#3FFFD9", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("GreenPoint Ag Holdings",  {hexColor: "#A6FFED", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      {legendGridPair("Southern States Cooperative",  {hexColor: "#C1FFF3", boxHeight: "16px", boxWidth: "16px", borderRadius:"8px"})}
      </Grid>
    </Grid>
  </Box>
)

const legendAgFieldsSegmentation = (
  <Box>
    <Typography variant='subtitle2' fontSize={"14px"} color={"#000000"}>Agriculture Fields Segmentation </Typography>
    <Grid container spacing={0}>
    <Grid item xs={12}>
      {legendGridPair("Corn",  {hexColor: "#EEC659", boxHeight: "16px", boxWidth: "16px"})}
      {legendGridPair("Soy",  {hexColor: "#ADC178", boxHeight: "16px", boxWidth: "16px"})}
      {legendGridPair("Grassland / Pasture",  {hexColor: "#A98467", boxHeight: "16px", boxWidth: "16px"})}
      {legendGridPair("Alfalfa",  {hexColor: "#77874D", boxHeight: "16px", boxWidth: "16px"})}
      {legendGridPair("Fallow / Idle Cropland",  {hexColor: "#D6AD8D", boxHeight: "16px", boxWidth: "16px"})}
      {legendGridPair("Other",  {hexColor: "#E1E1E1", boxHeight: "16px", boxWidth: "16px", stroke:"#E1E1E1", strokeWidth: "2.5px"})}
      </Grid>
    </Grid>
  </Box>
)


export const LegendAccordion = (params: ILegendAccordionParams) =>{
  const {panelLayers, legendOpen} = params

  interface ILayerLegend {
    type: layerType,
    active: boolean,
    legend: JSX.Element,
  }

  const renderYieldColorLegend = () => {
    const subFilters = panelLayers.find(e => e.type === layerType.YIELD_FORECAST)?.subFilters
    const crop = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_CROP)?.activeValues[0]?.toLowerCase();
    const model = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_MODEL)?.activeValues[0]?.toLowerCase();

    let imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/yellow-to-green-scale.png"
    let minBound = "0"
    let maxBound = "200"
    let title = "Corn Forecast"

    if (model && crop){
      switch (model.toUpperCase()) {
        case "ACTUAL":
          if (crop.toUpperCase() === "SOYBEANS"){
            title = "Soybean Forecast"
            maxBound = "70"
          }
          break;
        case "DELTA":
          title = "Delta (5 Years)"
          imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/RdYlBu.png"
          minBound = "-0.25"
          maxBound = "0.25"
          break;
        default:
          return <></>
        }
        return renderColorScaleLegend(title, minBound, maxBound, imageSource)
    }
    return <></>
  }

  const renderConditionsColorLegend = (type: layerType) => {

    let imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/yellow-to-green-scale.png"
    let minBound = "0"
    let maxBound = "100"
    let title = "Conditions"

    switch (type) {
      case layerType.FD_TEMPERATURE:
        title = "Temperature (°C)"
        imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/BuYlRd.png"
        minBound = "10"
        maxBound = "30"
        break;
      case layerType.FD_PRECIPITATION:
        title = "Precipitation (SPI)"
        imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/RdBu.png"
        minBound = "-2.0"
        maxBound = "2.0"
        break;
      case layerType.FD_DROUGHT:
        title = "Drought (PDSI)"
        imageSource = "https://storage.googleapis.com/cropai-mappable2/assets/Spectral.png"
        minBound = "-5"
        maxBound = "5"
        break;

      default:
        return <></>
    }
    return renderColorScaleLegend(title, minBound, maxBound, imageSource)
  }

  const isAnyFilterActive = (panelLayers: IGeoLayer[]) => {
    for (let layer of panelLayers){
      if (layer.active){

        return true
      }
    }
    return false
  }

  // Array of legends and booleans to determine active status
  const legendPairs: ILayerLegend[] = []
  for (let layer of panelLayers){
    const type = layer.type
    switch (type) {
      case layerType.GLOBAL_CROP:
        legendPairs.push(
          {
            type: layer.type,
            active: layer.active,
            legend: renderColorScaleLegend
            (
              "Cropland", "30.001", "100", "https://storage.googleapis.com/cropai-mappable2/assets/seaborn%20color%20palette%20rocket%20crop30.png"
            ),
          }
        )
        break;
      case layerType.GLOBAL_POP:
        legendPairs.push(
          {
            type: layer.type,
            active: layer.active,
            legend: getLegendPopulation(params.mapView),
          }
        )
        break;
        case layerType.POTASH_MINES:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendPotashMines,
            }
          )
          break;
        case layerType.POTASH_DEPOSITS:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendPotashDeposits,
            }
          )
          break;
        case layerType.PHOSPHATE_MINES:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendPhosphateMines,
            }
          )
          break;
        case layerType.PHOSPHATE_DEPOSITS:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendPhosphateDeposits,
            }
          )
          break;
        case layerType.NITROGEN_MANUFACTURING:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendNitrogenFacilities,
            }
          )
          break;
        case layerType.GLOBAL_RAIL:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendGlobalRail,
            }
          )
          break;
        case layerType.PORTS:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendPorts,
            }
          )
          break;
        case layerType.POTASH_SHIPPING_ROUTES:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendPotashShippingRoutes,
            }
          )
          break;
        case layerType.POTASH_TRADEFLOWS:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendPotashTradeflows,
            }
          )
          break;
        case layerType.AG_RETAILERS:
          legendPairs.push(
            {
              type: layer.type,
              active: layer.active,
              legend: legendAgRetailers,
            }
          )
          break;
          case layerType.CALI_SAM:
            legendPairs.push(
              {
                type: layer.type,
                active: layer.active,
                legend: legendAgFieldsSegmentation,
              }
            )
            break;
          case layerType.PHOSPHOROUS_APPLICATION:
            legendPairs.push(
              {
                type: layer.type,
                active: layer.active,
                legend: legendPhosphorousApplication,
              }
            )
            break;
          case layerType.NITROGEN_APPLICATION:
            legendPairs.push(
              {
                type: layer.type,
                active: layer.active,
                legend: legendNitrogenApplication,
              }
            )
            break;
            case layerType.YIELD_FORECAST:
              legendPairs.push(
                {
                  type: layer.type,
                  active: layer.active,
                  legend: renderYieldColorLegend(),
                }
              )
              break;

            case layerType.FD_TEMPERATURE:
            case layerType.FD_PRECIPITATION:
            case layerType.FD_DROUGHT:
              legendPairs.push(
                {
                  type: layer.type,
                  active: layer.active,
                  legend: renderConditionsColorLegend(layer.type),
                }
              )
              break;
      default:
        break;
    }
  }

  const getLegends = (legendPairs: ILayerLegend[]) => {
    return (
      <Box>
        {legendPairs.map((pair) => (
          <>
            {pair.active && pair.legend}
          </>
        ))}
        {/* NOTE: Setting a font size here of 15 or 16px will display a 1px high horizontal scroll bar from react-custom-scrollbars */}
        {
          !isAnyFilterActive(panelLayers) && <Typography fontSize={"14px"} >No filters are active</Typography>
        }
    </Box>
    );
  }

  return (
    <Accordion expanded={legendOpen} id={"legend-accordion"}
      disableGutters={true}
      sx={{
        backgroundColor: '#fffcfc',
        opacity: '90%',
        boxShadow: 'none',
        margin: 0,
        marginRight: '10px',
        overflowX: 'hidden',
        // overflowY: "auto",
        // maxHeight: params.legendMaxHeight || "28vh",
      }}
      >
        <AccordionSummary
          style={{
            margin: 0,
            minHeight: 0,
            height: 0,
            overflowX: 'hidden',
          }}
          id="panel-legend"
        ></AccordionSummary>
        <AccordionDetails
          style={{
            margin: 0,
            padding: "0px 0px",
          }}
        >
        <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax={params.legendMaxHeight || "28vh"}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
          hideTracksWhenNotNeeded={true}
        >
          <Box
            className="Flex Legends"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}>
            {getLegends(legendPairs)}
          </Box>
          </Scrollbars>
        </AccordionDetails>
      </Accordion>

  );
}
