import Plot from "react-plotly.js";
import { IBarPlot, IManageCache } from "../CropAI/CropAI";
import { fetchBarPlotData } from "../CropAI/FetchData";
import { VERBOSE_DEBUG } from "../../store/config";
import { useEffect, useState } from "react";
import { ChartSkeleton } from "./ChartSkeleton";

export interface IBarChartParams {
  cache: IManageCache,
  crop: string,
  stateName: string,
}

export const CountyBarChart = (params: IBarChartParams) => {

  const {cache, crop, stateName} = (params)
  const [fetchSent, setFetchSent] = useState(false)

  // Reset fetchSent state when state/crop is changed
  useEffect(() => {
    setFetchSent(false)
  }, [crop, stateName]);

  let chart: IBarPlot | undefined;

  // Fetch data
  switch(crop){
    case "corn":
      chart = cache.charts.chartData.barPlots.corn.find(e => e.level === stateName.toUpperCase())
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchBarPlotData(cache, "corn", stateName.toUpperCase())
        if (!cache.charts.chartData.barPlots.soybeans.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchBarPlotData(cache, "soybeans", stateName.toUpperCase())
        }
      }
      break;
    case "soybeans":
      chart = cache.charts.chartData.barPlots.soybeans.find(e => e.level === stateName.toUpperCase())
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchBarPlotData(cache, "soybeans", stateName.toUpperCase())
        if (!cache.charts.chartData.barPlots.corn.find(e => e.level === stateName.toUpperCase())){
          // If soybeans data is not cached then corn is probably not either. Fetch ahead
          fetchBarPlotData(cache, "corn", stateName.toUpperCase())
        }
      }
      break;
    default:
      console.log("ERROR: Invalid croptype in CountyBarChart")
      break
  }

  // If chart is undefined, either plot does not exist in cache or invalid crop was supplied
  if (chart){
  return (
    <>
      <Plot
          data={[
            {
              type: 'bar',
              x: chart.data.bcg.yield,
              y: chart.regions,
              orientation: 'h',
              name: 'BCG',
              marker: {
                color: "#007B57"
              },
              width: 0.6,
              text: chart.data.bcg.yield,
              texttemplate: '%{text:.0f}',
              textfont: {
                size: 12,
                family: "Bold"
              },
              showlegend: true
            },
          ]}
          config={{displayModeBar:false, responsive: true}}
          layout={{
            autosize: true,
            // width: 320,
            height: 400,
            margin: {t:20, l:0},
            xaxis: {showgrid: false, zeroline: false},
            yaxis: {automargin: true, showgrid: false, zeroline: false, tickformat: "<br>"},
            legend: {x: -0.1, orientation: 'h'},
          }}
          useResizeHandler={true}
          style={{width: '100%'}}
        />
      </>
  );
  }else{
    if (VERBOSE_DEBUG) console.log(`NO DATA: County Chart ${stateName} for ${crop} does not exist and could not be rendered.`)
    return (
      <ChartSkeleton/>
    )
  }
}
