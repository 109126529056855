import { FormGroup, FormControlLabel, Divider, Typography, Tooltip, IconButton, Box } from '@mui/material';
import { IStoryPanel, layerType } from '../../store/StoryApi';
import { LegendAccordion } from '../LegendAccordion/LegendAccordion';
import { ControlCheckbox } from '../ControlPanel/ControlPanel';
import { useState } from 'react';
import { FilterYieldForecast } from './FilterYieldForecast';
import { IManageState } from '../CropAI/CropAI';
import { produce } from 'immer';
import { ZoomIn } from '@mui/icons-material';
import { IMapView, MAP_VIEW_IOWA } from '../../store/MapApi';
import { LayerReset } from '../LayerReset/LayerReset';

interface IPanelInputParams {
  state: IManageState,
  panel: IStoryPanel,
  panelFilterArray: JSX.Element[],
  handleMapViewChange: (newMapView: IMapView) => void,
}

export const PanelFarmDemand = (params: IPanelInputParams) =>{
  const {panel, panelFilterArray, state, handleMapViewChange} = params
  const [legendOpen, setLegendOpen] = useState(false);
  // const [chartsOpen, setChartsOpen] = useState(false);

  const toggleLegendOpen = () => {
    setLegendOpen(!legendOpen)
  }
  const toggleChartsActive = () => {
    const panelIndex = state.storyData.panels.findIndex(e => e.name === panel.name)
    if (panelIndex !== -1){
      state.setStoryData(produce(draft => {
        draft.panels[panelIndex].chartsActive = !draft.panels[panelIndex].chartsActive
      }))
      console.log(`Toggled chartsActive for ${panel.name}`)
    }
  }

  const isChartsActive = () => {
    const panelIndex = state.storyData.panels.findIndex(e => e.name === panel.name)
    if (panelIndex !== -1){
      return (state.storyData.panels[panelIndex].chartsActive)
    }else{
      return false
    }
  }

  const zoomToIowa = () => {
    handleMapViewChange(MAP_VIEW_IOWA)
  }

  const renderFarm360Toggle = () => {


    const layerCheckbox = panelFilterArray.find(e => e.key === layerType.CALI_SAM)
    if (layerCheckbox){
      return (
        <Box sx={{display: "flex", flexDirection: "row"}}>
          {layerCheckbox}
          <Tooltip title="Zoom to Layer" placement="top" PopperProps={{style:{zIndex:3001}}}>
            <IconButton
              onClick={zoomToIowa}
              sx={{
                padding: "3px",
                marginLeft: "4px",
                color: "black",
              }}
              >
              <ZoomIn fontSize={"medium"}/>
            </IconButton>
          </Tooltip>
        </Box>
      )
    }else{
      return (
        <></>
      )
    }
  }

  const controlLayers = [
    layerType.AG_RETAILERS,
    layerType.CALI_SAM,
    layerType.CALI_EVI,
    layerType.FD_DROUGHT,
    layerType.FD_PRECIPITATION,
    layerType.FD_TEMPERATURE
  ]

  const generateLayerControls = () => {
    let componentArray: JSX.Element[] = []
    controlLayers.forEach(layer => {
      if (layer === layerType.CALI_SAM){
        componentArray.push(renderFarm360Toggle())
      }else{
        componentArray.push(
          <Typography sx={{paddingY: "5px"}}>
            {panelFilterArray.find(e => e.key === layer) || <></>}
          </Typography>
        )
      }
    })
    return componentArray
  }

  return (
  <FormGroup sx={{paddingLeft: "10px"}}>
    <Box sx={{paddingBottom: "5px"}}>
      <LayerReset
        state={params.state}
        panel={params.panel}
      />
    </Box>
    {generateLayerControls()}
    {/* <Typography sx={{paddingY: "5px"}}>
      {panelFilterArray.find(e => e.key === layerType.AG_RETAILERS) || <></>}
    </Typography>
    <Typography sx={{paddingY: "5px"}}>
      {renderFarm360Toggle()}
    </Typography>
    <Typography sx={{paddingY: "5px"}}>
      {panelFilterArray.find(e => e.key === layerType.CALI_EVI) || <></>}
    </Typography>
    <Typography sx={{paddingY: "5px"}}>
      {panelFilterArray.find(e => e.key === layerType.FD_DROUGHT) || <></>}
    </Typography>
    <Typography sx={{paddingY: "5px"}}>
      {panelFilterArray.find(e => e.key === layerType.FD_PRECIPITATION) || <></>}
    </Typography>
    <Typography sx={{paddingY: "5px"}}>
      {panelFilterArray.find(e => e.key === layerType.FD_TEMPERATURE) || <></>}
    </Typography> */}
    <FilterYieldForecast
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.YIELD_FORECAST)}
    />
    <FormControlLabel sx={{paddingY: "5px"}} control={<ControlCheckbox disabled />} label="Potassium Demand" />
    <FormControlLabel sx={{paddingY: "5px"}} control={<ControlCheckbox disabled />} label="Phosphorous Demand" />
    <FormControlLabel sx={{paddingY: "5px"}} control={<ControlCheckbox disabled />} label="Nitrogen Demand" />
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel  sx={{paddingTop: "10px"}} control={<ControlCheckbox onClick={toggleLegendOpen} />} label="LEGENDS" />
    <LegendAccordion
      panelLayers={panel.layers}
      legendOpen={legendOpen}
    />
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel sx={{paddingTop: "10px"}} control={<ControlCheckbox onClick={toggleChartsActive} checked={isChartsActive()} />} label="CHARTS" />
  </FormGroup>
  );
}
