import Plot from "react-plotly.js";
import { IBarPlot, IManageCache } from "../CropAI/CropAI";
import { ChartSkeleton } from "./ChartSkeleton";


export interface IScatterChartParams {
  cache: IManageCache,
  crop: string,
}

// TODO: Move this interface to explicitly define in bar chart interface from cache

export const NationalBarChart = (params: IScatterChartParams) => {

  const {cache, crop} = (params)

  let chart: IBarPlot | undefined;

  switch(crop){
    case "corn":
      chart = cache.charts.chartData.barPlots.corn.find(e => e.level === "NATIONAL")
      break;
    case "soybeans":
      chart = cache.charts.chartData.barPlots.soybeans.find(e => e.level === "NATIONAL")
      break;
    default:
      console.log("ERROR: Invalid croptype in NationalBarChart")
      break
  }

  // If chart is undefined, either plot does not exist in cache or invalid crop was supplied
  if (chart){
    return (
      <>
        <Plot
          data={[
            {
              type: 'bar',
              x: chart.data.bcg.yield,
              y: chart.regions,
              orientation: 'h',
              name: 'BCG',
              marker: {
                color: "#007B57"
              },
              // width: 0.4,
              text: chart.data.bcg.yield,
              texttemplate: '%{text:.0f}',
              textposition: 'outside',
              cliponaxis: false,
              // textfont: {
              //   size: 16,
              //   family: "Bold"
              // }
            },
            {
              type: 'bar',
              x: chart.data.usda?.yield ?? [],
              y: chart.regions,
              orientation: 'h',
              name: 'USDA',
              marker: {
                color: "#8AC481"
              },
              // width: 0.4,
              text: chart.data.usda?.yield ?? [],
              texttemplate: '%{text:.0f}',
              textposition: 'outside',
              cliponaxis: false,
              // textfont: {
              //   size: 16,
              //   family: "Bold"
              // }
            },
          ]}
          config={{displayModeBar:false, responsive: true}}
          layout={{
            autosize: true,
            // width: 300,
            height: 440,
            margin: {t:20, l:0},
            xaxis: {showgrid: false, zeroline: false},
            yaxis: {automargin: true, showgrid: false, zeroline: false},
            legend: {x: -0.1, orientation: 'h'}
          }}
          useResizeHandler={true}
          style={{width: '100%'}}
        />
      </>
    );
  }else{
    console.log("NO DATA: Chart does not exist and could not be rendered.")
    return (
      <ChartSkeleton/>
    )
  }
}
