import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { IBasicCardParams, IResultCardParams } from './CardApi';
import { CardActionArea, CardHeader, Grid } from '@mui/material';
import { Link as RouterLink} from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const defaultBasicParams: IBasicCardParams = {
  lightHeader: "Module Light Header",
  boldHeader: "Module Bold Header",
  subHeader: ["Module Sub Header"],
  content: "Module content",
}

const createCardContentList = (bullets:string[]) => {
  const list = []
  var i = 1
  for (const bullet of bullets){
    i += 1
    list.push(<li key={i}>{bullet}</li>)
  }
  return list
}

export default function BasicCard(params:IBasicCardParams = defaultBasicParams) {

  const getLinkAddr = (src: string) => {
    if (src === "/cropgpt"){
      return "http://130.211.229.83/"
    }
    return src
  }

  console.log("Basic params")
  console.log(params)
  return (
    <Card sx={{
        minWidth: "275px",
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "20px",
        borderColor: params.override?.backgroundColor ?? "black",
      }}>
      <CardActionArea component={RouterLink} to={params?.actionCardLink ? getLinkAddr(params.actionCardLink) : ""}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {params.lightHeader}
          </Typography>
          <Typography variant="h5" component="div">
            {params.boldHeader}
          </Typography>
          <ul>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {createCardContentList(params.subHeader || ["..."])}
            </Typography>
          </ul>
          <Typography variant="body2">
            {params.content}
            <br />
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small">
          {params?.actionTextLink || "Learn More"}
        </Button>
      </CardActions> */}
    </Card>
  );
}

const defaultResultParams: IResultCardParams = {
  title: "Wealth Management",
  timeElapsed: "24 hrs ago",
  jobTitles: 1986,
  jobPostings: 180_000,
  employers: 80,
}

export function ResultCard(params:IResultCardParams = defaultResultParams) {
  return (
    <Card sx={{ maxWidth: 600 }}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={params.title}
        subheader={params.timeElapsed}
      />
      <CardContent>
        <Grid container spacing={2} direction="row">
          <Grid item xs="auto">
            Standardized Job Titles <b>{params.jobTitles}</b>
          </Grid>
          <Grid item xs="auto">
            Job Postings <b>{params.jobPostings}</b>
          </Grid>
          <Grid item xs="auto">
            Employers <b>{params.employers}</b>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="edit">
          <EditIcon />
        </IconButton>
        <IconButton aria-label="clone">
          <ContentCopyIcon />
        </IconButton>
        <IconButton aria-label="refresh">
          <CachedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
