import { IMapView } from "../../store/MapApi";
import * as S from './styles'

interface CoordinateBoxParams {
  viewState: IMapView
}

export const CoordinateBox = (params: CoordinateBoxParams) => {
  const {viewState} = params
  return (
    <S.Box>
      Lat: {viewState.latitude.toFixed(4)} | Long: {viewState.longitude.toFixed(4)} | Zoom: {viewState.zoom.toFixed(2)}
    </S.Box>
    );
}
