import { FlyToInterpolator, LinearInterpolator } from "@deck.gl/core/typed";

export const MAPBOX_ACCESS_TOKEN =
"pk.eyJ1IjoieWFveXVuIiwiYSI6ImNsZDI5NDBrczAxYTYzcXFpaWk2YmZyMGEifQ.GVuApPUviRKNwBWMFQM2dw";

export const INITIAL_MAP_STYLE = "mapbox://styles/mapbox/dark-v9";

export const MAPBOX_STYLE_OPTIONS = [
  { label: "Dark", value: "mapbox://styles/mapbox/dark-v9" },
  { label: "Light", value: "mapbox://styles/mapbox/light-v9" },
  { label: "Streets", value: "mapbox://styles/mapbox/streets-v12" },
  { label: "Outdoors", value: "mapbox://styles/mapbox/outdoors-v10" },
  { label: "Satellite", value: "mapbox://styles/mapbox/satellite-v9" },
  {
    label: "Satellite Streets",
    value: "mapbox://styles/mapbox/satellite-streets-v10",
  },
  {
    label: "Navigation Preview Day",
    value: "mapbox://styles/mapbox/navigation-preview-day-v4",
  },
  {
    label: "Navigation Preview Night",
    value: "mapbox://styles/mapbox/navigation-preview-night-v4",
  },
  {
    label: "Navigation Guidance Day",
    value: "mapbox://styles/mapbox/navigation-guidance-day-v4",
  },
  {
    label: "Navigation Guidance Night",
    value: "mapbox://styles/mapbox/navigation-guidance-night-v4",
  },
];

export interface IMapView {
  longitude: number,
  latitude: number,
  zoom: number,
  pitch: number,
  bearing: number,
  transitionDuration?: number,
  transitionInterpolator?: FlyToInterpolator | LinearInterpolator,
}
export interface CoordinatePair {
  latitude: number,
  longitude: number,
}

export interface IAddressPoint {
  query: string,
  formattedAddress: string,
  active: boolean,
  geometry: CoordinatePair,
}

export const INITIAL_MAP_VIEW: IMapView = {
  latitude: 12,
  longitude: 0,
  zoom: 2,
  pitch: 0,
  bearing: 0,
};

export const MAP_VIEW_USA: IMapView = {
  longitude: -97.8183,
  latitude: 39.0137,
  zoom: 4,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}

export const MAP_VIEW_CENTRAL_VALLEY: IMapView = {
  latitude: 36.321591,
  longitude: -119.661905,
  zoom: 8,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}

export const MAP_VIEW_CORN_BELT: IMapView = {
  latitude: 42.0000,
  longitude: -91.7189,
  zoom: 3.5,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}

const IOWA_CENTER: CoordinatePair = {
  latitude: 42.158589,
  longitude: -93.532595,
}

export const MAP_VIEW_IOWA: IMapView = {
  latitude: IOWA_CENTER.latitude,
  longitude: IOWA_CENTER.longitude,
  zoom: 6.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}

export const MAP_VIEW_GLOBAL_CENTER: IMapView = {
  latitude: 12,
  longitude: 0,
  zoom: 2,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}

export const cornbeltStates = [
  "Arkansas",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Nebraska",
  "North Dakota",
  "Ohio",
  "South Dakota",
  "Wisconsin",
]

// Map Views per State
export const STATE_VIEW_ARKANSAS: IMapView = {
  latitude: 34.5239,
  longitude: -91.8128,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_ILLINOIS: IMapView = {
  latitude: 40.1369,
  longitude: -89.5404,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_INDIANA: IMapView = {
  latitude: 40.1663,
  longitude: -86.5595,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_IOWA: IMapView = {
  latitude: 42.2759,
  longitude: -92.8929,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_KANSAS: IMapView = {
  latitude: 38.5149,
  longitude: -98.7121,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_KENTUCKY: IMapView = {
  latitude: 37.4108,
  longitude: -84.0946,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_MICHIGAN: IMapView = {
  latitude: 43.4217,
  longitude: -84.2385,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_MINNESOTA: IMapView = {
  latitude: 46.7020,
  longitude: -93.1326,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_MISSOURI: IMapView = {
  latitude: 38.6528,
  longitude: -92.2610,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_NEBRASKA: IMapView = {
  latitude: 41.6613,
  longitude: -99.3794,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_NORTH_DAKOTA: IMapView = {
  latitude: 47.6417,
  longitude: -100.0989,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_SOUTH_DAKOTA: IMapView = {
  latitude: 44.2632,
  longitude: -100.0989,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_OHIO: IMapView = {
  latitude: 40.4689,
  longitude: -81.8608,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}
export const STATE_VIEW_WISCONSIN: IMapView = {
  latitude: 44.6919,
  longitude: -89.6689,
  zoom: 5.0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
}

export const STATE_FIPS_MAPPING = [
  {FIPS: "01", abbr:"AL", name:"ALABAMA"},
  {FIPS: "02", abbr:"AK", name:"ALASKA"},
  {FIPS: "04", abbr: "AZ", name:"ARIZONA"},
  {FIPS: "05", abbr:"AR", name:"ARKANSAS", view:STATE_VIEW_ARKANSAS},
  {FIPS: "06", abbr:"CA", name:"CALIFORNIA"},
  {FIPS: "08", abbr:"CO", name:"COLORADO"},
  {FIPS: "09", abbr:"CT", name:"CONNECTICUT"},
  {FIPS: "10", abbr:"DE", name:"DELAWARE"},
  {FIPS: "11", abbr:"D.C.", name:"DISTRICT OF COLUMBIA"},
  {FIPS: "12", abbr:"FL", name:"FLORIDA"},
  {FIPS: "13", abbr:"GA", name:"GEORGIA"},
  {FIPS: "15", abbr:"HI", name:"HAWAII"},
  {FIPS: "16", abbr:"ID", name:"IDAHO"},
  {FIPS: "17", abbr:"IL", name:"ILLINOIS", view:STATE_VIEW_ILLINOIS},
  {FIPS: "18", abbr:"IN", name:"INDIANA", view:STATE_VIEW_INDIANA},
  {FIPS: "19", abbr:"IA", name:"IOWA", view:STATE_VIEW_IOWA},
  {FIPS: "20", abbr:"KS", name:"KANSAS", view:STATE_VIEW_KANSAS},
  {FIPS: "21", abbr:"KY", name:"KENTUCKY", view:STATE_VIEW_KENTUCKY},
  {FIPS: "22", abbr:"LA", name:"LOUISIANA"},
  {FIPS: "23", abbr:"ME", name:"MAINE"},
  {FIPS: "24", abbr:"MD", name:"MARYLAND"},
  {FIPS: "25", abbr:"MA", name:"MASSACHUSETTS"},
  {FIPS: "26", abbr:"MI", name:"MICHIGAN", view:STATE_VIEW_MICHIGAN},
  {FIPS: "27", abbr:"MI", name:"MINNESOTA", view:STATE_VIEW_MINNESOTA},
  {FIPS: "28", abbr:"MS", name:"MISSISSIPPI"},
  {FIPS: "29", abbr:"MO", name:"MISSOURI", view:STATE_VIEW_MISSOURI},
  {FIPS: "30", abbr:"MT", name:"MONTANA"},
  {FIPS: "31", abbr:"NE", name:"NEBRASKA", view:STATE_VIEW_NEBRASKA},
  {FIPS: "32", abbr:"NV", name:"NEVADA"},
  {FIPS: "33", abbr:"NH", name:"NEW HAMPSHIRE"},
  {FIPS: "34", abbr:"NJ", name:"NEW JERSEY"},
  {FIPS: "35", abbr:"NM", name:"NEW MEXICO"},
  {FIPS: "36", abbr:"NY", name:"NEW YORK"},
  {FIPS: "37", abbr:"NC", name:"NORTH CAROLINA"},
  {FIPS: "38", abbr:"ND", name:"NORTH DAKOTA", view:STATE_VIEW_NORTH_DAKOTA},
  {FIPS: "39", abbr:"OH", name:"OHIO", view:STATE_VIEW_OHIO},
  {FIPS: "40", abbr:"OK", name:"OKLAHOMA"},
  {FIPS: "41", abbr:"O", name:"OREGON"},
  {FIPS: "42", abbr:"PA", name:"PENNSYLVANIA"},
  {FIPS: "44", abbr:"RI", name:"RHODE ISLAND"},
  {FIPS: "45", abbr:"SC", name:"SOUTH CAROLINA"},
  {FIPS: "46", abbr:"SD", name:"SOUTH DAKOTA", view:STATE_VIEW_SOUTH_DAKOTA},
  {FIPS: "47", abbr:"TN", name:"TENNESSEE"},
  {FIPS: "48", abbr:"TX", name:"TEXAS"},
  {FIPS: "49", abbr:"UT", name:"UTAH"},
  {FIPS: "50", abbr:"VT", name:"VERMONT"},
  {FIPS: "51", abbr:"VA", name:"VIRGINIA"},
  {FIPS: "53", abbr:"WA", name:"WASHINGTON"},
  {FIPS: "54", abbr:"WV", name:"WEST VIRGINIA"},
  {FIPS: "55", abbr:"WI", name:"WISCONSIN", view:STATE_VIEW_WISCONSIN},
  {FIPS: "56", abbr:"WY", name:"WYOMING"},
]
