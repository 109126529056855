import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import Paper from '@mui/material/Paper';
import { GenerateChart, chartType } from './GenerateChart';
import { IStoryData, StoryType, fieldType, layerType } from '../../store/StoryApi';
import { BACKEND_HOST } from '../../store/config';

interface IYieldForecastChartPanelParams {
  storyData: IStoryData,
}

export const YieldForecastChartPanel = (params: IYieldForecastChartPanelParams) =>{

  const {storyData} = params;

  const subFilters = storyData.panels.find(e => e.name === StoryType.FARM_DEMAND)?.layers.find(e => e.type === layerType.YIELD_FORECAST)?.subFilters;
  const crop = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_CROP)?.activeValues[0]?.toLowerCase();
  // const model = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_MODEL)?.activeValues[0]?.toLowerCase();

  let cropQuery = 'corn'
  if (crop){
    cropQuery = crop
  }

  return (
    <Box className="YieldForecastPanel" sx={{
      position: "absolute",
      top: "0px",
      left: "calc(100vw - 330px)",
    }}>
      <Paper style={{
        backgroundColor: '#fffcfc',
        opacity: '90%',
        boxShadow: 'none',
        borderRadius: "10px",
        padding: '10px',
        width: '320px',
        // minHeight: '400px',
        // height: '95vh',
        height: 'calc(100vh - 30px)',
        // zIndex: "2000",
        display: "flex",
        flexDirection: "column",
      }}>
        <Box className="NationalChart" sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width:"100%",
          // height: "50%"
          }}>
          {/* <Typography variant="subtitle1">NATIONAL YIELD FORECAST</Typography> */}
          <GenerateChart
            host={BACKEND_HOST}
            path={`/get_national_yield_forecast/${cropQuery}?height=200&width=300`}
            level={"NATIONAL"}
            type={chartType.PANEL}
            />
        </Box>
        <br/>
        <Divider sx={{padding: "5px"}} variant="fullWidth" />
        <br/>
        <Box className="StateChart" sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // height: "50%"
          }}>
        {/* <Typography variant="subtitle1">STATE YIELD FORECAST</Typography> */}
          <GenerateChart
              host={BACKEND_HOST}
              path={`/get_state_yield_forecast/${cropQuery}`}
              level={"STATE"}
              type={chartType.PANEL}
            />
        </Box>
      </Paper>
    </Box>
  );
}
