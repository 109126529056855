import Plot from "react-plotly.js";
import { IScatterPlot, IManageCache } from "../CropAI/CropAI";
import { fetchScatterPlotData } from "../CropAI/FetchData";
import { VERBOSE_DEBUG } from "../../store/config";
import { useEffect, useState } from "react";
import { ChartSkeleton } from "./ChartSkeleton";

export interface IScatterChartParams {
  cache: IManageCache,
  crop: string,
  stateName: string,
}

export const StateYieldChart = (params: IScatterChartParams) => {

  const {cache, crop, stateName} = (params)
  const [fetchSent, setFetchSent] = useState(false)

  // Reset fetchSent state when state/crop is changed
  useEffect(() => {
    setFetchSent(false)
  }, [crop, stateName]);

  let chart: IScatterPlot | undefined;
  let yRange: any;

  // Fetch data
  switch(crop){
    case "corn":
      chart = cache.charts.chartData.scatterPlots.corn.find(e => e.level === stateName.toUpperCase())
      yRange = [100, 220]
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchScatterPlotData(cache, "corn", stateName.toUpperCase())
        if (!cache.charts.chartData.scatterPlots.soybeans.find(e => e.level === stateName.toUpperCase())){
          // If corn data is not cached then soy is probably not either. Fetch ahead
          fetchScatterPlotData(cache, "soybeans", stateName.toUpperCase())
        }
      }
      break;
    case "soybeans":
      chart = cache.charts.chartData.scatterPlots.soybeans.find(e => e.level === stateName.toUpperCase())
      yRange = [25, 75]
      if (!chart && !fetchSent){
        setFetchSent(true)
        fetchScatterPlotData(cache, "soybeans", stateName.toUpperCase())
        if (!cache.charts.chartData.scatterPlots.corn.find(e => e.level === stateName.toUpperCase())){
          // If soybeans data is not cached then corn is probably not either. Fetch ahead
          fetchScatterPlotData(cache, "corn", stateName.toUpperCase())
        }
      }
      break;
    default:
      console.log("ERROR: Invalid croptype in State Yield Forecast")
      break
  }

  // If chart is undefined, either plot does not exist in cache or invalid crop was supplied
  if (chart){
  return (
    <>
        <Plot
          data={[
            {
              type: 'scatter',
              mode: 'lines+markers',
              x: chart.date,
              y: chart.data.bcg.yield,
              name: 'BCG',
              line: {
                shape: "hv",
                color: "#007B57"
              },
            },
            {
              type: 'scatter',
              mode: "markers",
              x: chart.date,
              y: chart.data.usda.yield,
              name: 'USDA',
              marker: {
                size: 10,
                symbol: "diamond",
              },
              line: {
                shape: "hv",
                color: "#8AC481"
              },
              showlegend: false
            },
          ]}
          config={{displayModeBar:false, responsive: true}}
          layout={{
            autosize: true,
            // width: 390,
            height: 342,
            margin: {t: 10, l: 50, r: 0},
            xaxis: {automargin: true, tickformat: '%b-%d', showgrid: false},
            yaxis: {title: 'Yield (Bu/Acre)', showgrid: false, range: yRange},
            legend: {x:0.5, y: -0.09, xanchor: "center", orientation: 'h'}
        }}
        useResizeHandler={true}
        style={{width: '100%'}}
        />
      </>
  );
  }else{
    if (VERBOSE_DEBUG) console.log(`NO DATA: State Yield Forecast Chart ${stateName} for ${crop} does not exist and could not be rendered.`)
    return (
      <ChartSkeleton/>
    )
  }
}
