import {
  styled,
  Box as MuiBox
} from '@mui/material'

export const Box = styled(MuiBox)(() => ({
  marginLeft: '10px',
  backgroundColor: "#35363a",
  color: "white",
  borderRadius: "4px",
  paddingLeft: "4px",
  paddingRight: "4px",
  position: "absolute",
  zIndex: "800",
  bottom: "5px",
  // top: "93.5vh",
  left: "90px",
  opacity: "75%"
}));
