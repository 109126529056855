import { Box, IconButton, Typography } from "@mui/material";
import { IManageState } from "../CropAI/CropAI";
import { RestartAlt } from "@mui/icons-material";
import { produce } from "immer";
import { IStoryPanel } from "../../store/StoryApi";

interface ILayerResetParams {
  state: IManageState,
  panel: IStoryPanel,
}

export const LayerReset = (params: ILayerResetParams) => {

 const {state, panel} = (params)

  const resetPanelLayers = () => {
    state.setStoryData(produce(draft => {
      const panelIndex = draft.panels.findIndex(e => e.name === panel.name)
      if (panelIndex > -1){
        draft.panels[panelIndex].layers.forEach(e => e.active = false)
        console.log(`Reset layers for Panel: ${panel.name}`)
      }
    }))
  }

  return (
    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
    {/* <Tooltip title="Reset story layers" placement="bottom" PopperProps={{style:{zIndex:3001}}}> */}
      <IconButton
        onClick={resetPanelLayers}
        sx={{
          padding: "0px",
          marginLeft: "-1px",
          marginRight: "9px",
          color: "#6E76FA",
        }}
        >
        <RestartAlt fontSize={"medium"}/>
      </IconButton>
    {/* </Tooltip> */}
    <Typography>
      {"Reset story layers"}
    </Typography>
  </Box>
  );
};
