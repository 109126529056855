import {
  styled,
  Box
} from '@mui/material'

export const MapWindow = styled(Box)(() => ({
  // marginLeft: '40px',
  // alignItems: 'stretch',
  // overflow: 'hidden',
  height: '100%',
  // width: '100%'
}));
