import React from 'react'

import FormControl from '@mui/material/FormControl';
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


import { Dayjs } from 'dayjs';

import {Paper} from "@mui/material";


interface Props {
    handleSetElevatorDate: (date: Dayjs | null) => void;
    handleSetElevatorMode: (elevatorMode: string) => void;
    elevatorDate: Dayjs
    allowedElevatorDates: Dayjs[]
    elevatorMode: string
}


const ControlPanel = (props: Props) => {
    return (
        <Paper
            // elevation={3}
            style={{
                padding: 8,
                position: "absolute",
                // pointerEvents: "none",
                top: 20,
                left: 20,
                width: 250,
                bottom: 20,
                opacity: 0.8,
            }}
        >
            <h1>Grain Elevators</h1>
            <br/><br/>
            <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Scenario Date"

                        // inputFormat="MM/DD/YYYY"
                        value={props.elevatorDate}
                        onChange={props.handleSetElevatorDate}
                        // renderInput={(params: any) => <TextField {...params} />}
                        disableFuture={true}
                        shouldDisableDate={(day: Dayjs) => {
                            return !props.allowedElevatorDates.some((element) => element.isSame(day, "day"))
                        }}
                    />
                </LocalizationProvider>
                <br/><br/>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Mode</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={props.elevatorMode}
                    // defaultValue="COUNTY"
                    name="radio-buttons-group"
                    onChange={event => props.handleSetElevatorMode(event.target.value)}
                  >
                      <FormControlLabel value="WINLOSS" control={<Radio/>} label="Win/Loss" />
                      <FormControlLabel value="FOB" control={<Radio/>} label="FOB Price" />
                  </RadioGroup>
                </FormControl>

            </div>
            <br/><br/>
            {/*<ElevatorLegend/>*/}

        </Paper>
    )
}

export function ElevatorLegend() {
    function ballStyle(color: string): object {
        return {
            height: "16px",
            width: "16px",
            backgroundColor: color,
            borderRadius: "50%",
            display: "inline-block"
        }
    }
    return <Paper style={{
                padding: 8,
                position: "absolute",
                opacity: .95,
                bottom: 230,
                left: 300
            }}>
        <h2>Elevator Legend</h2>
        <span className="dot" style={ballStyle("#00E0B5")}/> Cargill
        <br/>
        <span className="dot" style={ballStyle("#6E76FA")}/> Cooperative
        <br/>
        <span className="dot" style={ballStyle("#00FFCC")}/> Heartland
        <br/>
        <span className="dot" style={ballStyle("#2B0B9E")}/> Innovative AG Services
        <br/>
        <span className="dot" style={ballStyle("#A5A5A9")}/> Other
    </Paper>
}


export default ControlPanel;
