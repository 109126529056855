import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { IManageState } from "../CropAI/CropAI";
import { RestartAlt } from "@mui/icons-material";
import { produce } from "immer";
import { IGeoLayer, IStoryPanel, layerType } from "../../store/StoryApi";

interface IMapResetParams {
  state: IManageState,
  excludeSet?: layerType[],
}

export const resetMapLayers = (state: IManageState, excludeSet:layerType[] = []) => {
  state.setStoryData(produce(draft => {

    draft.panels.forEach(panel => panel.layers.forEach(layer => {
      // Reset all layers except for those in the excludeSet
      if (!excludeSet.includes(layer.type)){
        layer.active = false
      }
    }))
    console.log(`Reset map layers`)
  }))
}

export const MapReset = (params: IMapResetParams) => {

 const {state, excludeSet=[]} = (params)

 const handleResetClick = () => {
  resetMapLayers(state, excludeSet)
 }

  return (
    <Tooltip title="Reset all layers" placement="bottom" PopperProps={{style:{zIndex:3001}}}>
    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <IconButton
        onClick={handleResetClick}
        sx={{
          // padding: "0px",
          // color: "#6E76FA",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "rgba(255, 252, 252, .7)",
          '&:hover': {
            backgroundColor: '#fffcfc',
            color: '#6E76FA',
          },
          borderRadius: "4px",
          padding: "4px 8px",
        }}
        >
        <RestartAlt sx={{color: "#6E76FA",}} fontSize={"medium"}/>
        <Typography>
          {"Reset"}
        </Typography>
      </IconButton>
    </Box>
  </Tooltip>
  );
};
