// TODO: Func description
export const HSLToRGB = (h: number, s: number, l: number): number[] => {
  s /= 100;
  l /= 100;
  const k = (n: number): number => (n + h / 30) % 12;
  const a: number = s * Math.min(l, 1 - l);
  const f = (n: number): number =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [255 * f(0), 255 * f(8), 255 * f(4)];
};

// Returns RGB or RGBA representation from HEX string of form: #{val}{val}{val}{val}{val}{val} where {val} is a hexadecimal value
// Alpha is optional --> defaults to 1
export const hexToRGB = (hexCode: string, alpha?: number) => {

  // Validate expected length
  if (hexCode.length !== 7 || hexCode[0] !== "#"){
    console.log("Warning: Invalid hexcode passed through hexToRGB")
    return alpha ? "rgba(255,255,255,1)" : "rgb(255,255,255)"
  }

  // Erorr checking?
  var R = parseInt(hexCode.slice(1, 3), 16),
      G = parseInt(hexCode.slice(3, 5), 16),
      B = parseInt(hexCode.slice(5, 7), 16);

  return alpha ? "rgba(" + R + ", " + G + ", " + B + ", " + alpha + ")" : "rgb(" + R + ", " + G + ", " + B + ")"
};

export const convertRGBtoArray = (rgbString: string) => {
  const rgbArray = rgbString.slice(
    rgbString.indexOf("(") + 1,
    rgbString.indexOf(")")
    // @ts-ignore
  ).split(", ").map(k => parseInt(k, 10))
  // console.log("rgbArray: ")
  // console.log(rgbArray)
  return rgbArray
}
