import { Checkbox, FormGroup, FormControlLabel, Divider, Grid, Typography, Box } from '@mui/material';
import { IStoryPanel, ISubFilter, StoryType, layerType } from '../../store/StoryApi';
import { LegendAccordion } from '../LegendAccordion/LegendAccordion';
import { useState } from 'react';
import { ControlCheckbox } from '../ControlPanel/ControlPanel';
import { FilterPotashMines } from './FilterPotashMines';
import { FilterPhosphateMines } from './FilterPhosphateMines';
import { FilterNitrogenManufacturing } from './FilterNitrogenManufacturing';
import { IManageState, IUpdateLayerSubFilters } from '../CropAI/CropAI';
import { LayerReset } from '../LayerReset/LayerReset';

interface IPanelInputParams {
  state: IManageState,
  panel: IStoryPanel
  panelFilterArray: JSX.Element[]
}

export const PanelAgInputs = (params: IPanelInputParams) =>{
  const {state, panel, panelFilterArray} = params
  const [legendOpen, setLegendOpen] = useState(false);

  const toggleLegendOpen = () => {
    console.log("Toggled Legend")
    setLegendOpen(!legendOpen)
  }

  return (
  <FormGroup sx={{paddingLeft: "10px"}}>
    <Box sx={{paddingBottom: "5px"}}>
      <LayerReset
        state={params.state}
        panel={params.panel}
      />
    </Box>
    {"Potassium"}
    <FilterPotashMines
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.POTASH_MINES)}
    />
    {/* {panelFilterArray.find(e => e.key === layerType.POTASH_MINES) || <></>} */}
    {panelFilterArray.find(e => e.key === layerType.POTASH_DEPOSITS) || <></>}
    <FormControlLabel control={<ControlCheckbox disabled />} label="Potash Application" />
    <br/>
    {"Phosphorous"}
    {panelFilterArray.find(e => e.key === layerType.PHOSPHATE_MINES) || <></>}
    {panelFilterArray.find(e => e.key === layerType.PHOSPHATE_DEPOSITS) || <></>}
    {panelFilterArray.find(e => e.key === layerType.PHOSPHOROUS_APPLICATION) || <></>}
    <br/>
    {"Nitrogen"}
    {panelFilterArray.find(e => e.key === layerType.NITROGEN_MANUFACTURING) || <></>}
    {panelFilterArray.find(e => e.key === layerType.NITROGEN_APPLICATION) || <></>}
    {/* <Typography sx={{paddingY: "5px"}}>
      {panelFilterArray.find(e => e.key === layerType.POTASH_DEPOSITS) || <></>}
    </Typography>
    <FormControlLabel sx={{paddingY: "5px"}} control={<ControlCheckbox disabled />} label="Potash Application" />
    <Typography sx={{paddingTop: "10px"}}>
      {"Phosphorous"}
    </Typography>
    <FilterPhosphateMines
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.PHOSPHATE_MINES) || <></>}
    />
    <Typography sx={{paddingY: "5px"}}>
      {panelFilterArray.find(e => e.key === layerType.PHOSPHATE_DEPOSITS) || <></>}
    </Typography>
    <FormControlLabel sx={{paddingY: "5px"}} control={<ControlCheckbox disabled />} label="Phosphorous Application" />
    <Typography sx={{paddingTop: "10px"}}>
      {"Nitrogen"}
    </Typography>
    <FilterNitrogenManufacturing
      state={state}
      panel={panel}
      layerCheckbox={panelFilterArray.find(e => e.key === layerType.NITROGEN_MANUFACTURING) || <></>}
    /> */}
    {/* <FormControlLabel sx={{paddingY: "5px"}} control={<ControlCheckbox disabled />} label="Nitrogen Application" /> */}
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel  sx={{paddingTop: "10px"}} control={<ControlCheckbox onClick={toggleLegendOpen} />} label="LEGENDS" />
    <LegendAccordion
      panelLayers={panel.layers}
      legendOpen={legendOpen}
    />
    <Divider sx={{padding: "5px"}} variant="fullWidth" />
    <FormControlLabel sx={{paddingTop: "10px"}} control={<ControlCheckbox disabled />} label="CHARTS" />
  </FormGroup>
  );
}
