import { Box, IconButton} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IStoryPanel, fieldType, layerType } from '../../store/StoryApi';
import { useState } from 'react';
import { IManageState } from '../CropAI/CropAI';
import { PanelSubFilterSingle } from '../PanelSubFilter/PanelSubFilterSingle';

interface IFilterParams {
  state: IManageState,
  panel: IStoryPanel,
  layerCheckbox?: JSX.Element,
}

export const FilterYieldForecast = (params: IFilterParams) =>{
  const {state, panel, layerCheckbox} = params
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const toggleFilters = () => {
    console.log("Toggled Filters")
    setFiltersExpanded(!filtersExpanded)
  }

  const layer = panel.layers.find(e => e.type === layerType.YIELD_FORECAST)

  const getDefaultSelection = (field: fieldType) => {
    switch (field) {
      case fieldType.YIELD_FORECAST_CROP:
        return "Corn"
      case fieldType.YIELD_FORECAST_MODEL:
        return "Actual"
      case fieldType.YIELD_FORECAST_LEVEL:
        return "County"
      default:
        return "UNKNOWN"
    }
  }

  // If the layerCheckbox or layer itself could not be found in panel, do not render
  if (!layerCheckbox || !layer){
    return (<></>)
  }else{
    return (
    <>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        paddingY: "5px"
      }}>
        {layerCheckbox}
        <IconButton
          sx={{padding: "0px"}}
          onClick={toggleFilters}
        >
        {
          (filtersExpanded ? <ExpandLessIcon sx={{color: "#667080"}}/> : <ExpandMoreIcon sx={{color: "#667080"}}/>)
        }
        </IconButton>
      </Box>
      <Box className="SubFilter Box" sx ={{
        display: (filtersExpanded ? "block" : "none"),
      }}>
        {
        layer.subFilters?.map((subFilter) => (
          <PanelSubFilterSingle
            key={subFilter.field}
            panel={panel}
            layerName={layer.type}
            subFilter={subFilter}
            state={state}
            defaultSelection={getDefaultSelection(subFilter.field)}
          />
          ))
        }
      </Box>
    </>
    );
  }
}
