import { useState, useEffect, useMemo } from "react";
import { MapSelector } from "../MapSelector/MapSelector";
import { IAddressPoint, IMapView, INITIAL_MAP_STYLE, MAP_VIEW_CORN_BELT } from "../../store/MapApi";
import * as S from './styles'
import { StoryPanel } from "../StoryPanel/StoryPanel";
import { IRenderMapParams, RenderMap } from "../MapLayers/RenderMap";
import Box from '@mui/material/Box';
import { IShapeData, IGeoLayer, IStoryData, ISubFilter, StoryType, agInputs, centralValley, farmDemand, fieldType, globalAg, layerType, railAndShipping, supplyChain, tradeflows, IStateShapeData, optionSet } from "../../store/StoryApi";
import { produce } from "immer";
import axios from "axios";
import { VERBOSE_DEBUG } from "../../store/config";
import { fetchBarPlotData, fetchChiPlotData, fetchCountyData, fetchNationalMetrics, fetchScatterPlotData, fetchSettings, fetchStateData } from "./FetchData";
import { Dashboard } from "../Dashboard/Dashboard";
import Scrollbars from "react-custom-scrollbars";
import { AddressSearch } from "../MapLayers/AddressSearch";
import { MapReset } from "../LayerReset/MapReset";

export interface IUpdateLayerSubFilters {
  panelName: StoryType,
  layerToUpdate: layerType,
  newSubFilters: ISubFilter[],
  updateActive?: boolean
}

export interface IManageState {
  storyData: IStoryData,
  setStoryData: (value: React.SetStateAction<IStoryData>) => void,
}

export interface ICountyCache {
  data: IShapeData,
  setData: (value: React.SetStateAction<IShapeData>) => void,
}

export interface IStateCache {
  data: IStateShapeData,
  setData: (value: React.SetStateAction<IStateShapeData>) => void,
}


export interface INationalMetrics {
  corn: any,
  soybeans: any,
}

export interface IMetricsCache {
  nationalData: INationalMetrics,
  setNationalData: (value: React.SetStateAction<INationalMetrics>) => void,
}

export interface IAddressCache {
  layerActive: boolean,
  addressArray: IAddressPoint[],
  setAddressArray: (value: React.SetStateAction<IAddressPoint[]>) => void,
}


export interface ISettings {
  dashboard:{
    forecastSummary: {
      addHeader: boolean,
      corn: {
        headerText: string,
        README?: string,
        lines: Array<string>
      }
      soybeans: {
        headerText: string,
        README?: string,
        lines: Array<string>
      }
    }
  }
}

export interface ISettingsCache {
  values: ISettings,
  setSettings: (value: React.SetStateAction<ISettings>) => void,
}

// export enum ChartType {
//   BAR_YIELD_FORECAST_NATIONAL = "BAR_YIELD_FORECAST_NATIONAL",
//   BAR_YIELD_FORECAST_STATE = "BAR_YIELD_FORECAST_STATE",
//   SCATTER_YIELD_FORECAST = "SCATTER_YIELD_FORECAST",
//   CROP_HEALTH_INDEX = "CROP_HEALTH_INDEX",
// }

// LEVEL will be either "NATIONAL" or a cornbeltState name in all caps (i.e. ARKANSAS, ILLINOIS, SOUTH DAKOTA, etc.)
export interface IBarPlot {
  level: string,
  // REVIEW: Array of strings?
  regions: any,
  data: {
    bcg: {
      // REVIEW: Array of numbers?
      yield: any,
      production: any,
    },
    usda?: {
      yield: any,
      production: any,
    }
  }
}

export interface IScatterPlot {
  level: string,
  date: any,
  data: {
    bcg: {
      yield: any,
    },
    upper: {
      yield: any,
    },
    lower: {
      yield: any,
    },
    usda: {
      yield: any,
    },
  }
}

export interface IChiPlot {
  level: string,
  date: any,
  data: {
    median: {
      yield: any,
    },
    maximum: {
      yield: any,
    },
    minimum: {
      yield: any,
    },
    y2012: {
      yield: any,
    },
    y2018: {
      yield: any,
    },
    y2019: {
      yield: any,
    },
    y2020: {
      yield: any,
    },
    y2021: {
      yield: any,
    },
    y2022: {
      yield: any,
    },
    y2023: {
      yield: any,
    },
  }
}

export interface ICharts {
  barPlots: {
    corn: IBarPlot[],
    soybeans: IBarPlot[],
  },
  scatterPlots: {
    corn: IScatterPlot[]
    soybeans: IScatterPlot[]
  },
  chiPlots: {
    corn: {
      evi: IChiPlot[],
      precipitation: IChiPlot[],
      temperature: IChiPlot[],
    },
    soybeans: {
      evi: IChiPlot[],
      precipitation: IChiPlot[],
      temperature: IChiPlot[],
    }
  },
}

export interface IChartsCache {
  chartData: ICharts,
  setCharts: (value: React.SetStateAction<ICharts>) => void,
}

export interface IManageCache {
  counties: ICountyCache,
  states: IStateCache,
  metrics: IMetricsCache,
  settings: ISettingsCache,
  addresses: IAddressCache,
  charts: IChartsCache,
}

export enum viewType {
  DEFAULT_MAP_VIEW = "DEFAULT_MAP_VIEW",
  DASHBOARD_VIEW = "DASHBOARD_VIEW",
}

interface IParams {
  view: viewType,
}


export interface IConditionalFilter {
  type: layerType,
  sets: optionSet[],
}

export interface IManageFilters {
  filters: IConditionalFilter[],
  setFilters: (value: React.SetStateAction<IConditionalFilter[]>) => void,
}

export const CropAI = (params: IParams) => {

  const {view} = params

  const [mapView, setMapView] = useState(MAP_VIEW_CORN_BELT)

  const handleMapViewChange = (newMapView: IMapView) => {
    if (VERBOSE_DEBUG) console.log("handleMapViewChange()")
    setMapView({...newMapView})
  }

  const initialLayers: IStoryData = {
    panels: [
      globalAg,
      agInputs,
      railAndShipping,
      tradeflows,
      farmDemand,
      supplyChain,
      centralValley,
    ],
  }

  let conditionalFilters: IConditionalFilter[] = []
  initialLayers.panels.forEach(panel => {
    panel.layers.forEach(layer => {
      conditionalFilters.push({
        type: layer.type,
        sets: [],
      })
    })
  })

  const [mapStyle, setMapStyle] = useState<string>(INITIAL_MAP_STYLE);

  const [storyData, setStoryData] = useState<IStoryData>(initialLayers)

  const [filters, setFilters] = useState<IConditionalFilter[]>(conditionalFilters)

  const state: IManageState = {
    storyData: storyData,
    setStoryData: setStoryData,
  }

  const filter: IManageFilters = {
    filters: filters,
    setFilters: setFilters,
  }

  const handleFilterUpdate = (type: layerType, sets: optionSet[]) => {
    filter.setFilters(produce(draft => {
      const index = draft.findIndex(e => e.type === type)
      if (index > -1){
        draft[index].sets = sets
      }
    }))
  }

  // Refresh State by replacing a storyPanel's layers with update active status(es)
  const handleLayerChange = (panelName: StoryType, newLayers: IGeoLayer[]) => {


    // Find index of target panel
    const index = storyData.panels.findIndex(e => e.name === panelName)

    // If panel was found
    if (index !== -1){
      // Temp: newLayers is array of only the one layer to update
      const layerIndex = storyData.panels[index].layers.findIndex(e => e.type === newLayers[0].type)

      // Update layers of that panel with the newLayers
      setStoryData(produce(draft => {
        draft.panels[index].layers[layerIndex].active = !draft.panels[index].layers[layerIndex].active
      }))
    }else{
      console.log(`WARNING - handleLayerChange() could not find panel: ${panelName} in state!`)
    }
  }

  // Manually activate/deactivate layer
  const manualToggleLayer = (panelName: StoryType, layerName: layerType, activate: boolean) => {
    const panelIndex = storyData.panels.findIndex(e => e.name === panelName)
    if (panelIndex !== -1){
      const layerIndex = storyData.panels[panelIndex].layers.findIndex(e => e.type === layerName)
      if (layerIndex !== -1){
        // Set state of layer, one time also update mapView
        if (storyData.panels[panelIndex].layers[layerIndex].active !== activate){
          setStoryData(produce(draft => {
            draft.panels[panelIndex].layers[layerIndex].active = activate
          }))
          if (VERBOSE_DEBUG) console.log(`Manually set state for ${panelName}:${layerName} to ${activate}`)
        }
      }
    }
  }


  // TODO: Refactor as updateLayerSubFilter --> to update a single subfilter array
  // Refresh State by replacing a storyPanel's layers with update active status(es)
  // Defaults to updated filter.options, set updateActive to true to update activeValues

  useEffect(() => {
    if (VERBOSE_DEBUG) console.log(`> CropAi.tsx useEffect() storyData state was updated`)
    if (VERBOSE_DEBUG) console.log(storyData)
  }, [storyData]); // Only re-run the effect if count changes

  const handleMapStyleChange = (newStyle: string) => {
    setMapStyle(newStyle)
  }

  function updateLayerSubFilter (panelName: StoryType, layerName: layerType, filterName: fieldType, values: string[], setOptions: boolean = true) {
    state.setStoryData(produce<IStoryData>(draft => {
      const panelIndex = draft.panels.findIndex(e => e.name === panelName)
      if (panelIndex !== -1){
        const layerIndex = draft.panels[panelIndex].layers.findIndex(e => e.type === layerName)
        if (layerIndex !== -1){
          const subFilterIndex = draft.panels[panelIndex].layers[layerIndex].subFilters.findIndex(e => e.field === filterName)
          if (subFilterIndex !== -1){
            if (setOptions){
              draft.panels[panelIndex].layers[layerIndex].subFilters[subFilterIndex].options = values
            }else{
              draft.panels[panelIndex].layers[layerIndex].subFilters[subFilterIndex].activeValues = values
            }
            if (VERBOSE_DEBUG) console.log(`updateLayerSubFilter > panelName: ${panelName} layerName: ${layerName} filterName: ${filterName}`)
        }
      }
    }
  }))
  }

  // const [countyData, setCountyData] = useState<IShapeData>({shapes: [], temperature: [], precipitation: [], irrigation: []})

  // Initialize state hooks for cache data
  const [countyData, setCountyData] = useState<IShapeData>({features: []})
  const [stateData, setStateData] = useState<IShapeData>({features: []})
  const [nationalData, setNationalData] = useState<INationalMetrics>({corn: [], soybeans: []})
  const initialSettings = {
    dashboard:{
      forecastSummary:{
        addHeader: true,
        corn: {
          headerText: "Corn Forecast Summary",
          lines: [
            "This forecast is the product of a joint initiative between BCG X and BCG's Industrial Goods Practice Area. \n ",
            "\n The information contained in this dashboard is not intended as, and shall not be understood or construed as, financial advice."
          ]
        },
        soybeans: {
          headerText: "Soybean Forecast Summary",
          lines: [
            "This forecast is the product of a joint initiative between BCG X and BCG's Industrial Goods Practice Area. \n ",
            "\n The information contained in this dashboard is not intended as, and shall not be understood or construed as, financial advice."
          ]
        },
      }
    }
  }
  const [addressArray, setAddressArray] = useState<IAddressPoint[]>([])
  const [settingsData, setSettings] = useState<ISettings>(initialSettings)
  const initialCharts: ICharts = {
    barPlots: {
      corn: [],
      soybeans: [],
    },
    scatterPlots: {
      corn: [],
      soybeans: [],
    },
    chiPlots: {
      corn: {
        evi: [],
        temperature: [],
        precipitation: [],
      },
      soybeans: {
        evi: [],
        temperature: [],
        precipitation: [],
      }
    },
  }
  const [chartData, setCharts] = useState<ICharts>(initialCharts)


  // Initialize cache
  const cache: IManageCache = {
    counties: {
      data: countyData,
      setData: setCountyData,
    },
    states: {
      data: stateData,
      setData: setStateData,
    },
    metrics: {
      nationalData: nationalData,
      setNationalData: setNationalData,
    },
    settings:{
      values: settingsData,
      setSettings: setSettings,
    },
    addresses:{
      layerActive: true,
      addressArray: addressArray,
      setAddressArray: setAddressArray,
    },
    charts: {
      chartData: chartData,
      setCharts: setCharts,
    },
  }

  // Populate cache with data
  useEffect(() => {
    if (VERBOSE_DEBUG) console.log("Fetching initial cache data...")
    fetchCountyData(cache)
    fetchStateData(cache)
    fetchNationalMetrics(cache)
    fetchSettings(cache)

    // National Bar Chart (Cornbelt States)
    fetchBarPlotData(cache, "corn", "NATIONAL")
    fetchBarPlotData(cache, "soybeans", "NATIONAL")

    // Yield Forecast Chart
    fetchScatterPlotData(cache, "corn", "NATIONAL")
    fetchScatterPlotData(cache, "soybeans", "NATIONAL")

    // Crop Health Index Chart
    fetchChiPlotData(cache, "corn", "evi", "NATIONAL")
    fetchChiPlotData(cache, "corn", "temperature", "NATIONAL")
    fetchChiPlotData(cache, "corn", "precipitation", "NATIONAL")
    fetchChiPlotData(cache, "soybeans", "evi", "NATIONAL")
    fetchChiPlotData(cache, "soybeans", "temperature", "NATIONAL")
    fetchChiPlotData(cache, "soybeans", "precipitation", "NATIONAL")

    // cornbeltStates.forEach((state: string) => {
    //   fetchBarPlotData(cache, "corn", state.toUpperCase())
    //   fetchBarPlotData(cache, "soybeans", state.toUpperCase())
    // })
    if (VERBOSE_DEBUG) console.log(cache)

    // Ignore warning because cache does not need to be recomputed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO: Parse subfilters by data pull
  useEffect(() => {
    (async () => {
      const response = await axios.get('https://storage.googleapis.com/cropai-mappable/GlobalMines.geojson')
      if (response.data && response.data["features"]){
        const features = response.data["features"]

        let companies:string[] = []
        let countries:string[] = []

        for (let d of features){
          if ("properties" in d){
            if ("Company" in d.properties){
              let company = d.properties["Company"]
              if (companies.indexOf(company) === -1){
                companies.push(company)
              }
            }
            if ("Country" in d.properties){
              let country = d.properties["Country"]
              if (countries.indexOf(country) === -1){
                countries.push(country)
              }
            }
          }
        }
        updateLayerSubFilter(StoryType.AG_INPUTS, layerType.POTASH_MINES, fieldType.POTASH_MINES_COMPANY, companies, true)
        updateLayerSubFilter(StoryType.AG_INPUTS, layerType.POTASH_MINES, fieldType.POTASH_MINES_COUNTRY, countries, true)
      }

    })();
    // Ignore warning because mines does not need to be recomputed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Memo to check if a story panel is open
  const isPanelOpen = useMemo(() => {
    for (let panel of state.storyData.panels){
      if (panel.storyOpen){
        return true
      }
    }
    return false

  }, [state.storyData.panels])

  const regularStyle = {
    position: "absolute",
    maxWidth: "200px",
    minWidth: "160px",
    zIndex: "1200",
    top: "10px",
    right: "10px",
  }

  const chartOffset = {
    position: "absolute",
    maxWidth: "200px",
    minWidth: "160px",
    zIndex: "1200",
    top: "10px",
    right: "340px",
  }

  const isYieldForecastChartActive = () => {
    const panel = state.storyData.panels.find(e => e.name === StoryType.FARM_DEMAND)
    if (panel){
      const yfLayer = panel.layers.find(e => e.type === layerType.YIELD_FORECAST)
      return (yfLayer?.active && panel.chartsActive) || false
    }else{
      return false
    }
  }

  // Full screen map view with story panels
  const getFullMapView = () => {
    return (
      <S.MapWindow >
      <Box sx={{
        position: "absolute",
        zIndex: "2500",
        top: "10px",
        right: isYieldForecastChartActive() ? "340px" : "10px",
        display: "flex",
        flexDirections: "row",
        columnGap: "12px",
      }}>
        <Box sx={{
          height: "32px",
          display: "flex",
        }}>
          <MapReset
            state={state}
          />
        </Box>
        <Box className="Address-Search-Fullmap" sx={{
            color: "white",
            borderRadius: "4px",
            zIndex: "2500",
            maxWidth:"220px",
            // paddingRight: "8px",
        }}>
            { true &&
            <AddressSearch
              cache={cache}
              handleMapViewChange={handleMapViewChange}
              />
            }
        </Box>
        <MapSelector
          state={state}
          style={mapStyle}
          handleChange={handleMapStyleChange}
        />
      </Box>
      <StoryPanel
        state={state}
        storyData={storyData}
        mapView={mapView}
        handleLayerChange={handleLayerChange}
        handleMapViewChange={handleMapViewChange}
      />
      <RenderMap
        state={state}
        cache={cache}
        mapView={mapView}
        setMapView={setMapView}
        mapStyle={mapStyle}
        handleFilterUpdate={handleFilterUpdate}
        fullscreen={true}
      />
      <Box
        component="img"
        sx={{
          backgroundColor: "transparent",
          height: 120,
          width: 281,
          position: "absolute",
          bottom: 10,
          right: 0,
        }}
        alt="CEI Logo"
        src="https://storage.googleapis.com/cropai-mappable/RITM2556204_EarthIntelCenters_Logo_white.png"
      />
    </S.MapWindow>
    )
  }

  const getDashboardView = () => {
    const renderMapParams: IRenderMapParams = {
      state: state,
      cache: cache,
      mapView: mapView,
      setMapView: setMapView,
      mapStyle: "mapbox://styles/mapbox/light-v9",
      handleFilterUpdate: handleFilterUpdate,
      fullscreen: false,
    }
    const panel = state.storyData.panels.find(e => e.name === StoryType.FARM_DEMAND)
    const layer = panel?.layers.find(e => e.type === layerType.YIELD_FORECAST)
    if (panel && layer){
      // Set yieldforecast layer activate
      manualToggleLayer(StoryType.FARM_DEMAND, layerType.YIELD_FORECAST, true)
      return (
        <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax={"calc(100vh)"}
          // renderTrackHorizontal={props => <div {...props} className="track-horizontal-2" style={{display:"none"}}/>}
          // renderThumbHorizontal={props => <div {...props} className="thumb-horizontal-2" style={{display:"none"}}/>}
          hideTracksWhenNotNeeded={true}
        >
          <Dashboard
            mapParams={renderMapParams}
            state={state}
            panel={panel}
            layer={layer}
            cache={cache}
            handleMapViewChange={handleMapViewChange} manualToggleLayer={function (panelName: StoryType, layerName: layerType, activate: boolean): void {
              throw new Error("Function not implemented.");
            } }          />
        </Scrollbars>
      )
    }else{
      console.log("ERROR: Unable to generate dashboard - Panel or Layer was not initialized.")
      return (
        <></>
      )
    }
  }

  const renderView = (view: viewType) => {
    switch (view) {
      case viewType.DASHBOARD_VIEW:
        return (getDashboardView())
      default:
        return (getFullMapView())
    }
  }

  return(
    <Box>
      {renderView(view)}
    </Box>
  );
}
