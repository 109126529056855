import Box from '@mui/material/Box';
import { DialogTitle, IconButton, Popper } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { GenerateChart, chartType } from './GenerateChart';
import { IStoryData, StoryType, fieldType, layerType } from '../../store/StoryApi';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useState } from 'react';
import { BACKEND_HOST } from '../../store/config';


function PaperComponent(props: PaperProps) {
  // const nodeRef = useRef(null);
  return (
    <Draggable
      // nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
      {/* <div ref={nodeRef}>Example Target</div> */}
    </Draggable>
  );
}

interface ICountyChartPanelParams {
  storyData: IStoryData,
  FIPS: string,
  panelOpen: boolean,
  handlePanelClose: () => void,
}


export const CountyChartPanel = (params: ICountyChartPanelParams) =>{

  const {storyData, FIPS, panelOpen, handlePanelClose} = params;

  const subFilters = storyData.panels.find(e => e.name === StoryType.FARM_DEMAND)?.layers.find(e => e.type === layerType.YIELD_FORECAST)?.subFilters;
  const crop = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_CROP)?.activeValues[0]?.toLowerCase();
  // const model = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_MODEL)?.activeValues[0]?.toLowerCase();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  let cropQuery = 'corn'
  if (crop){
    cropQuery = crop
  }

  return (
    <>
    { (FIPS !== "") &&

      <Popper
      open={true}
      anchorEl={anchorEl}
      // sx={{position: 'absolute', top: '60px', left: '60px'}}
      >
    <Box className="CountyChart" sx={{
      position: "absolute",
      top: "calc(200px)",
      left: "calc(50vw)",
    }}>
      <PaperComponent style={{
        backgroundColor: '#ffffff',
        opacity: '100%',
        boxShadow: 'none',
        borderRadius: "10px",
        padding: '10px',
        width: '720px',
        // minHeight: '400px',
        // height: '95vh',
        height: '540px',
        // zIndex: "2000",
        display: "flex",
        flexDirection: "column",
      }}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <IconButton
            onClick={handlePanelClose}
          >
            <ArrowBackIosRoundedIcon sx={{color: "#667080"}}/>
          </IconButton>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" >
            {`County: ${FIPS}`}
          </DialogTitle>
        </Box>
        <Box className="CountyChart" sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // height: "50%"
          }}>
          {/* <Typography variant="subtitle1">NATIONAL YIELD FORECAST</Typography> */}
          <GenerateChart
            host={BACKEND_HOST}
            path={`/get_county_evi_plot/${cropQuery}/${FIPS}`}
            level={"COUNTY"}
            type={chartType.PANEL}
            />
        </Box>
      </PaperComponent>
    </Box>
    </Popper>
  }
  </>
  );
}
