import { Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IStoryPanel, ISubFilter, StoryType, layerType } from '../../store/StoryApi';
import { LegendAccordion } from '../LegendAccordion/LegendAccordion';
import { useState } from 'react';
import { IManageState } from '../CropAI/CropAI';
import { PanelSubFilterMulti } from '../PanelSubFilter/PanelSubFilterMulti';
import { PanelSubFilterMultiVirtual } from '../PanelSubFilter/PanelSubFilterMultiVirtual';
import { VirtualAutoMultiFilter } from '../PanelSubFilter/VirtualAutoMultiFilter';

interface IFilterParams {
  state: IManageState,
  panel: IStoryPanel,
  layerCheckbox?: JSX.Element,
}

export const FilterPotashMines = (params: IFilterParams) =>{
  const {state, panel, layerCheckbox} = params
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const toggleFilters = () => {
    console.log("Toggled Filters")
    setFiltersExpanded(!filtersExpanded)
  }

  const layer = panel.layers.find(e => e.type === layerType.POTASH_MINES)

  // If the layerCheckbox or layer itself could not be found in panel, do not render
  if (!layerCheckbox || !layer){
    return (<></>)
  }else{
    return (
    <>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
      }}>
        {layerCheckbox}
        <IconButton
          sx={{padding: "0px"}}
          onClick={toggleFilters}
        >
        {
          (filtersExpanded ? <ExpandLessIcon sx={{color: "#667080"}}/> : <ExpandMoreIcon sx={{color: "#667080"}}/>)
        }
        </IconButton>
      </Box>
      <Box className="SubFilter Box" sx ={{
        display: (filtersExpanded ? "block" : "none"),
      }}>
        {
        layer.subFilters?.map((subFilter) => (
          <VirtualAutoMultiFilter
          key={subFilter.field}
          panel={panel}
          layerName={layer.type}
          subFilter={subFilter}
          state={state}
          />
          ))
        }
        {/* {
        layer.subFilters?.map((subFilter) => (
          <PanelSubFilterMultiVirtual
          key={subFilter.field}
          panel={panel}
          layerName={layer.type}
          subFilter={subFilter}
          state={state}
          />
          ))
        } */}
      </Box>
    </>
    );
  }
}
