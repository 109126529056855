import Plot from "react-plotly.js";
import { IChiPlot, IManageCache } from "../CropAI/CropAI";
import { ChartSkeleton } from "./ChartSkeleton";

export interface IChiChartParams {
  cache: IManageCache,
  crop: string,
  healthIndex: string
}

// TODO: Move this interface to explicitly define in bar chart interface from cache

export const NationalChiChart = (params: IChiChartParams) => {

  const {cache, crop, healthIndex} = (params)

  let chart: IChiPlot | undefined;
  let yAxisTitle = ""

  // Fetch data
  if (crop === "corn"){
    if (healthIndex.toUpperCase() === "EVI"){
      chart = cache.charts.chartData.chiPlots.corn.evi.find(e => e.level === "NATIONAL")
      yAxisTitle = "Enhanced Vegetation Index"
    }else if (healthIndex.toUpperCase() === "TEMPERATURE"){
      chart = cache.charts.chartData.chiPlots.corn.temperature.find(e => e.level === "NATIONAL")
      yAxisTitle = "Daytime LST (Celsius)"
    }else if (healthIndex.toUpperCase() === "PRECIPITATION"){
      chart = cache.charts.chartData.chiPlots.corn.precipitation.find(e => e.level === "NATIONAL")
      yAxisTitle = "Accumulated Precipitation (Inches)"
    }else {
      console.log("ERROR: Invalid CropHealth Index")
    }
  }else if (crop === "soybeans") {
    if (healthIndex.toUpperCase() === "EVI"){
      chart = cache.charts.chartData.chiPlots.soybeans.evi.find(e => e.level === "NATIONAL")
      yAxisTitle = "Enhanced Vegetation Index"
    }else if (healthIndex.toUpperCase() === "TEMPERATURE"){
      chart = cache.charts.chartData.chiPlots.soybeans.temperature.find(e => e.level === "NATIONAL")
      yAxisTitle = "Daytime LST (Celsius)"
    }else if (healthIndex.toUpperCase() === "PRECIPITATION"){
      chart = cache.charts.chartData.chiPlots.soybeans.precipitation.find(e => e.level === "NATIONAL")
      yAxisTitle = "Accumulated Precipitation (Inches)"
    }else {
      console.log("ERROR: Invalid CropHealth Index")
    }
  }

  // If chart is undefined, either plot does not exist in cache or invalid crop was supplied

  if (chart){
    return (
      <>
        <Plot
          data={[
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.median.yield,
              name: 'Median',
              line: {
                color: "rgb(161, 191, 239)",
                width: 2
              }
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.maximum.yield,
              name: '(2012 - 2022)',
              marker: {
                color: "#bab6b6"
              },
              line: {
                width: 0
              },
              showlegend: false
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.minimum.yield,
              name: '(2012 - 2022)',
              marker: {
                color: "#bab6b6"
              },
              line: {
                width: 0
              },
              fillcolor: "rgba(68, 68, 68, 0.3)",
              fill: 'tonexty',
              showlegend: false
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2012.yield,
              name: '2012',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2018.yield,
              name: '2018',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2019.yield,
              name: '2019',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2020.yield,
              name: '2020',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2021.yield,
              name: '2021',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2022.yield,
              name: '2022',
              line: {
                width: 2
              },
              visible: 'legendonly'
            },
            {
              type: 'scatter',
              mode: 'lines',
              x: chart.date,
              y: chart.data.y2023.yield,
              name: '2023',
              line: {
                color: "rgb(31, 119, 180)",
                width: 2.5
              },
            },
          ]}
          config={{displayModeBar:false, responsive: true}}
          layout={{
            autosize: true,
            // width: 400,
            height: 400,
            margin: {t: 40, l: 50, r: 0},
            xaxis: {
              showgrid: false,
              showline: true,
              linewidth: 0.5,
              linecolor: 'black',
              tickformat: '%b-%d',
              tickfont: {
                family: 'Tahoma',
                size: 12
              },
              zeroline: false
            },
            yaxis: {
              title: yAxisTitle,
              showgrid: false,
              showline: true,
              linewidth: 0.5,
              linecolor: 'black',
              tickfont: {
                family: 'Tahoma',
                size: 12
              },
              zeroline: false

            },
            legend: {
              x: 0.5,
              y: -0.09,
              xanchor: "center",
              orientation: 'h',
              bgcolor: "rgb(255,255,255,255)"
            }
          }}
          useResizeHandler={true}
          style={{width: '100%'}}
        />
      </>
    );
  }else{
    console.log("NO DATA: Chart does not exist and could not be rendered.")
    return (
      <ChartSkeleton/>
    )
  }
}
