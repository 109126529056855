/// app.js
import DeckGL from '@deck.gl/react';
import {GeoJsonLayer} from '@deck.gl/layers';
import {DataFilterExtension} from '@deck.gl/extensions/typed';
// @ts-ignore
import { MapView} from '@deck.gl/core';
import StaticMap, { Map } from 'react-map-gl';  // "^7.0.21"
import axios from "axios";
import 'maplibre-gl/dist/maplibre-gl.css';

import { PickingInfo } from "@deck.gl/core/typed";
import { TooltipContent } from "@deck.gl/core/typed/lib/tooltip";

import * as d3 from "d3";
import { HSLToRGB, convertRGBtoArray } from "../../utils/color";
import {CROPTYPE_LOOKUP} from "../../utils/croptype_lookup";
import { IGeoLayer, IStoryData, ISubFilter, IWeatherData, StoryType, layerType, optionSet } from '../../store/StoryApi';
import { CoordinatePair, IAddressPoint, IMapView, INITIAL_MAP_VIEW, STATE_FIPS_MAPPING } from "../../store/MapApi";
import { hexToRgb } from "@mui/material";
import { Box } from "@mui/material";
import { CoordinateBox } from "../CoordinateBox/CoordinateBox";
import { useEffect, useRef, useState } from 'react';
import { fieldType } from '../../store/StoryApi';
import { CountyChartPanel } from '../YieldForecast/CountyChart';
import { generateGlobalCropland, generatePopulationLayer } from './GlobalAgLayers';
import { generateNitrogenApplication, generateNitrogenFacilities, generatePhosphateDeposits, generatePhosphateMines, generatePhosphorousApplication, generatePotashDeposits, generatePotashMines } from './AgInputsLayers';
import { generatePortsLayer, generatePotashShippingPoints, generateRail } from './RailAndShippingLayers';
import { generateShippingRoutesLine } from './TradeflowsLayers';
import { generateAgRetailers, generateEvi_Iowa, generateIowaByKeyword, generateIowaGeo, generateIowaHex, generateIowaPolygons, generateSam_Iowa } from './FarmDemand';
import { IManageCache, IManageState } from '../CropAI/CropAI';
import { BACKEND_HOST } from '../../store/config';
import mapboxgl from 'mapbox-gl';
import { isModuleDeclaration } from 'typescript';
import { generateAddressLayer } from './AddressSearch';
import { FlyToInterpolator } from "@deck.gl/core/typed";

// Set your mapbox access token here
const MAPBOX_TOKEN = "pk.eyJ1IjoibGlhbWNvbm5lbGwiLCJhIjoiY2xoN3ozdmN6MDNsczNybXN3eG9jeWJvYSJ9.txqA20qxGQdHs9ZAN00iKw";

const scaleYieldActual = d3.scaleSequential(d3.interpolateYlGn).domain([0,200]);
// Full domain -1, 1
const scaleYieldDelta = d3.scaleSequential(d3.interpolateRdYlBu).domain([-.3, .3]);

// Temp range is from [5, 40] --> fit to [10, 30] for more distinct edges, inverted to go from (Colder)Blue-->Red rather than (Colder)Red-->Blue
const scaleTemperature = d3.scaleSequential(d3.interpolateRdYlBu).domain([-30, -10])
const scalePrecipitation = d3.scaleSequential(d3.interpolateRdBu).domain([-2.0, 2.0])
const scaleIrrigation = d3.scaleSequential(d3.interpolateSpectral).domain([-5, 5])

export const generateCountyWeather = (countyData: any, scale: d3.ScaleSequential<string, never>, layerName: layerType, level:string) => {

  // If level is a state name set STATE_FILTER to the state to filter by
  const STATE_FILTER = STATE_FIPS_MAPPING.find(e => e.name === level.toUpperCase())?.name

  // Gets negated for temperature
  let scaleMultiplier = 1
  const getWeatherValue = (dProps: any) => {
    switch (layerName) {
      case layerType.FD_TEMPERATURE:
        if ("temperature" in dProps){
          scaleMultiplier = -1
          return dProps.temperature
        }
        return null
      case layerType.FD_PRECIPITATION:
        if ("precipitation" in dProps){
          return dProps.precipitation
        }
        return null
      case layerType.FD_DROUGHT:
        if ("drought" in dProps){
          return dProps.drought
        }
        return null
      default:
        break;
    }
  }

  const layer = new GeoJsonLayer({
    id: 'yf-counties-conditions',
    data: countyData,
    pointRadiusMinPixels: 5,
    getFillColor: (d: any) => {
      // Get weather value from d.properties.FIPS
      // const value = weatherData.find(e => e.fips === d.properties.FIPS)?.value
      const value = getWeatherValue(d.properties)
      if (value){
        const rgbString = scale(scaleMultiplier * value)
        return convertRGBtoArray(rgbString)
      }else{
        console.log(`Unknown ${layerName} value for county with fips: ${d.properties.FIPS}`)
        return [0, 0, 0, 1]
      }
    },
    getFilterValue: (d: any) => {
      // Filter out if county not in stateFilter
      if (STATE_FILTER){
        if (d.properties["state_name"].toUpperCase() !== STATE_FILTER.toUpperCase()){
          return 0
        }
      }
      // Filter if no data
      const value = getWeatherValue(d.properties)
      if (value && value !== ""){
        return 1
      }else{
        return 0
      }
    },
    updateTriggers: {
      getFillColor: [scale, layerName],
      getFilterValue: [scale, layerName, level],
    },
    filterRange: [1,1],  // 12:00 - 13:00
    extensions: [new DataFilterExtension({filterSize: 1})],
    opacity: .5,
    pickable: true
  })
  return layer
}

const generateYieldForecast = (countyData: any, crop: string, model: string, level:string) => {

  // If level is a state name set STATE_FILTER to the state to filter by
  const STATE_FILTER = STATE_FIPS_MAPPING.find(e => e.name === level.toUpperCase())?.name

  return new GeoJsonLayer({
    id: 'yf-layer',
    data: countyData,
    getFillColor: (d: any) => {
        // Yield: 'actual' == Forecast and 'delta' === Delta (5yrs)
        if (model === 'actual'){
          const multiplier = (crop === 'corn' ? 1 : 3)
          let rgbString = scaleYieldActual(d.properties.yield[crop][model] * multiplier)
          if (rgbString){
            return (convertRGBtoArray(rgbString))
          }else{
            return [255, 255, 255, 0]
          }
        }else{
          let rgbString = scaleYieldDelta(d.properties.yield[crop][model] *1.2)
          if (rgbString){
            return (convertRGBtoArray(rgbString))
          }else{
            return [255, 255, 255, 0]
          }
        }
    },
    getFilterValue: (d: any) => {

      // Filter out if county not in stateFilter
      if (STATE_FILTER){
        if (d.properties["state_name"].toUpperCase() !== STATE_FILTER.toUpperCase()){
          return 0
        }
      }
      // Filter if no yield data
      if (d.properties.yield[crop][model] === ""){
        // console.log(`${crop}: ${model}: ${level} => had no yield. Skipped render`)
        return 0
      }else{
        return 1
      }
    },
    updateTriggers: {
      getFilterValue: [crop, model, level],
      getFillColor: [crop, model],
    },
    filterRange: [1,1],  // 12:00 - 13:00
    extensions: [new DataFilterExtension({filterSize: 1})],
    opacity: .5,
    pickable: true,
  })
}

const generateStateYieldForecast = (countyData: any, crop: string, model: string) => {

  return new GeoJsonLayer({
    id: 'yf-states',
    data: countyData,
    getFillColor: (d: any) => {
        if (d.properties.yield[crop][model] === ""){
          console.log(d.properties)
        }
        if (model === 'actual'){
          const multiplier = (crop === 'corn' ? 1 : 3)
          let rgbString = scaleYieldActual(d.properties.yield[crop][model] * multiplier)
          if (rgbString){
            return (convertRGBtoArray(rgbString))
          }else{
            return [255, 255, 255, 0]
          }
        }else{
          let rgbString = scaleYieldDelta(d.properties.yield[crop][model] *1.2)
          if (rgbString){
            return (convertRGBtoArray(rgbString))
          }else{
            return [255, 255, 255, 0]
          }
        }
    },
    getFilterValue: (d: any) => {
      if (d.properties.yield[crop][model] === ""){
        return 0
      }else{
        return 1
      }
    },
    updateTriggers: {
      getFilterValue: [crop, model],
      getFillColor: [crop, model],
      // getFillColor: [activeFilters],
      // getLineColor: [activeFilters],
    },
    filterRange: [1,1],  // 12:00 - 13:00
    extensions: [new DataFilterExtension({filterSize: 1})],
    opacity: .5,
    pickable: true,
  })
}

export interface IRenderMapParams {
  state: IManageState,
  cache: IManageCache,
  mapView: IMapView,
  setMapView: (value: IMapView) => void,
  mapStyle: string,
  handleFilterUpdate: (type: layerType, sets: optionSet[]) => void,
  fullscreen: boolean,
}

export const RenderMap = (props: IRenderMapParams) => {

  const {state, cache, mapView, setMapView, mapStyle, handleFilterUpdate, fullscreen } = props

  const storyData = state.storyData

  const [showCountyChart, setShowCountyChart] = useState(false);
  const [countyFIPS, setCountyFIPS] = useState("");

  const yfLayerActive = storyData.panels.find(e => e.name === StoryType.FARM_DEMAND)?.layers.find(e => e.type === layerType.YIELD_FORECAST)?.active

  const subFilters = storyData.panels.find(e => e.name === StoryType.FARM_DEMAND)?.layers.find(e => e.type === layerType.YIELD_FORECAST)?.subFilters;
  const crop = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_CROP)?.activeValues[0]?.toLowerCase();
  const model = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_MODEL)?.activeValues[0]?.toLowerCase();
  const level = subFilters?.find(e => e.field === fieldType.YIELD_FORECAST_LEVEL)?.activeValues[0]?.toLowerCase();

  // On initial load, if fullscreen map is set then update to global view
  useEffect(() => {
    if (fullscreen){
      console.log("set initial map ")
      setMapView(INITIAL_MAP_VIEW)
    }
  }, [fullscreen])

  const getDeckLayers = (storyData: IStoryData) => {
    var layers = []

    // Extract all active layers from all panels of storyData
    for (const panel of storyData.panels){
      for (const layer of panel.layers){
        if (layer.active){
          switch (layer.type){
            case layerType.AG_RETAILERS:
              layers.push(generateAgRetailers())
              break;
            case layerType.YIELD_FORECAST:
              if (crop && model && level){
                const conditions = ["FD_TEMPERATURE", "FD_PRECIPITATION", "FD_DROUGHT"]
                if (conditions.includes(model.toUpperCase()) ){
                  // Weather layer
                  switch (model.toUpperCase()) {
                    case conditions[0]:
                      layers.push(generateCountyWeather(cache.counties.data.features, scaleTemperature, layerType.FD_TEMPERATURE, level))
                      break;
                    case conditions[1]:
                      layers.push(generateCountyWeather(cache.counties.data.features, scalePrecipitation, layerType.FD_PRECIPITATION, level))
                      break;
                    case conditions[2]:
                      layers.push(generateCountyWeather(cache.counties.data.features, scaleIrrigation, layerType.FD_DROUGHT, level))
                      break;
                    default:
                      break;
                  }
                }else{
                  if (level === 'state'){
                    layers.push(generateStateYieldForecast(cache.states.data.features, crop, model))
                  }else{
                    // Generate by counties
                    layers.push(generateYieldForecast(cache.counties.data.features, crop, model, level))
                  }
                }
              }else{
                console.log(`invalid of either crop:${crop} model:${model} or level: ${level}`)
              }
              break;
            case layerType.FD_TEMPERATURE:
              layers.push(generateCountyWeather(cache.counties.data.features, scaleTemperature, layer.type, "County"))
              break;
            case layerType.FD_PRECIPITATION:
              layers.push(generateCountyWeather(cache.counties.data.features, scalePrecipitation, layer.type, "County"))
              break;
            case layerType.FD_DROUGHT:
              layers.push(generateCountyWeather(cache.counties.data.features, scaleIrrigation, layer.type, "County"))
              break;
            case layerType.GLOBAL_CROP:
              layers.push(generateGlobalCropland())
              break;
            case layerType.GLOBAL_POP:
              layers.push(generatePopulationLayer())
              break;
            case layerType.GLOBAL_RAIL:
              layers.push(generateRail())
              break;
            case layerType.PORTS:
              layers.push(generatePortsLayer())
              break;
            case layerType.POTASH_DEPOSITS:
              layers.push(generatePotashDeposits())
              break;
            case layerType.POTASH_MINES:
              layers.push(generatePotashMines(state, panel, layer, handleFilterUpdate))
              break;
            case layerType.POTASH_SHIPPING_ROUTES:
              layers.push(generatePotashShippingPoints())
              break;
            case layerType.POTASH_TRADEFLOWS:
              layers.push(generateShippingRoutesLine())
              break;
            case layerType.PHOSPHATE_DEPOSITS:
              layers.push(generatePhosphateDeposits())
              break;
            case layerType.PHOSPHATE_MINES:
              layers.push(generatePhosphateMines())
              break;
            case layerType.NITROGEN_MANUFACTURING:
              layers.push(generateNitrogenFacilities())
              break;
            case layerType.PHOSPHOROUS_APPLICATION:
              layers.push(generatePhosphorousApplication())
              break;
            case layerType.NITROGEN_APPLICATION:
              layers.push(generateNitrogenApplication())
              break;
            case layerType.CALI_EVI:
              // layers.push(generateIowaGeo)
              layers.push(generateEvi_Iowa())
              break;
            case layerType.CALI_SAM:
              layers.push(generateIowaPolygons())
              // Only render Hex when Polygons are not being shown
              // if (mapView.zoom < 7.50){
                layers.push(generateIowaByKeyword("wolf"))
              // }
              break;
            default:
              // Do nothing
              break;
          }
        }
      }
    }
    if (cache.addresses.layerActive){
      layers.push(generateAddressLayer(cache.addresses.addressArray))
    }
    return layers
  }

  const handleCountyClick = (FIPS: string) => {
    setCountyFIPS(FIPS)
    setShowCountyChart(true)
  }

  const handleCountyClose = () => {
    setShowCountyChart(false);
  };

  function handleLayerClick(info: PickingInfo) {
    const obj = info.object
    const layer = info.layer?.id

    if ((layer === 'yf-layer') && obj){
      if (obj.properties.FIPS){
        const FIPS = obj.properties.FIPS
        handleCountyClick(FIPS)
        console.log("clicked:")
        console.log(obj)
      }
    }else if ((layer === "address-pins") && obj) {
      console.log("clicked:")
      console.log(obj)
      if (obj.geometry){
        const view: IMapView = {
          longitude: obj.geometry.longitude,
          latitude: obj.geometry.latitude,
          zoom: 8,
          pitch: 0,
          bearing: 0,
          transitionDuration: 500,
          transitionInterpolator: new FlyToInterpolator(),
        }
        setMapView(view)
      }
    }
  }

  const renderFullscreenMap = () => {
    let isHovering = false;

    return (
      <Box>
        <DeckGL
          initialViewState={mapView}
          onViewStateChange={(e:any) => setMapView(e.viewState)}
          controller={true}
          layers={getDeckLayers(storyData)}
          getTooltip={(info: PickingInfo) => getTooltip(info, crop, model)}
          onClick={(info: PickingInfo) => handleLayerClick(info)}
          onHover={({object}:any) => (isHovering = Boolean(object))}
          getCursor={({isDragging}:any) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab'))}
          // sx={{zIndex: 1200}}
          style={{zIndex: "revert", overflow: "hidden"}}
        >
          <MapView id="map" width="100%" controller={true}>
            <StaticMap
                mapboxAccessToken={MAPBOX_TOKEN}
                mapStyle={mapStyle}
            />
          </MapView>
        </DeckGL>
        <CoordinateBox
          viewState={mapView}
        />
        { (showCountyChart) &&
          <CountyChartPanel
          storyData={storyData}
          FIPS = {countyFIPS}
          panelOpen= {showCountyChart}
          handlePanelClose = {handleCountyClose}
        />
        }
      </Box>
    )
  }

  const renderRelativeMap = () => {
    // const attr = new mapboxgl.AttributionControl(options:{compact: true})
    let isHovering = false;
    return (
      <Box className="RenderMap-Relative"
        sx={{
          width: "inherit", height: "inherit", position:"relative",
          border: "#b5b5b5",
          borderStyle: "solid",
          borderRadius: "4px",
          borderWidth: "2px",
        }}
      >
      <DeckGL
        initialViewState={mapView}
        onViewStateChange={(e:any) => setMapView(e.viewState)}
        controller={true}
        layers={getDeckLayers(storyData)}
        getTooltip={(info: PickingInfo) => getTooltip(info, crop, model)}
        onClick={(info: PickingInfo) => handleLayerClick(info)}
        onHover={({object}:any) => (isHovering = Boolean(object))}
        getCursor={({isDragging}:any) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab'))}
        // onClick={(info: PickingInfo) => test(info)}
        // sx={{zIndex: 1200, width: "inherit", height: "inherit"}}
        style={{zIndex:"revert", width: "inherit", height: "inherit"}}
      >
        <MapView id="map" controller={true}>
          <Map
            mapboxAccessToken={MAPBOX_TOKEN}
            mapStyle={mapStyle}
            attributionControl={false}
          >
            {/* <AttributionControl
            compact={true}
            /> */}
            </Map>
          {/* <StaticMap
            mapboxAccessToken={MAPBOX_TOKEN}
            mapStyle={mapStyle}
            attributionControl={false}

            // style={{width: "50px", height: "400px"}}
          /> */}
        </MapView>
      </DeckGL>
      {/* <CoordinateBox
        viewState={mapView}
      /> */}
      {/* { (showCountyChart) &&
        <CountyChartPanel
        storyData={storyData}
        FIPS = {countyFIPS}
        panelOpen= {showCountyChart}
        handlePanelClose = {handleCountyClose}
      />
      } */}
    </Box>
    )
  }

  return (
    <Box sx={{width: "inherit", height: "inherit"}}>
      {fullscreen ? renderFullscreenMap() : renderRelativeMap()}
    </Box>
  );
}

function getTooltip(info: PickingInfo, crop?:string, model?:string): TooltipContent {

  // console.log('info', info);
  // console.log('crop', crop);
  // console.log('model', model);

  let layer_id = info.layer?.id || "UNDEFINED_LAYER"
  const obj = info.object

  interface IFarmHexPoint {
    properties: {
      Alfalfa: number,
      Barley: number,
      Barren: number,
      Canola: number,
      Clover_Wil: number,
      Corn: number,
      Crop: string,
      Dbl_Crop_S: number,
      Dbl_Crop_W: number,
      Dry_Beans: number,
      Falow_Idl: number,
      GRID_ID: string,
      Grassland_: number,
      Majority_l: number,
      Millet: number,
      Oats: number,
      Other_Hay_: number,
      Peas: number,
      Pop_or_Orn: number,
      Potatoes: number,
      Resolution: number,
      Rye: number,
      Sod_Grass_: number,
      Sorghum: number,
      Soybeans: number,
      Spring_Whe: number,
      Sweet_Corn: number,
      Triticale: number,
      Winter_Whe: number,
      layerName: string,
    }
  }
  interface ICountyDataPoint {
    properties: {
      FIPS: string,
      county_name: string,
      state_name: string,
      drought: number,
      precipitation: number,
      temperature: number,
      yield: {
        corn: {
          actual: number,
          delta: number,
        },
        soybeans: {
          actual: number,
          delta: number,
        }
      }
    }
  }

  interface IMinesDataPoint {
    properties: {
      Asset: string,
      Company: string,
      Country: string,
      Lat: string,
      Long: string,
      Product: string,
      State_Prov: string,
      Status: string,
      _22_ProdCa: number,
      _22_ProdVo: number,
    }
  }

  interface IDepositsDataPoint {
    properties: {
      Site_Name: string,
      Type: string,
      country: string,
      Deposit_Ty: string,
      commods: string,
      company: string,
    }
  }

  interface IShippingRouteDataPoint {
    properties: {
      Timestamp: string,
      ETA: string,
      MMSI: number,
      IMO: number,
      Vessel_Nam: string,
      Vessel_Typ: string,
      Origin__Co: string,
      Origin__Po: string,
      Destinatio: string,
      Destinati2: string,
      Flag: string,
      Commodity: string,
      Time_Spend:number,
      Load_Time_: number,
      Dead_Weigh: number,
      Load__mt_: number,
    }
  }

  interface ITradeflowsDataPoint {
    properties : {
      Origin__Co: string,
      Origin__Po: string,
      Destinatio: string,
      Destinati2: string,
      Tonnage__2: number,
      Tonnage__3: number,
      Tonnage__4: number,
      Tonnage__5: number,
      Tonnage__6: number,
      Tonnage__7: number,
      Tonnage__8: number,
      Tonnage__9: number,
      Tonnage_10: number,
      Tonnage_11: number,
      Tonnage_12: number,
    }
  }

  interface IAgRetailersDataPoint {
    properties: {
      list_name: string,
      City: string,
      State: string,
      Count_of_S: number,
    }
  }

  // TODO: THIS ENTIRE FUNCTIONS NEEDS TO BE REFACTORED

  let yfTable: string = ""
  let table: string = ""
  // let table: string = "<tr>" +
  //     "<th id='q'>" + "Key" + "</th>" +
  //     "<th id='o'>" + "Value" + "</th>" +
  //     "</tr>"

  // render yield forecast popup
  if (obj !== undefined && ['yf-layer', 'yf-counties-conditions'].includes(layer_id) && model && crop){
    let cdp: ICountyDataPoint = obj
    yfTable = "<tr>" +
    "<th id='q'>" + `${cdp.properties.county_name},` + "</th>" +
    "<th id='o'>" + `${STATE_FIPS_MAPPING.find(e => e.name === cdp.properties.state_name.toUpperCase())?.abbr ?? '--'}` + "</th>" +
    "</tr>"
    // yfTable += '<tr><td>' + "County" + '</td><td>  ' + cdp.properties.county_name + '</td></tr>'
    // yfTable += '<tr><td>' + "State" + '</td><td>  ' + cdp.properties.state_name + '</td></tr>'

    let yieldStruct;
    let value;
    if (crop.toLowerCase() === 'corn'){
      yieldStruct = cdp.properties.yield.corn
    }else if (crop.toLowerCase() === 'soybeans') {
      yieldStruct = cdp.properties.yield.soybeans
    }
    if (yieldStruct){
      if (model.toLowerCase() === 'actual'){
        value = yieldStruct.actual
      }else if (model.toLowerCase() === 'delta'){
        value = yieldStruct.delta
      }
    }

    // yfTable += '<tr><td>' + `${(model.toUpperCase() === 'DELTA') ? "Delta" : "Forecast (bushels/acre):"}` + '</td><td>  ' + `${value?.toFixed(2) ?? '---'}`+ '</td></tr>'
    if (crop && yieldStruct && yieldStruct.actual && yieldStruct.delta){
      yfTable += '<tr><td>' + `Forecast (Bushels/Acre):` + '</td><td>  ' + `${Number(yieldStruct?.actual)?.toFixed(2) ?? '---'}`+ '</td></tr>'
      yfTable += '<tr><td>' + `Delta (5 Years):` + '</td><td>  ' + `${Number(yieldStruct?.delta)?.toFixed(2) ?? '---'}`+ '</td></tr>'
    }

    yfTable += '<tr><td>' + `Precipitation (SPI):` + '</td><td>  ' + `${Number(cdp.properties.precipitation).toFixed(2) ?? '---'}`+ '</td></tr>'
    yfTable += '<tr><td>' + `Temperature (C):` + '</td><td>  ' + `${Number(cdp.properties.temperature).toFixed(2) ?? '---'}`+ '</td></tr>'
    yfTable += '<tr><td>' + `Drought (PDSI):` + '</td><td>  ' + `${Number(cdp.properties.drought).toFixed(2) ?? '---'}`+ '</td></tr>'

    table = `<table>${yfTable.length > 0 ? yfTable : table}</table>`
  }

  if (obj !== undefined && layer_id) {
    const ent_properties = obj.properties

    let minesTable: string = ""
    // render mines popup
    if (layer_id === "layer-potash-mines"){
      let mdp: IMinesDataPoint = obj
      minesTable += '<tr><td>' + `Asset:` + '</td><td>  ' + `${mdp.properties.Asset ?? '---'}`+ '</td></tr>'
      minesTable += '<tr><td>' + `Company:` + '</td><td>  ' + `${mdp.properties.Company ?? '---'}`+ '</td></tr>'
      minesTable += '<tr><td>' + `State/Province:` + '</td><td>  ' + `${mdp.properties.State_Prov ?? '---'}`+ '</td></tr>'
      minesTable += '<tr><td>' + `Country:` + '</td><td>  ' + `${mdp.properties.Country ?? '---'}`+ '</td></tr>'
      minesTable += '<tr><td>' + `Product:` + '</td><td>  ' + `${mdp.properties.Product ?? '---'}`+ '</td></tr>'
      minesTable += '<tr><td>' + `Status:` + '</td><td>  ' + `${mdp.properties.Status ?? '---'}`+ '</td></tr>'
      minesTable += '<tr><td>' + `2022 Production Capacity (kt):` + '</td><td>  ' + `${mdp.properties._22_ProdCa ?? '---'}`+ '</td></tr>'
      minesTable += '<tr><td>' + `2022 Production Volume (kt):` + '</td><td>  ' + `${mdp.properties._22_ProdVo ?? '---'}`+ '</td></tr>'

      table = `<table>${minesTable.length > 0 ? minesTable : table}</table>`
    }

    let depositsTable: string = ""
    // render deposits popup
    if (layer_id === "layer-potash-deposits"){
      let ddp: IDepositsDataPoint = obj
      depositsTable += '<tr><td>' + `Site Name:` + '</td><td>  ' + `${ddp.properties.Site_Name ?? '---'}`+ '</td></tr>'
      depositsTable += '<tr><td>' + `Type:` + '</td><td>  ' + `${ddp.properties.Type ?? '---'}`+ '</td></tr>'
      depositsTable += '<tr><td>' + `Country:` + '</td><td>  ' + `${ddp.properties.country ?? '---'}`+ '</td></tr>'
      depositsTable += '<tr><td>' + `Deposit Type:` + '</td><td>  ' + `${ddp.properties.Deposit_Ty ?? '---'}`+ '</td></tr>'
      depositsTable += '<tr><td>' + `Commodities:` + '</td><td>  ' + `${ddp.properties.commods ?? '---'}`+ '</td></tr>'
      depositsTable += '<tr><td>' + `Company:` + '</td><td>  ' + `${ddp.properties.company ?? '---'}`+ '</td></tr>'

      table = `<table>${depositsTable.length > 0 ? depositsTable : table}</table>`
    }

    let shippingRoutesTable: string = ""
    // render shipping routes popup
    if (layer_id === "route-points-layer"){
      let srdp: IShippingRouteDataPoint = obj
      shippingRoutesTable += '<tr><td>' + `Timestamp:` + '</td><td>  ' + `${srdp.properties.Timestamp ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `ETA:` + '</td><td>  ' + `${srdp.properties.ETA ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `MMSI:` + '</td><td>  ' + `${srdp.properties.MMSI ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `IMO:` + '</td><td>  ' + `${srdp.properties.IMO ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Vessel Name:` + '</td><td>  ' + `${srdp.properties.Vessel_Nam ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Vessel Type:` + '</td><td>  ' + `${srdp.properties.Vessel_Typ ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Origin Country:` + '</td><td>  ' + `${srdp.properties.Origin__Co ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Origin Port:` + '</td><td>  ' + `${srdp.properties.Origin__Po ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Destination Country:` + '</td><td>  ' + `${srdp.properties.Destinatio ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Destination Port:` + '</td><td>  ' + `${srdp.properties.Destinati2 ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Flag:` + '</td><td>  ' + `${srdp.properties.Flag ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Commodity:` + '</td><td>  ' + `${srdp.properties.Commodity ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Time spent at port (hrs):` + '</td><td>  ' + `${srdp.properties.Time_Spend ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Load Time (hrs):` + '</td><td>  ' + `${srdp.properties.Load_Time_ ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Dead Weight Tonnage:` + '</td><td>  ' + `${srdp.properties.Dead_Weigh ?? '---'}`+ '</td></tr>'
      shippingRoutesTable += '<tr><td>' + `Load (mt):` + '</td><td>  ' + `${srdp.properties.Load__mt_ ?? '---'}`+ '</td></tr>'

      table = `<table>${shippingRoutesTable.length > 0 ? shippingRoutesTable : table}</table>`
    }

    let tradeflowsTable: string = ""
    // render tradeflows popup
    if (layer_id === "routes-lines-layer"){
      let tdp: ITradeflowsDataPoint = obj
      tradeflowsTable += '<tr><td>' + `Origin Country:` + '</td><td>  ' + `${tdp.properties.Origin__Co ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Origin Port:` + '</td><td>  ' + `${tdp.properties.Origin__Po ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Destination Country:` + '</td><td>  ' + `${tdp.properties.Destinatio ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Destination Port:` + '</td><td>  ' + `${tdp.properties.Destinati2 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2022:` + '</td><td>  ' + `${tdp.properties.Tonnage_11 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2021:` + '</td><td>  ' + `${tdp.properties.Tonnage_10 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2020:` + '</td><td>  ' + `${tdp.properties.Tonnage__9 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2019:` + '</td><td>  ' + `${tdp.properties.Tonnage__8 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2018:` + '</td><td>  ' + `${tdp.properties.Tonnage__7 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2017:` + '</td><td>  ' + `${tdp.properties.Tonnage__6 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2016:` + '</td><td>  ' + `${tdp.properties.Tonnage__5 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2015:` + '</td><td>  ' + `${tdp.properties.Tonnage__4 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2014:` + '</td><td>  ' + `${tdp.properties.Tonnage__3 ?? '---'}`+ '</td></tr>'
      tradeflowsTable += '<tr><td>' + `Tonnage 2013:` + '</td><td>  ' + `${tdp.properties.Tonnage__2 ?? '---'}`+ '</td></tr>'

      table = `<table>${tradeflowsTable.length > 0 ? tradeflowsTable : table}</table>`
    }

    let agRetailersTable: string = ""
    // render ag retailers popup
    if (layer_id === "ag-retailers-layer"){
      let ardp: IAgRetailersDataPoint = obj
      agRetailersTable += '<tr><td>' + `Company:` + '</td><td>  ' + `${ardp.properties.list_name ?? '---'}`+ '</td></tr>'
      agRetailersTable += '<tr><td>' + `City:` + '</td><td>  ' + `${ardp.properties.City ?? '---'}`+ '</td></tr>'
      agRetailersTable += '<tr><td>' + `State:` + '</td><td>  ' + `${ardp.properties.State ?? '---'}`+ '</td></tr>'
      agRetailersTable += '<tr><td>' + `Count of Stores:` + '</td><td>  ' + `${ardp.properties.Count_of_S ?? '---'}`+ '</td></tr>'

      table = `<table>${agRetailersTable.length > 0 ? agRetailersTable : table}</table>`
    }

    let header = '';
    if (layer_id === "ag-retailers-layer") {
      // header=`<h2>${ent_properties.list_name}</h2>`
    } else if (layer_id === "ports-layer") {
      header='<tr><td>' + `Name:` + '</td><td>  '+ `${ent_properties.Port}`+ '</td></tr>'
      // header=`<h2>${ent_properties.Port_Name}</h2>`
    } else if (layer_id === "sam-layer") {
      // @ts-ignore
      return {html: `<h2>${CROPTYPE_LOOKUP[Math.round(ent_properties.majority_label)]}</h2>` }
    } else if (layer_id === "routes-lines-layer") {
      // header=`<h2>${ent_properties.Origin__Po}, ${ent_properties.Origin__Co} to ${ent_properties.Destinati2}, ${ent_properties.Destinatio}</h2>`
    } else if (layer_id === "major-deposits-layer") {
      header=`<h2>${ent_properties.Site_Name}</h2>`
    } else if (layer_id === "final-mines-layer") {
        header=`<h2>${ent_properties.Asset}</h2>`
    } else if (layer_id === "iowa-hex") {
        if (obj){
          let fhp: IFarmHexPoint = obj
          let fhpTable: string = ""
          for (let p in fhp.properties){
            if (p in obj.properties){
              if (obj.properties[p] !== 0){
                fhpTable += `<tr><td>${p}: ${obj.properties[p]}`
              }
            }
          }
          table = `<table>${fhpTable}</table>`
        }
    } else if (layer_id === "address-pins") {
    //   "<tr>" +
    // "<th id='q'>" + `${cdp.properties.county_name},` + "</th>" +
    // "<th id='o'>" + `${STATE_FIPS_MAPPING.find(e => e.name === cdp.properties.state_name.toUpperCase())?.abbr ?? '--'}` + "</th>" +
    // "</tr>"
      header=''
      let adpTable = ""
      if (obj){
        let adp: IAddressPoint = obj

        if (adp.query.length <= 24){
          adpTable += `<tr><th>"${adp.query}"</th></tr>`
        }else{
          adpTable += `<tr><th>"${adp.query.substring(0,21)}..."</th></tr>`
        }

        // Splits formatted address by comma
        const pieces = adp.formattedAddress?.split(',')
        // Displays formatted address across multiple lines
        let n = 0
        let line = ""
        for (const piece of pieces){
          n += 1
          if (line.length + piece.length <= 20){
            line += `${piece}` + (n === pieces.length ? '' : ',')
          }else{
            if ((n === pieces.length) && piece.length <= 12){
              // If the next piece is the last remaining piece and is small, append anyway
              line += `${piece}` + (n === pieces.length ? '' : ',')
              adpTable += `<tr><td>${line}</td></tr>`
              line = ''
            }else{
              adpTable += `<tr><td>${line}</td></tr>`
              line = `${piece}` + (n === pieces.length ? '' : ',')
            }
          }
        }
        if (line.length > 0){
          adpTable += `<tr><td>${line}</td></tr>`
        }
        adpTable += `<tr><td><hr/></td></tr>`
        adpTable += `<tr><td>Latitude: ${adp.geometry.latitude.toFixed(4) ?? '---'} </td></tr>`
        adpTable += `<tr><td>Longitude: ${adp.geometry.longitude.toFixed(4) ?? '---'} </td></tr>`
        // adpTable += `<tr><td>${adp.formattedAddress}</td></tr>`
      }
      table += `<table>${adpTable.length > 0 ? adpTable : table}</table>`
    }
      return {html: `${header}${table}`, style: {zIndex:"3000", left: "12px", top:"20px", borderRadius:"8px"}}
    } else {
      return null
    }
}
