import {
  styled,
  Dialog as MuiDialog,
  Box as MuiBox,
  Popper as MuiPopper,
} from '@mui/material'

export const Dialog = styled(MuiDialog)(() => ({
  // marginLeft: '40px',
  // alignItems: 'stretch',
  // overflow: 'hidden',
  // minWidth: '280px',
  // width: '100%'
}));

export const Box = styled(MuiBox)(() => ({
  // minHeight: 'calc(100% - 64px)',
  // maxHeight: 'calc(100% - 64px)',
    // marginLeft: '40px',
  // alignItems: 'stretch',
  // overflow: 'hidden',
  // minWidth: '280px',
  // width: '100%'
}));

export const Popper = styled(MuiPopper)(() => ({
  top: '60px',
  left: '60px',
  translate: '60px, 60px'
  // marginLeft: '40px',
  // alignItems: 'stretch',
  // overflow: 'hidden',
  // minWidth: '280px',
  // width: '100%'
}));
