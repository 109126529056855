import { Checkbox, FormGroup, Typography, Grid, Box, IconButton, FormControl, Select, MenuItem, ListItemText, OutlinedInput, SelectChangeEvent, InputLabel, ListSubheader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IStoryData, IStoryPanel, ISubFilter, StoryType, fieldType, layerType } from '../../store/StoryApi';
import { LegendAccordion } from '../LegendAccordion/LegendAccordion';
import { useState, useEffect } from 'react';
import { ControlCheckbox } from '../ControlPanel/ControlPanel';
import ClearIcon from '@mui/icons-material/Clear';
import { IManageState, IUpdateLayerSubFilters } from '../CropAI/CropAI';
import { produce } from "immer";

interface IFilterParams {
  manualToggleLayer: (panelName: StoryType, layerName: layerType, activate: boolean) => void,
  state: IManageState,
  panel: IStoryPanel,
  layerName: layerType,
  subFilter: ISubFilter,
  defaultSelection: string,
  override?: {
    width?: string,
    label?: string,
    backgroundColor?: string,
  }
}

export const MapOverlayMenu = (params: IFilterParams) =>{
  const {state, panel, layerName, subFilter, defaultSelection, override, manualToggleLayer} = params
  const [selections, setSelections] = useState<string[]>([defaultSelection]);

  useEffect(() => {
    // If a Yield option was selected
    // if (["Delta", "Actual"].includes(selections[0])){
      state.setStoryData(produce<IStoryData>(draft => {
        const panelIndex = draft.panels.findIndex(e => e.name === panel.name)
        if (panelIndex !== -1){
          const layerIndex = panel.layers.findIndex(e => e.type === layerName)
          if (layerIndex !== -1){
            const subFilterIndex = panel.layers[layerIndex].subFilters.findIndex(e => e.field === subFilter.field)
            if (subFilterIndex !== -1){
              // if (JSON.stringify(draft.panels[panelIndex].layers[layerIndex].subFilters[subFilterIndex].activeValues) !== JSON.stringify(selections)){
                draft.panels[panelIndex].layers[layerIndex].subFilters[subFilterIndex].activeValues = [...selections]
              // }
            }
          }
        }
      }))

    // populateLayerWithSubFilters({panelName: panel.name, layerToUpdate: layerType.POTASH_MINES, newSubFilters: [newFilter], updateActive: true})
    // TODO: TS lint does not like this effect trigger, but adding the other requested values creates infinite loop (crashes)
  }, [selections]); // Only re-run the effect if count changes

  const handleChange = (event: SelectChangeEvent<typeof selections>) => {
    // {value} is string[]
    const {
      target: { value },
    } = event;
    // If clear all selected
    if (value.includes("CLEAR_ALL")){
      setSelections([])
    }else{
      setSelections(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    }
  }

  const MENU_PROPS = {
    PaperProps: {
      style: {
        // maxHeight: "50vh",
        // width: 250,
      },
    },
  };

  // Allows option label overriding for yield forecast Actual --> Forecast, and Delta --> Delta (5yrs)
  const renderLabelOverride = (filter: string) => {
    switch (filter) {
      case "Actual":
        return "Forecast (Bushels/Acre)"
      case "Delta":
        return "Delta (5 Years)"
      case layerType.FD_TEMPERATURE:
        return "Temperature (°C)"
      case layerType.FD_PRECIPITATION:
        return "Precipitation (SPI)"
      case layerType.FD_DROUGHT:
        return "Drought (PDSI)"
      default:
        // Otherwise no override
        return filter
    }
  }

  const weatherLayers = [
    layerType.FD_TEMPERATURE,
    layerType.FD_PRECIPITATION,
    layerType.FD_DROUGHT,
  ]

  return (
  <Box>
    <Typography fontSize={"14px"} color={"#667080"}>{override?.label ?? subFilter.label}</Typography>
    <FormControl sx={{ width: override?.width ?? "200px" }} size="small">
      <Select
        labelId={subFilter.label + "-label"}
        id={subFilter.label}
        multiple={false}
        value={selections}
        displayEmpty={true}
        variant="outlined"
        renderValue={(selected) => {
          if (selected.length === 0) {
            return ["None"];
          }
          // Overide label for yield forecast
          if ((layerName === layerType.YIELD_FORECAST) && selected.length === 1){
            return renderLabelOverride(selected[0])
          }else{
            // Return normal
            return selected.join(', ');
          }
        }}
        MenuProps={MENU_PROPS}
        onChange={handleChange}
        sx={{
          backgroundColor: override?.backgroundColor ?? "white",
          height: "30px",
          maxWidth: "200px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
      {/* <MenuItem value={"CLEAR_ALL"}>
        <ClearIcon color="disabled" sx={{marginRight:"9px"}}/>
        <Typography color="#b6b6b6">Clear All</Typography>
      </MenuItem> */}
        <ListSubheader>{"Yield"}</ListSubheader>
        {subFilter.options.map((filter) => (
        <MenuItem key={filter} value={filter}>
            {/* {
              (selections.indexOf(filter) > -1) &&
              <Checkbox sx={{padding: "0px 9px 0px 0px" }} checked={selections.indexOf(filter) > -1} />
            } */}
            <ListItemText sx={{paddingLeft: "16px"}} primary={layerName === layerType.YIELD_FORECAST ? renderLabelOverride(filter) : filter} />
        </MenuItem>
      ))}
      <ListSubheader>{"Conditions"}</ListSubheader>
      {weatherLayers.map((filter) => (
          <MenuItem key={filter} value={filter}>
            {/* {
              (selections.indexOf(filter) > -1) ?
              <Checkbox sx={{padding: "0px 9px 0px 0px" }} checked={selections.indexOf(filter) > -1} />
              : <Box sx={{padding: "0px 9px 0px 0px" }} />
            } */}
            <ListItemText sx={{paddingLeft: "16px"}} primary={layerName === layerType.YIELD_FORECAST ? renderLabelOverride(filter) : filter} />
          </MenuItem>
      ))}
      </Select>
    </FormControl>
  </Box>
  );
}
