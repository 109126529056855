import React, {useEffect, useRef, useState} from 'react';

import ControlPanel, {ElevatorLegend} from './ControlPanel'
import Map from "./Map"

import dayjs, { Dayjs } from 'dayjs';

const api_url = "http://localhost:5000"



const Cargill = () => {
    const [elevatorDate, setElevatorDate] = useState<Dayjs>(dayjs("2023-01-03"));
    const [allowedElevatorDates, setAllowedElevatorDates] = useState<Dayjs[]>([]);
    const [elevatorMode, setElevatorMode] = useState<string>("WINLOSS");

    useEffect(() => {
        console.log("Getting allowed elevator dates")
        fetch(`${api_url}/elevator_demo/elevator_price_dates`)
            .then(response => response.json())
            .then(data => {
                let dates = []
                for (var i = 0; i < data.length; i++) {
                    dates.push(dayjs(data[i]))
                }
                setAllowedElevatorDates(dates)
            })
    }, [])


    const handleSetElevatorDate = (elevatorDate: Dayjs | null) => {
        console.log("setting elevator date")
        if (elevatorDate !== null) {
            setElevatorDate(elevatorDate)
            // this.setState({elevatorDate});
        }
    }

    const handleSetElevatorMode = (elevatorMode: string) => {
        console.log("setting elevatorMode")
        setElevatorMode(elevatorMode)
        // this.setState({elevatorMode});
    }


    return (
        <div>
            <Map elevatorDate={elevatorDate} elevatorMode={elevatorMode}/>

            <ControlPanel
                handleSetElevatorDate={handleSetElevatorDate}
                handleSetElevatorMode={handleSetElevatorMode}
                elevatorDate={elevatorDate}
                allowedElevatorDates={allowedElevatorDates}
                elevatorMode={elevatorMode}
            />

            {/*{(this.state.mode === "ELEVATOR") &&*/}
            {/*    <ElevatorColorBar elevatorMode={this.state.elevatorMode}/>*/}
            {/*}*/}
            <div style={{bottom: 0, left: 300}}>
                <ElevatorLegend/>
            </div>
        </div>
    );
};

export default Cargill;
