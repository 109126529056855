import Box from '@mui/material/Box';
import { DialogTitle, IconButton } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { StoryType, layerType } from '../../store/StoryApi';
import { useState } from 'react'
import { IGeoLayer, IStoryData } from '../../store/StoryApi';
import * as S from './styles'
import { ControlPanel } from '../ControlPanel/ControlPanel';
import { IMapView } from '../../store/MapApi';
import { IManageState } from '../CropAI/CropAI';
import { LaunchYieldDashboardButton } from '../PanelFarmDemand/LaunchDashboardButton';

interface IDialogPanelParams {
  state: IManageState,
  storyType: StoryType,
  storyLabel: string,
  panelOpen: boolean,
  handlePanelClose: () => void,
  storyData: IStoryData,
  mapView: IMapView,
  handleLayerChange: (panelName: StoryType, newLayers: IGeoLayer[]) => void,
  handleMapViewChange: (newMapView: IMapView) => void,
}

function PaperComponent(props: PaperProps) {
  // const nodeRef = useRef(null);
  return (
    <Draggable
      // nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
      {/* <div ref={nodeRef}>Example Target</div> */}
    </Draggable>
  );
}


const getStoryPanel = (state: IManageState, storyType: StoryType, storyData: IStoryData, mapView: IMapView,
                      handleLayerChange: (panelName: StoryType, newLayers: IGeoLayer[]) => void, handleMapViewChange: (newMapView: IMapView) => void)=> {
  // Verify matching storyType exists in panels
  const panel = storyData.panels.find(e => e.name === storyType)

  if (!panel){
    // No need to display warning if storyType is _NONE (default value meaning no panel has been selected)
    if (storyType !== StoryType._NONE){
      console.log(`WARNING - getStoryPanel() failed to find storyType: ${storyType} in panel state!`)
    }
    // If panel is undefined i.e. the panel does not exist in the state, return nothing
    return <></>
  }else{
    return (
      <ControlPanel
        state={state}
        panel={panel}
        handleLayerChange={handleLayerChange}
        mapView={mapView}
        handleMapViewChange={handleMapViewChange}

      />
    )
  }
}


export const DialogPanel = (params: IDialogPanelParams) =>{

  const {state, storyType, storyLabel, panelOpen, handlePanelClose, storyData, mapView, handleLayerChange, handleMapViewChange} = params;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  const handleClose = () => {
    handlePanelClose();
  };

  return (
      <S.Popper
        open={panelOpen}
        anchorEl={anchorEl}
        // sx={{position: 'absolute', top: '60px', left: '60px'}}
      >
        <S.Box>
          <PaperComponent style={{
            backgroundColor: '#fffcfc',
            opacity: '90%',
            boxShadow: 'none',
            borderRadius: "10px",
            // padding: '10px',
            padding: '10px 5px 10px 10px',
            minWidth: '320px',
            maxWidth: '320px',
            minHeight: '400px',
            // height: '95vh',
            height: 'calc(100vh - 30px)',
            zIndex: "2000",
          }}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} paddingBottom={"5px"}>
              <IconButton
                onClick={handleClose}
                sx={{
                  padding:'0px 3px 0px 3px',
                  margin:'0px 5px 0px 5px',
                }}
              >
                <ArrowBackIosRoundedIcon sx={{color: "#667080"}}/>
              </IconButton>

              <DialogTitle style={{padding: '0', cursor: 'move' }} id="draggable-dialog-title" >
                {storyLabel.toUpperCase()}
              </DialogTitle>
              {(storyType === StoryType.FARM_DEMAND) && <LaunchYieldDashboardButton state={state} excludeSet={[layerType.YIELD_FORECAST]}/>}
            </Box>
            {/* {(storyType === StoryType.FARM_DEMAND) && <LaunchYieldDashboardButton/>} */}
            {getStoryPanel(state, storyType, storyData, mapView, handleLayerChange, handleMapViewChange)}
            <Box
              // style={{ cursor: 'move' }} id="draggable-dialog-title"
              component="img"
              sx={{
                backgroundColor: "transparent",
                height: 153,
                width: 263,
                position: "absolute",
                bottom: 0,
              }}
              alt="BCG X Logo"
              src="https://bcg.widen.net/content/iin91jlach/png/02-bcgx-logo-black-RGB-medium.png?w=2048&h=1152&position=c&quality=80&use=clzzmzwm&x.portal_shortcode_generated=pfusj1sw&x.collection_sharename=9tmumvft&x.app=portals"
              // src="https://bcg.widen.net/content/iin91jlach/jpeg/02-bcgx-logo-black-RGB-medium.jpeg?w=2048&h=1152&position=c&quality=80&use=clzzmzwm&x.portal_shortcode_generated=pfusj1sw&x.collection_sharename=9tmumvft&x.app=portals"
            />
          </PaperComponent>
        </S.Box>
      </S.Popper>
  );
}
