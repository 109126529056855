import { GeoJsonLayer } from "@deck.gl/layers"
import { hexToRgb } from "@mui/system"
import { HSLToRGB, convertRGBtoArray } from "../../utils/color"
import { MVTLayer, TileLayer } from "@deck.gl/geo-layers/typed"
import {CROPTYPE_LOOKUP} from "../../utils/croptype_lookup";
import { BitmapLayer } from "@deck.gl/layers";

const iowaLongLatBounds = [-96, 40, -90, 43]

// Ag Retailers US & CA
export const generateAgRetailers = () => {
  const agRetailersLayer = new GeoJsonLayer({
    id: 'ag-retailers-layer',
    data: "https://storage.googleapis.com/cropai-mappable/AgRetailers.geojson",
    getFillColor: (d: any) => {
      return convertRGBtoArray(hexToRgb(getColorByAgRetailer(d.properties.list_name)))
    },
    pointRadiusMinPixels: 5,
    stroked: true,
    getLineColor: [255, 255, 255],
    getLineWidth: 1.0,
    lineWidthUnits: 'pixels',
    opacity: .75,
    pickable: true,
  })
  return agRetailersLayer
}

// Ag Fields Segmentation --> Farm 360
export const generateIowaPolygons = () => {
  const sam_layer = new MVTLayer({
    id: 'sam-layer',
    data: `https://storage.googleapis.com/cropai-mappable/iowa_1024_kernel_100_points_mask_farm_poly/{z}/{x}/{-y}.mvt`,
    getFillColor: (d: any) => {
      return convertRGBtoArray(hexToRgb(getAgSamColorHex(d.properties.majority_label)))      },
    opacity: .9,
    pickable: true,
    extent: iowaLongLatBounds,
    maxZoom: 9,
    minZoom: 7.5,
  })
  return sam_layer
}
// Ag Fields Segmentation --> Farm 360
export const generateSam_Iowa = () => {
  const sam_layer = new MVTLayer({
    id: 'sam-layer-2',
    data: `https://storage.googleapis.com/cropai-mappable/iowa-hex-uncompressed/{z}/{x}/{y}.pbf`,
    getFillColor: (d: any) => {
      console.log(d.properties)
      return HSLToRGB(255, 50, 50)  // 413 is 1.618 * 255
      },
    opacity: .9,
    pickable: false,
    minZoom: 6,
    maxZoom: 7.5,
  })
  return sam_layer
}

// Ag Fields Segmentation --> Farm 360
export const generateIowaHex = () => {
  const layer = new MVTLayer({
    id: 'iowa-hex',
    data: `https://storage.googleapis.com/cropai-mappable/iowa-hex-uncompressed/{z}/{x}/{y}.pbf`,
    getFillColor: (d: any) => {
      if ("Majority_l" in d.properties){
        // console.log(d.properties)
        return convertRGBtoArray(hexToRgb(getAgSamColorHex(d.properties.Majority_l)))
      }else{
        return convertRGBtoArray(hexToRgb("#E1E1E1"))
      }
      },
    opacity: .9,
    pickable: true,
    minZoom: 6,
    maxZoom: 7.5,
  })
  return layer
}

// Ag Fields Segmentation --> Farm 360
export const generateIowaByKeyword = (keyword: string) => {
  const layer = new MVTLayer({
    id: 'iowa-hex',
    data: `https://storage.googleapis.com/cropai-mappable/iowa-${keyword}/{z}/{x}/{y}.pbf`,
    getFillColor: (d: any) => {
      if ("Majority_l" in d.properties){
        // console.log(d.properties)
        return convertRGBtoArray(hexToRgb(getAgSamColorHex(d.properties.Majority_l)))
      }else{
        return convertRGBtoArray(hexToRgb("#E1E1E1"))
      }
      },
    opacity: .9,
    pickable: true,
    // [minX, minY, maxX, maxY]
    extent: iowaLongLatBounds,
    minZoom: 4,
    // maxZoom: 7.5,
  })
  return layer
}
// Ag Fields Segmentation --> Farm 360
export const generateIowaGeo = () => {
  const layer = new GeoJsonLayer({
    id: 'iowa-geojson',
    data: `https://storage.googleapis.com/cropai-mappable/iowa-geojsons/Hex_Grid_6.geojson`,
    getFillColor: (d: any) => {
      return convertRGBtoArray(hexToRgb("#E1E1E1"))
      if ("Majority_l" in d.properties){
        // console.log(d.properties)
        return convertRGBtoArray(hexToRgb(getAgSamColorHex(d.properties.Majority_l)))
      }else{
        return convertRGBtoArray(hexToRgb("#E1E1E1"))
      }
      },
      // pointRadiusMinPixels: 5,
      // filled: true,
      // stroked: true,
      // getLineColor: [255, 255, 255],
      // getLineWidth: 1.0,
      // lineWidthUnits: 'pixels',
      opacity: .75,
      pickable: false,
  })
  return layer
}

// False Color EVI
export const generateEvi_Iowa = () => {
  const cali_evi_layer = new TileLayer({
    id: "tile-images",
    data: 'https://storage.googleapis.com/cropai-mappable2/iowa_1024_kernel_inference_evi_raster_tiles/{z}/{x}/{y}',
    minZoom: 0,
    maxRequests: 0,
    maxZoom: 14,
    // tileSize: 256,

    renderSubLayers: props => {
        const {
          // @ts-ignore
          bbox: {west, south, east, north}
        } = props.tile;

        return new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [west, south, east, north]
        });
      }
  })
  return cali_evi_layer
}

// Central Valley EVI
export const generateEvi = () => {
  const cali_evi_layer = new TileLayer({
    id: "tile-images",
    data: 'https://storage.googleapis.com/cropai-mappable2/california_central_valley_pheno_evi_raster_tiles2/{z}/{x}/{y}',
    minZoom: 0,
    maxRequests: 0,
    maxZoom: 14,
    // tileSize: 256,

    renderSubLayers: props => {
        const {
          // @ts-ignore
          bbox: {west, south, east, north}
        } = props.tile;

        return new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [west, south, east, north]
        });
      }
  })
  return cali_evi_layer
}

// Central Valley Farm SAM
export const generateSam = () => {
  const sam_layer = new MVTLayer({
    id: 'sam-layer',
    data: `https://storage.googleapis.com/cropai-mappable/cv_fields_polygons/{z}/{x}/{-y}.mvt`,
    getFillColor: (d: any) => {
      return HSLToRGB((d.properties.majority_label * 413) % 255, 50, 50)  // 413 is 1.618 * 255
      },
    opacity: .9,
    pickable: true,
    maxZoom: 9
  })
  return sam_layer
}


const getColorByAgRetailer = (retailer: string) => {
  switch (retailer) {
    case "Nutrien Ag Solutions":
      return("#02786E")
    case "GROWMARK":
    case "Growmark":
      return("#08887D")
    case "Helena Agri-Enterprises":
    case "Helena":
      return("#00A887")
    case "CHS Agronomy":
      return("#0BBA98")
    case "Simplot":
    case "Simplot Grower Solutions":
      return("#00E0B5")
    case "MFA Inc.":
    case "MFA Inc":
      return("#0CEFC3")
    case "Wilbur-Ellis":
    case "Wilbur-Ellis Agribusiness":
      return("#00FFCC")
    case "Titan Pro":
      return("#3FFFD9")
    case "GreenPoint Ag Holdings":
    case "GreenPoint Ag":
      return("#A6FFED")
    case "Southern States Cooperative":
      return("#C1FFF3")
    default:
      return("#FFFFFF")
  }
}

const getAgSamColorHex = (cropId: number) => {
  // Out of bounds
  if (cropId < 1 || cropId > 254){
    return "#FFFFFF"
  }
  // TODO: Revisit the types here. CROPTYPE_LOOKUP could be restructured
  const cropLabel = CROPTYPE_LOOKUP[cropId as keyof typeof CROPTYPE_LOOKUP]
  switch (cropLabel.toUpperCase()) {
    case "CORN":
      return "#EEC659"
    case "SOYBEANS":
      return "#ADC178"
    case "GRASSLAND/PASTURE":
      return "#A98467"
    case "ALFALFA":
      return "#77874D"
    case "FALLOW/IDLE CROPLAND":
      return "#D6AD8D"
    default:
      return "#E1E1E1"
  }

}
